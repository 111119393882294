import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = [
    'members',
  ];

  connect() {
    document.addEventListener('collective-members:updated', this.onUpdated);
  }

  disconnect() {
    document.removeEventListener('collective-members:updated', this.onUpdated);
  }

  onUpdated = (event) => {
    this.membersTarget.innerHTML = event.detail;
  }

  onDeleteSuccess(event) {
    const [, , xhr] = event.detail;
    this.membersTarget.innerHTML = xhr.response;
    Snackbar.show({ text: 'The team member was successfully removed' });
  }

  onDeleteFail() {
    Snackbar.show({
      text: 'There was a problem removing the team member',
      actionTextColor: ERROR_COLOR,
    });
  }

  onShowForm(event) {
    const [, , xhr] = event.detail;
    document.dispatchEvent(new CustomEvent('collective-members-modal-update', {
      detail: xhr.response,
    }));
  }

  onShowFormFail() {
    Snackbar.show({
      text: 'There was a problem displaying the form',
      actionTextColor: ERROR_COLOR,
    });
  }
}
