import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['dropdownButton'];

  onToggleButton(e) {
    const { currentTarget } = e;

    currentTarget.classList.toggle('active');
  }

  onRemoveConnectionSuccess() {
    this.element.parentNode.removeChild(this.element);
    Snackbar.show({ text: 'The connection has been removed' });
  }

  onRemoveConnectionFail() {
    Snackbar.show({
      text: 'There was a problem removing the connection',
      actionTextColor: ERROR_COLOR,
    });
  }
}
