import { Controller } from '@hotwired/stimulus';

import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  static targets = [
    'score',
    'totalScore',
    'ratings',
  ];

  onRatingSelected = (e) => {
    const {
      currentTarget,
    } = e;

    const match = this.ratingsTarget.querySelector('.selected');
    const score = parseInt(currentTarget.getAttribute('data-score'), 10);
    if (match) {
      match.classList.remove('selected');
    }
    currentTarget.classList.add('selected');
    this.scoreTarget.value = score;
    const stars = currentTarget.parentNode.children;

    Array.from(stars).forEach((node, i) => {
      const value = i + 1;
      if (value <= score) {
        node.classList.add('selected');
      } else {
        node.classList.remove('selected');
      }
    });
    Rails.fire(this.element, 'submit');
  }

  onMouseOver = (e) => {
    const {
      currentTarget,
    } = e;
    const score = parseInt(currentTarget.getAttribute('data-score'), 10);
    const stars = currentTarget.parentNode.children;

    Array.from(stars).forEach((node, i) => {
      const value = i + 1;
      if (value <= score) {
        node.classList.add('hover');
      } else {
        node.classList.remove('hover');
      }
    });
  }

  onMouseOut = (e) => {
    const {
      currentTarget,
    } = e;
    const stars = currentTarget.parentNode.children;

    Array.from(stars).forEach((node) => {
      node.classList.remove('hover');
    });
  }


  onSaveSuccess(e) {
    const [
      res,
      status,
      xhr,
    ] = e.detail;
    const {
      judge_score: {
        score,
      },
    } = res;
    this.totalScoreTarget.textContent = score || '-';
    Snackbar.show({
      text: 'The score was updated successfully',
    });
  }

  onSaveFail() {
    Snackbar.show({
      text: 'There was a problem saving judging setup',
      actionTextColor: ERROR_COLOR,
    });
  }
}
