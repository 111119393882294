import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { dasherize } from 'underscore.string';

export default class extends Controller {
  static targets = [
    'competition',
    'requirementsList',
    'incompleteFieldsWrapper',
  ];

  connect() {
    document.addEventListener('refresh:requirements', this.fetchRequirements);
    if (this.hasCompetitionTarget) {
      this.onCompetitionChange();
    } else {
      this.fetchRequirements();
    }
  }

  disconnect() {}

  fetchRequirements = () => {
    const endpoint = this.data.get('endpoint');
    if (!endpoint) {
      return;
    }
    axios
      .get(
        endpoint,
        {
          params: {
            _: Date.now(),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
              .content,
          },
        },
      )
      .then((res) => {
        const {
          data: {
            incomplete_fields: incompleteFields,
            requirements_rendered: requirementsRendered,
          },
        } = res;

        const incompletedSectionIds = incompleteFields.map(
          (f) => `${dasherize(f.field)}-section`,
        );
        const sections = document.querySelectorAll('.venture-section');
        [].forEach.call(sections, (el) => {
          if (incompletedSectionIds.includes(el.id)) {
            el.classList.add('incomplete');
          } else {
            el.classList.remove('incomplete');
          }
        });

        this.incompleteFieldsWrapperTarget.innerHTML = requirementsRendered;
        this.incompleteFieldsWrapperTarget.classList.remove('hidden');
      })
      .catch((err) => {
        const {
          response: {
            data: { errors: errorsHash },
          },
        } = err;
      });
  };

  onFetchIncompleteFieldsSuccess() {}

  onFetchIncompleteFieldsFail() {}

  onCompetitionChange() {
    const { selectedIndex, value: competitionId } = this.competitionTarget;
    if (!competitionId) {
      this.reset();
      return;
    }

    const option = this.competitionTarget.options[selectedIndex];
    const {
      dataset: { url },
    } = option;
    this.data.set('endpoint', url);
    this.fetchRequirements();
  }

  reset() {
    const sections = document.querySelectorAll('.venture-section');
    [].forEach.call(sections, (el) => {
      el.classList.remove('incomplete');
    });

    this.incompleteFieldsWrapperTarget.innerHTML = '';
    this.incompleteFieldsWrapperTarget.classList.add('hidden');
  }
}
