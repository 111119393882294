import { Controller } from '@hotwired/stimulus';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['email'];

  connect() {
    this.emailTarget.value = '';

    document.addEventListener('share-modal:closed', this.reset);
  }

  disconnect() {
    document.removeEventListener('share-modal:closed', this.reset);
  }

  reset = () => {
    this.emailTarget.value = '';
  };

  onSendSuccess() {
    Snackbar.show({ text: 'Your invite has been send!' });
  }

  onSendFail() {
    Snackbar.show({
      text: 'There was a problem sharing this venture',
      actionTextColor: ERROR_COLOR,
    });
  }
}
