import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'endDate',
    'current',
  ];

  onChange = (e) => {
    const {
      target: {
        checked,
      },
    } = e;

    [].forEach.call(this.endDateTargets, (target) => {
      console.log(target);
      if (checked) {
        target.value = '';
      }
      target.disabled = checked;
    });
  }
}
