import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'memberForm',
  ];

  connect() {
    this.modalEl.on('closed.zf.reveal', this.onClose);
  }

  disconnect() {
    this.modalEl.off('closed.zf.reveal', this.onClose);
  }

  onClose = () => {
    this.membersController.onCancelForm();
  }

  updateContent(html) {
    this.memberFormTarget.innerHTML = html;
  }

  onCreateSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.membersController.onCreateSuccess(xhr.response);
    this.hide();
  }

  onUpdateSuccess(event) {
    const [data, status, xhr] = event.detail;

    this.membersController.onUpdateSuccess(xhr.response);
    this.hide();
  }

  show() {
    $(this.element).parent().foundation('open');
  }

  hide() {
    $(this.element).parent().foundation('close');
  }

  get modalEl() {
    return $(this.element).parent();
  }

  get membersController() {
    const membersEl = document.querySelector('.team-members-section');
    return this.application.getControllerForElementAndIdentifier(membersEl, 'members');
  }
}
