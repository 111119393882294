import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

export default class extends Component {
  static propTypes = {
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    show: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
    show: false,
    children: (
      <span>Are you sure?</span>
    ),
  };

  render() {
    const {
      children,
      cancelButtonText,
      confirmButtonText,
      show,
    } = this.props;

    return (
      <Modal
        closeOnEscapeKey={false}
        title="Confirm Action"
        onClose={() => this.props.onCancel()}
      >
        <div className="p-6">
          <div className="mb-6">
            {children}
          </div>
          <div
            className="flex justify-end"
          >
            <button
              type="button"
              onClick={() => this.props.onConfirm()}
              className="btn btn-primary mr-2"
            >
              {confirmButtonText}
            </button>
            <button
              type="button"
              onClick={() => this.props.onCancel()}
              className="btn btn-hollow-blue"
            >
              {cancelButtonText}
            </button>

          </div>
        </div>
      </Modal>
    );
  }
}
