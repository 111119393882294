import { Controller } from '@hotwired/stimulus';

const MIN_NUMBER_OF_PASSIONS = 5;

export default class extends Controller {
  static targets = [
    'errorContainer',
    'errorMessage',
  ];

  onSubmit(e) {
    const checked = document.querySelectorAll('input[type=checkbox]:checked').length;
    if (checked < MIN_NUMBER_OF_PASSIONS) {
      this.errorContainerTarget.classList.add('error-wrapper');
      this.errorMessageTarget.innerHTML = `Please select at least ${MIN_NUMBER_OF_PASSIONS} passions`;
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.errorContainerTarget.classList.remove('error-wrapper');
      this.errorMessageTarget.innerHTML = '';
    }
  }
}
