import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onChange = () => {
    this.submit();
  }

  onSaveSuccess = () => {
    Snackbar.show({
      text: 'We saved the field successfully!',
    });
  }

  onSaveFail = () => {
    Snackbar.show({
      text: 'There was a problem saving the field.',
      actionTextColor: ERROR_COLOR,
    });
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }

}
