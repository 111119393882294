import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'distanceText',
    'businessStageErrorLabel',
    'businessTypeErrorLabel',
    'educationLevelErrorLabel',
    'educationLevel',
  ];

  onSubmit(e) {
    let hasError = false;

    const businessStageCheckboxes = document.querySelectorAll('[type=checkbox][name="user[business_stage_ids][]"]:checked');
    if (businessStageCheckboxes.length < 1) {
      hasError = true;
      this.businessStageErrorLabelTarget.innerHTML = 'Please select at least one business stage';
      this.businessStageErrorLabelTarget.parentNode.classList.add('error-wrapper');
    } else {
      this.businessStageErrorLabelTarget.innerHTML = '';
      this.businessStageErrorLabelTarget.parentNode.classList.remove('error-wrapper');
    }
    const businessTypeCheckboxes = document.querySelectorAll('[type=checkbox][name="user[business_type_ids][]"]:checked');
    if (businessTypeCheckboxes.length < 1) {
      hasError = true;
      this.businessTypeErrorLabelTarget.innerHTML = 'Please select at least one business type';
      this.businessTypeErrorLabelTarget.parentNode.classList.add('error-wrapper');
    } else {
      this.businessTypeErrorLabelTarget.innerHTML = '';
      this.businessTypeErrorLabelTarget.parentNode.classList.remove('error-wrapper');
    }

    const educationLevel = this.educationLevelTarget.value;
    if (!educationLevel) {
      hasError = true;
      this.educationLevelErrorLabelTarget.innerHTML = 'Please select your highest level of education';
      this.educationLevelErrorLabelTarget.parentNode.classList.add('error-wrapper');
    } else {
      this.educationLevelErrorLabelTarget.innerHTML = '';
      this.educationLevelErrorLabelTarget.parentNode.classList.remove('error-wrapper');
    }

    if (hasError) {
      e.preventDefault();
    }
  }

  onDistanceChange = (e) => {
    this.distanceTextTarget.innerHTML = e.target.value;
  }
}
