import { Controller } from '@hotwired/stimulus';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['perks'];

  connect() {
    document.addEventListener('perks:updated', this.onUpdated);
  }

  disconnect() {
    document.removeEventListener('perks:updated', this.onUpdated);
  }

  onFormLoadSuccess(e) {
    const [response, status, xhr] = e.detail;
    document.dispatchEvent(
      new CustomEvent('perk-form-modal-update', {
        detail: xhr.response,
      }),
    );
  }

  onFormLoadFail() {
    Snackbar.show({
      text: 'There was a problem loading the form',
      actionTextColor: ERROR_COLOR,
    });
  }

  onDeleteSuccess(e) {
    const [response, status, xhr] = e.detail;

    this.perksTarget.innerHTML = xhr.response;
  }

  onDeleteFail() {
    Snackbar.show({
      text: 'There was a problem removing the perk',
      actionTextColor: ERROR_COLOR,
    });
  }

  onUpdated = (e) => {
    const [response, status, xhr] = e.detail;

    this.perksTarget.innerHTML = xhr.response;
  };
}
