/* global Stripe */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    apiKey: String,
    clientSecret: String,
    paymentElementId: String,
    returnUrl: String,
  };

  static targets = [
    'errors',
  ];

  stripe = false;

  card = false;

  connect() {
    this.loadStripe();
    this.element.addEventListener('submit', this.handleSubmit);
  }

  disconnect() {
    this.element.removeEventListener('submit', this.handleSubmit);
  }

  setupStripe = () => {
    this.stripe = Stripe(this.apiKeyValue);

    const appearance = {
      theme: 'stripe',
      variables: {
      },
    };
    const options = { clientSecret: this.clientSecretValue, appearance };
    this.elements = this.stripe.elements(options);

    this.paymentElement = this.elements.create('payment');
    this.paymentElement.mount(`${this.paymentElementIdValue}`);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.elements) {
      return;
    }
    this.hideErrors();
    const result = await this.stripe.confirmSetup({
      elements: this.elements,
      confirmParams: {
        return_url: this.returnUrlValue,
      },
    });

    const { error } = result;
    if (error) {
      this.showErrors(error.message);
    }
  };

  loadStripe() {
    const script = document.createElement('script');
    script.onload = this.setupStripe;
    script.src = 'https://js.stripe.com/v3/';

    document.head.appendChild(script);
  }

  showErrors(message) {
    this.errorsTarget.classList.add('hidden');
    this.errorsTarget.textContent = message;
  }

  hideErrors() {
    this.errorsTarget.classList.add('hidden');
  }
}
