import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'errors',
    'key',
  ];

  onSaveSuccess(e) {
    const [
      response,
      status,
      xhr,
    ] = e.detail;

    this.clearErrors();

    document.dispatchEvent(
      new CustomEvent(
        'project:updated',
        {
          detail: {
            key: this.data.get('key'),
            html: xhr.response,
          },
        },
      ),
    );
  }

  onSaveFail(event) {
    const [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  setKeyValue(value) {
    if (this.hasKeyTarget) {
      this.keyTarget.value = value;
    }
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }

  clearErrors() {
    if (this.hasErrorTarget) {
      this.errorsTarget.innerHTML = '';
    }
  }
}
