import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'countryField',
    'cityField',
    'cityHiddenField',
  ];

  connect() {
    this.setupValidation();
  }

  disconnect() {
    this.validator.destroy();
    this.validator = null;

    if (this.autocomplete) {
      google.maps.event.removeListener(this.autocomplete, this.onPlaceChanged);
      google.map.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = null;
    }
  }

  onCountryChange = () => {
    this.setupCitySearch();
    this.toggleCityField();
  }

  onCityChange = (e) => {
    if (e.key !== 'Enter' && !e.key.startsWith('Arrow')) {
      this.cityHiddenFieldTarget.value = '';
    }
  }

  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    this.cityHiddenFieldTarget.value = place.name;
    this.cityHiddenFieldTarget.dispatchEvent(new Event('change'));
    $(this.element).valid();
  }

  toggleCityField() {
    let disabled = true;
    if (this.countryCode) {
      disabled = false;
    }
    this.cityFieldTarget.disabled = disabled;
    if (disabled) {
      this.resetCityField();
    }
  }

  resetCityField() {
    this.cityFieldTarget.value = '';
    this.cityHiddenFieldTarget.value = '';
  }

  setupValidation() {
    this.validator = $(this.element).validate({
      rules: {
        'user[email]': {
          email: true,
          required: true,
        },
        'user[password]': {
          required: (element) => {
            console.log(this.passwordRequired);
            if (this.passwordRequired) {
              return true;
            }
            const password = element.value;
            return password.length > 0;
          },
          minlength: 3,
        },
        'user[password_confirmation]': {
          required: (element) => {
            if (this.passwordRequired) {
              return true;
            }
            const confirmation = element.value;
            return confirmation.length > 0;
          },
          equalTo: '#user_password',
        },
        'user[first_name]': {
          required: true,
        },
        'user[last_name]': {
          required: true,
        },
      },
      messages: {
        'user[email]': {
          email: 'Please enter a valid email address',
          required: 'The email address is required',
        },
        'user[password]': {
          required: 'The password is required',
          minlength: 'Please enter at least 6 characters for the password',
        },
        'user[password_confirmation]': {
          required: 'The password confirmation is required',
          equalTo: 'The passwords do no match',
        },
      },
      highlight(element) {
        $(element).closest('section').addClass('error-wrapper');
      },
      unhighlight(element) {
        $(element).closest('section').removeClass('error-wrapper');
      },
    });
  }

  setupCitySearch() {
    const country = this.countryCode;
    if (!country) {
      return;
    }
    const options = {
      types: [
        '(cities)',
      ],
      componentRestrictions: {
        country,
      },
    };

    if (!this.autocomplete) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.cityFieldTarget,
        options,
      );

      google.maps.event.addListener(this.autocomplete, 'place_changed', this.onPlaceChanged);
    } else {
      this.autocomplete.setComponentRestrictions({ country });
    }
  }

  get passwordRequired() {
    return this.data.get('password-required');
  }

  get countryCode() {
    return this.countryFieldTarget.value;
  }
}
