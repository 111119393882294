/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { Application } from '@hotwired/stimulus';
import Dropdown from 'stimulus-dropdown';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import './utils/login-interceptor';

require('@rails/activestorage').start();

function disableTrixEditorFileUpload() {
  document.addEventListener('trix-file-accept', (event) => {
    event.preventDefault();
  });
}

const application = Application.start();
application.register('dropdown', Dropdown);
window.Stimulus = application;

const context = require.context('./controllers', true, /.js$/);
window.Stimulus.load(definitionsFromContext(context));
// Support component names relative to this directory:
const componentRequireContext = require.context('./components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

window.addEventListener('DOMContentLoaded', () => {
  disableTrixEditorFileUpload();
});

document.addEventListener('turbolinks:load', () => {
  disableTrixEditorFileUpload();
});

require('@rails/actiontext');
