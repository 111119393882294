import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = [
    'applyCouponButton',
    'couponCodeTextField',
    'couponCodeHiddenField',
    'errors',
    'summary',
  ];

  onCouponCodeChange(e) {
    const {
      target: {
        value,
      },
    } = e;

    if (value) {
      this.applyCouponButtonTarget.disabled = false;
    } else {
      this.applyCouponButtonTarget.disabled = true;
    }
  }

  async onApplyCouponCode() {
    const planId = this.data.get('id');
    const couponCode = (this.couponCodeTextFieldTarget.value || '').trim();

    if (!couponCode) {
      return;
    }
    const url = `/plans/${planId}/coupons`;

    try {
      const res = await axios.post(
        url,
        {
          coupon_code: couponCode,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
          },
        },
      );
      this.couponCodeTextFieldTarget.value = '';
      this.couponCodeHiddenFieldTarget.value = couponCode;

      this.errorsTarget.innerHTML = '';
      this.summaryTarget.innerHTML = res.data;
    } catch (e) {
      this.errorsTarget.innerHTML = e.response.data;
      Snackbar.show({
        text: 'The coupon code is not valid.',
        actionTextColor: ERROR_COLOR,
      });
    }
  }
}
