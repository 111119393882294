import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = [
    'form',
    'errors',
    'select',
    'container',
    'searchForm',
    'searchField',
    'judges',
    'addButton',
  ];

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.hitEnterKey = true;
    }
  };

  onSearch = () => {
    if (!this.hitEnterKey) {
      this.searchFieldTarget.value = '';
      this.searchFormTaget.submit();
    }
  };

  onDeleteSuccess(event) {
    const [, , xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;
    Snackbar.show({ text: 'The team member was successfully removed' });
  }

  onDeleteFail() {
    Snackbar.show({
      text: 'There was a problem removing the judge',
      actionTextColor: ERROR_COLOR,
    });
  }

  onSaveSuccess(event) {
    const [, , xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response;
    this.hideForm();
    Snackbar.show({
      text: 'The competition judge was successfully invited',
    });
  }

  onSaveFail(event) {
    const [, , xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  onCancelSave() {
    this.hideForm();
    this.addButtonTarget.classList.remove('hide');
  }

  onShowForm(event) {
    const [, , xhr] = event.detail;
    this.formTarget.innerHTML = xhr.response;
    this.showForm();
    this.addButtonTarget.classList.add('hide');
  }

  onResendInvite() {
    Snackbar.show({
      text: 'The invitation has been resent.',
    });
  }

  showForm() {
    this.formTarget.classList.remove('hidden');
  }

  hideForm() {
    this.formTarget.classList.add('hidden');
  }
}
