import React, { Component } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { CheckIcon, ArrowLongLeftIcon } from '@heroicons/react/20/solid';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { titleize, humanize } from 'underscore.string';
import axios from 'axios';
import Alert from './Alert';
import Spinner from './Spinner';
import CheckboxQuestion from './competitions/applications/CheckboxQuestion';
import DropdownQuestion from './competitions/applications/DropdownQuestion';
import ParagraphQuestion from './competitions/applications/ParagraphQuestion';
import ShortQuestion from './competitions/applications/ShortQuestion';
import FileUploadQuestion from './competitions/applications/FileUploadQuestion';

import { QuestionTypes } from '../constants/questions';

function validateFileUploadQuestion(answer) {
  let success = true;
  let errorMessage = '';

  if (!answer.not_applicable && !answer.attachment && !answer.attachment_url) {
    success = false;
    errorMessage = 'The field is required';
  }

  return {
    success,
    errorMessage,
  };
}

function validateMultiselectQuestion(answer) {
  let success = true;
  let errorMessage = '';

  if (!answer.data) {
    success = false;
    errorMessage = 'The field is required';
  } else {
    const { choices } = JSON.parse(answer.data);
    if (choices.length === 0) {
      success = false;
      errorMessage = 'The field is required';
    }
  }

  return {
    success,
    errorMessage,
  };
}

function validateShortQuestion(answer) {
  let success = true;
  let errorMessage = '';

  if (!answer.data) {
    success = false;
    errorMessage = 'The field is required';
  }
  return {
    success,
    errorMessage,
  };
}

function validateQuestion(questionType, answer) {
  switch (questionType) {
    case 'checkbox':
      return validateMultiselectQuestion(answer);
    case 'file':
      return validateFileUploadQuestion(answer);
    default:
      return validateShortQuestion(answer);
  }
}

function mapServerErrors(errors) {
  const errorGroups = [];
  if (errors.base) {
    errorGroups.push({
      title: errors.base[0],
      errors: [],
    });
  }

  if (errors.project) {
    errorGroups.push({
      title:
        'The venture included in this application has the following issues:',
      errors: errors.project,
    });
  }
  if (errors.profile) {
    errorGroups.push({
      title:
        'The profile included in this application has the following issues:',
      errors: errors.project,
    });
  }
  return errorGroups;
}

function renderErrorGroup(errorGroup, index) {
  const errorList =
    errorGroup.errors.length > 0 ? (
      <ul>
        {errorGroup.errors.map((error, i) => (
          <li className="mb-2" key={i}>
            {error}
          </li>
        ))}
      </ul>
    ) : null;

  return (
    <div className="mb-4" key={index}>
      <span className="mb-2">{errorGroup.title}</span>
      {errorList}
    </div>
  );
}
function parseServerErrors(err) {
  const {
    response: {
      data: { errors },
    },
  } = err;
  return errors;
}

function getProjectUrl(project) {
  const { id } = project;
  return `/projects/${id}`;
}

function applyPromoCode(id, promoCode) {
  return axios({
    method: 'post',
    url: `/submissions/${id}/promo-codes.json`,
    data: {
      promo_code: promoCode,
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
  });
}

function saveApplication(id, data) {
  return axios({
    method: 'patch',
    url: `/submissions/${id}.json`,
    data,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
  });
}

function submitApplication(id) {
  return axios({
    method: 'post',
    url: `/submissions/${id}/submissions.json`,
    data: {},
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
  });
}

function mapAnswers(answers = [], questions = []) {
  return questions.reduce((answerMap, question) => {
    const existingAnswer = answers.find((a) => a.question_id === question.id);

    let answer;

    if (existingAnswer) {
      answer = { ...existingAnswer };
    } else {
      answer = {
        question_id: question.id,
        data: null,
      };
    }

    return {
      ...answerMap,
      [question.id]: answer,
    };
  }, {});
}

function mapApplicationToState(application, questions) {
  const answers = mapAnswers(application.answers, questions);
  return {
    application: cloneDeep(application),
    answers,
  };
}

function mapPropsToState(props) {
  const { application, questions } = props;
  return {
    ...mapApplicationToState(application, questions),
    alertTitle: '',
    errors: {},
    errorDetails: [],
    saving: false,
    submitting: false,
    validating: false,
    profileIncomplete: false,
    promoCode: '',
    feeWaived: props.fee_waived || false,
    promoCodeError: false,
  };
}

function LoadingCircle({ text }) {
  return (
    <div className="flex items-center">
      <Spinner className="w-5 h-5 mr-2" />
      {text}
    </div>
  );
}

LoadingCircle.propTypes = {
  text: PropTypes.string,
};

LoadingCircle.defaultProps = {
  text: 'Loading...',
};

const validationTitle =
  'Please fill out all required fields and submit the application again';

export default class CompetitionApplication extends Component {
  constructor(props) {
    super(props);
    this.state = mapPropsToState(props);
  }

  getType() {
    const {
      competition: { competition_type },
    } = this.props;
    return competition_type;
  }

  getAnswer(questionId) {
    const { answers } = this.state;
    return answers[questionId];
  }

  getQuestionComponent(question, index) {
    const { answers } = this.state;

    const answer = answers[question.id];
    switch (question.question_type) {
      case QuestionTypes.DROP_DOWN:
        return (
          <DropdownQuestion
            key={question.id}
            index={index}
            question={question}
            answer={answer}
            validationStatus={this.getValidationResult(question.id)}
            onAnswerChange={this.handleAnswerChange(question.id)}
          />
        );
      case QuestionTypes.CHECK_BOX:
        return (
          <CheckboxQuestion
            key={question.id}
            index={index}
            question={question}
            answer={answer}
            validationStatus={this.getValidationResult(question.id)}
            onAnswerChange={this.handleAnswerChange(question.id)}
          />
        );
      case QuestionTypes.PARAGRAPH:
        return (
          <ParagraphQuestion
            key={question.id}
            index={index}
            question={question}
            answer={answer}
            validationStatus={this.getValidationResult(question.id)}
            onAnswerChange={this.handleAnswerChange(question.id)}
          />
        );
      case QuestionTypes.FILE:
        return (
          <FileUploadQuestion
            key={question.id}
            index={index}
            question={question}
            answer={answer}
            validationStatus={this.getValidationResult(question.id)}
            onAnswerChange={this.handleAnswerChange(question.id)}
            onToggleNotApplicable={this.handleToggleNotApplicable(question.id)}
            directUploadUrl={this.props.directUploadPath}
          />
        );
      default:
        return (
          <ShortQuestion
            key={question.id}
            index={index}
            question={question}
            validationStatus={this.getValidationResult(question.id)}
            answer={answer}
            onAnswerChange={this.handleAnswerChange(question.id)}
          />
        );
    }
  }

  getValidationResult(questionId) {
    const { errors } = this.state;
    const hasError = Object.prototype.hasOwnProperty.call(errors, questionId);

    if (hasError) {
      return errors[questionId];
    }
    return null;
  }

  handleClosePromoCodeError = () => this.setState({ promoCodeError: false });

  handleCloseAlert = () => this.setState({ errors: {}, errorDetails: [] });

  handleApplyPromoCode = async () => {
    const {
      application: { id },
    } = this.props;

    try {
      const { promoCode } = this.state;

      await applyPromoCode(id, promoCode);

      this.setState({ feeWaived: true, promoCodeError: false });
    } catch (e) {
      console.error(e);
      this.setState({ promoCodeError: true });
    }
  };

  handlePromoCodeChange = (e) => {
    const {
      target: { value: promoCode },
    } = e;
    this.setState({ promoCode });
  };

  handleToggleNotApplicable = (questionId) => (checked) => {
    this.setState((prevState) => {
      const answers = cloneDeep(prevState.answers);
      answers[questionId].not_applicable = checked;
      return {
        answers,
      };
    });
  };

  handleAnswerChange = (questionId) => (data) => {
    this.setState((prevState) => {
      const question = this.props.questions.find((q) => q.id === questionId);
      const answers = cloneDeep(prevState.answers);
      if (question.question_type === QuestionTypes.FILE) {
        answers[questionId].attachment = data.signed_id;
        answers[questionId].data = data.key;
      } else {
        answers[questionId].data = data;
      }
      return {
        answers,
      };
    });
  };

  handlePayButtonClick = () => {
    document.getElementById('checkout-form').submit();
  };

  handleSave = (e) => {
    e.preventDefault();
    const {
      application: { id },
    } = this.props;
    if (this.isSubmitted()) {
      const { errors, errorDetails } = this.validate();
      const hasErrors =
        Object.keys(errors).length > 0 || errorDetails.length > 0;

      if (hasErrors) {
        return;
      }
    }
    const data = this.transformRequest();

    this.setState(
      {
        saving: true,
      },
      () => {
        saveApplication(id, data)
          .then((res) => {
            this.hideSaving();
            this.handleSaveSuccess(res);
            Snackbar.show({ text: 'Your application has been saved' });
          })
          .catch((err) => {
            this.hideSaving();
            this.handleSaveFail(err);
          });
      },
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      application: { id },
    } = this.props;
    const { errors, errorDetails } = this.validate(true);
    const hasErrors = Object.keys(errors).length > 0 || errorDetails.length > 0;

    if (hasErrors) {
      return;
    }

    const data = this.transformRequest();

    this.setState(
      {
        submitting: true,
      },
      () => {
        saveApplication(id, data)
          .then((res) => {
            this.handleSaveSuccess(res);
            submitApplication(id)
              .then((submitRes) => {
                this.handleSaveSuccess(submitRes);
                Snackbar.show({ text: 'Your application has been submitted' });

                this.hideSubmitting();
              })
              .catch((err) => {
                this.hideSubmitting();
                this.handleSubmitFail(err);
              });
          })
          .catch((err) => {
            this.hideSubmitting();
            this.handleSaveFail(err);
          });
      },
    );
  };

  handleSubmitFail = (err) => {
    const resError = parseServerErrors(err);
    if (resError) {
      this.setState({
        alertTitle: 'There were problems submitting your application',
        errorDetails: mapServerErrors(resError),
        errors: {},
      });
    }
  };

  handleSaveFail = (err) => {
    const resError = parseServerErrors(err);
    if (resError) {
      this.setState({
        alertTitle: 'There were problems saving your application',
        errorDetails: mapServerErrors(resError),
        errors: {},
      });
    }
  };

  handleSaveSuccess = (res) => {
    const {
      data: { application },
    } = res;
    this.setState({
      ...mapApplicationToState(application, this.props.questions),
    });
  };

  applyPromoCodeDisabled = () => this.state.promoCode.length === 0;

  hideSaving() {
    this.setState({
      saving: false,
    });
  }

  hideSubmitting() {
    this.setState({
      submitting: false,
    });
  }

  isSubmitted() {
    const {
      application: { status },
    } = this.state;

    return status === 'submitted' || status === 'awaiting_review';
  }

  canSubmit() {
    const {
      application: { profile_fields_completed, submittable },
      feeWaived,
    } = this.state;

    const { fee_required, fee_paid } = this.props;

    return (
      profile_fields_completed &&
      submittable &&
      ((fee_required && fee_paid) || !fee_required || feeWaived)
    );
  }

  paymentRequired() {
    const { fee_required, fee_paid } = this.props;
    const { feeWaived } = this.state;

    if (!feeWaived && fee_required && !fee_paid) {
      return true;
    }

    return false;
  }

  validate(submitting = false) {
    const errors = {};
    const errorDetails = [];

    const { questions } = this.props;

    questions.forEach((question) => {
      const validationResult = validateQuestion(
        question.question_type,
        this.getAnswer(question.id),
      );

      if (!validationResult.success) {
        errors[question.id] = validationResult;
      }
    });

    if (submitting && this.paymentRequired()) {
      errorDetails.push({
        title: 'Payment Required',
        errors: ['Please pay the application fee.'],
      });
    }
    this.setState({ alertTitle: validationTitle, errors, errorDetails });
    return { alertTitle: validationTitle, errors, errorDetails };
  }

  hasErrors() {
    const { errors, errorDetails } = this.state;

    if (Object.keys(errors).length || errorDetails.length) {
      return true;
    }
    return false;
  }

  transformRequest() {
    const { answers: userAnswers } = this.state;

    const answers = Object.keys(userAnswers).map((question_id) => {
      const { attachment, data, id, not_applicable } = userAnswers[question_id];

      const answer = {
        _destroy: false,
        question_id,
        data,
        not_applicable,
      };

      if (id) {
        answer.id = id;
      }

      if (attachment) {
        answer.attachment = attachment;
      }

      return answer;
    });

    const request = {
      competition_application: {
        answers_attributes: answers,
      },
    };

    return request;
  }

  renderStatusComponent() {
    const { application } = this.state;
    const { status } = application;

    const { project, competition } = this.props;

    switch (status) {
      case 'submitted':
        return (
          <SubmittedStatus
            application={application}
            competitionType={competition.competition_type}
            project={project}
          />
        );
      case 'started':
        return <StartedStatus />;
      case 'awaiting_review':
        return <AwaitingReviewStatus />;

      case 'promoted':
      case 'answers_requested':
        return (
          <AnswersRequestedStatus
            application={application}
            onSubmit={this.handleSubmit}
          >
            {this.renderSubmitButton()}
          </AnswersRequestedStatus>
        );
      case 'passed':
        return <PassedStatus competition={competition} />;
      default:
        return null;
    }
  }

  renderStatusSection() {
    const manageApplicationsUrl = '/applications';

    return (
      <div className="flex flex-wrap mb-16 md:-mx-6">
        <div className="w-full md:w-1/3 md:px-6">
          <a
            className="mb-4 flex items-center space-x-2"
            href={manageApplicationsUrl}
          >
            <ArrowLongLeftIcon className="mr-2 w-5 h-5" />
            <span>Back to Manage Applications</span>
          </a>
        </div>
        <div className="w-full md:w-2/3 md:px-6">
          <div
            className="px-4 py-3 bg-white border-t-4 rounded rounded-b shadow-md border-blue-dark text-blue-darkest"
            role="alert"
          >
            <div className="flex justify-between mb-6">
              {this.renderHeading()}
              <div className="grow-0 shrink-0">{this.renderStatusBadge()}</div>
            </div>
            {this.renderCongratulations()}
            <div className="flex">
              <div className="py-1">
                <svg
                  className="w-6 h-6 mr-2 fill-current text-blue-darker"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              {this.renderStatusComponent()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCongratulations() {
    const {
      application: { status, round, current_round },
    } = this.state;

    if (round === 1 || status === 'passed') {
      return null;
    }

    const text = (
      <p>
        Your application has been promoted the{' '}
        <strong>{current_round.name}</strong> round.
      </p>
    );

    return (
      <div className="w-full p-4 my-4 bg-green-100 rounded-md">
        <div className="flex">
          <div className="shrink-0">
            <svg
              className="w-5 h-5 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="mb-2 text-sm font-bold text-green-800 leading-5">
              Congratulations!
            </h3>
            <h3 className="text-sm font-medium text-green-800 leading-5">
              {text}
            </h3>
          </div>
        </div>
      </div>
    );
  }

  renderHeading() {
    const { competition } = this.props;
    const competitionUrl = `/competitions/${competition.id}`;

    return (
      <div>
        <h4 className="inline-block mr-2 text-xl text-black">
          Application to&nbsp;
          <a href={competitionUrl} rel="noopener noreferrer" target="_blank">
            {competition.name}
          </a>
        </h4>
      </div>
    );
  }

  renderStatusBadge() {
    const {
      application: { status },
    } = this.state;

    const statusText = titleize(humanize(status || 'N/A'));

    if (
      status === 'promoted' ||
      status === 'submitted' ||
      status === 'awaiting_review'
    ) {
      return (
        <span className="inline-block px-2 py-1 text-xs font-bold text-white rounded-full bg-brand-blue">
          {statusText}
        </span>
      );
    }

    if (status === 'passed') {
      return (
        <span className="inline-block px-2 py-1 text-xs font-bold bg-red-400 rounded-full text-red-lightest">
          {statusText}
        </span>
      );
    }
    return (
      <span className="inline-block px-2 py-1 text-xs font-bold text-yellow-700 bg-yellow-100 rounded-full">
        {statusText}
      </span>
    );
  }

  renderErrors() {
    const { alertTitle, errorDetails } = this.state;

    if (!this.hasErrors()) {
      return null;
    }
    const errorList =
      errorDetails.length > 0 ? (
        <div className="mt-4 server-errors-wrapper">
          {errorDetails.map((errorGroup, i) => renderErrorGroup(errorGroup, i))}
        </div>
      ) : null;

    return (
      <div className="flex-1">
        <Alert title={alertTitle} onClose={this.handleCloseAlert} type="error">
          {errorList}
        </Alert>
      </div>
    );
  }

  renderProfileStatus() {
    const {
      application: { profile_fields_completed },
    } = this.state;

    if (profile_fields_completed) {
      return (
        <Alert type="success" hideClose>
          <span className="text-sm">
            All required fields have been filled out
          </span>
        </Alert>
      );
    }
    return (
      <Alert type="error" hideClose>
        Please fill the required profile fields&nbsp;
        <a href="/profile" target="_blank" rel="noopener noreferrer">
          here
        </a>
      </Alert>
    );
  }

  renderVentureStatus() {
    const {
      application: { profile_fields_completed },
    } = this.state;

    const { project } = this.props;

    const projectUrl = `/projects/${project.id}`;
    if (profile_fields_completed) {
      return (
        <Alert type="success" hideClose>
          <span className="text-sm">
            All required fields have been filled out
          </span>
        </Alert>
      );
    }
    return (
      <Alert type="error" hideClose>
        Please fill the required venture fields&nbsp;
        <a
          href={projectUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="underline font-bold text-red-800"
        >
          here
        </a>
        &nbsp;and re-visit this application.
      </Alert>
    );
  }

  renderProfile() {
    const { project, competition } = this.props;
    const projectUrl = `/projects/${project.id}`;

    let entity;

    const isPeopleApplication = competition.competition_type === 'people';
    const isStartupApplication =
      !competition.competition_type ||
      competition.competition_type === 'startup';
    if (isPeopleApplication) {
      entity = 'Profile';
    } else {
      entity = 'Venture';
    }
    return (
      <div className="flex flex-wrap md:-mx-6">
        <div className="w-full md:w-1/3 md:px-6">
          <h3 className="mb-4 text-2xl">Your {entity}</h3>
        </div>
        <div className="w-full md:w-2/3 md:px-6">
          <div className="p-6 bg-white rounded shadow">
            <h4 className="mb-4 text-xl">
              {isStartupApplication && (
                <a href={projectUrl} rel="noopener noreferrer" target="_blank">
                  {project.title}
                </a>
              )}
              {isPeopleApplication && (
                <a href="/profile" rel="noopener noreferrer" target="_blank">
                  Your Profile
                </a>
              )}
            </h4>
            {isPeopleApplication && this.renderProfileStatus()}
            {isStartupApplication && this.renderVentureStatus()}
          </div>
        </div>
      </div>
    );
  }

  renderPayments() {
    if (!this.props.fee_required) {
      return null;
    }
    const { competition } = this.props;
    const paymentRequired = this.paymentRequired();
    return (
      <div className="flex flex-wrap md:-mx-6">
        <div className="w-full md:w-1/3 md:px-6">
          <h3 className="mb-4 text-2xl">Application Fee</h3>
        </div>
        <div className="w-full md:w-2/3 md:px-6">
          <div className="p-6 bg-white rounded shadow">
            {paymentRequired && (
              <div className="mb-4">
                <Alert
                  title="Please pay the application fee."
                  onClose={this.handleCloseAlert}
                  type="error"
                />
              </div>
            )}
            <div className="flex justify-end" />
            <p className="text-base text-grey-700 mb-1">
              {competition.name} charges a fee of{' '}
              <strong>{accounting.formatMoney(this.props.fee_amount)}</strong>{' '}
              to finalize this submission.
            </p>
            <div className="table-wrapper mb-4">
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Spark XYZ</td>
                    <td className="text-right">
                      {accounting.formatMoney(this.props.customer_fee)}
                    </td>
                  </tr>
                  <tr>
                    <td>{competition.name}</td>
                    <td className="text-right">
                      {accounting.formatMoney(this.props.collective_fee)}
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td className="text-right font-bold">
                      {accounting.formatMoney(this.props.fee_amount)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {this.props.fee_paid && (
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-lightest text-blue-darker">
                  <CheckIcon className="w-5 h-5" />
                </span>
                <span className="font-bold">Paid</span>
              </div>
            )}
            {this.state.feeWaived && (
              <div className="flex items-center">
                <span className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-lightest text-blue-darker">
                  <CheckIcon className="w-5 h-5" />
                </span>
                <span className="font-bold">Fee Waived</span>
              </div>
            )}
            {!this.state.feeWaived && !this.props.fee_paid && (
              <div>
                <div className="sm:flex flex-wrap items-center sm:justify-between">
                  <div className="mb-4 sm:mb-0 flex items-center space-x-2">
                    <input
                      type="text"
                      className="error form-field w-48"
                      placeholder="Promo Code"
                      onChange={this.handlePromoCodeChange}
                      value={this.state.promoCode}
                    />
                    <button
                      type="button"
                      className="btn btn-gray"
                      onClick={this.handleApplyPromoCode}
                      disabled={this.applyPromoCodeDisabled()}
                    >
                      Apply Promo Code
                    </button>
                  </div>
                  <h5 className="my-2 sm:my-0 font-medium text-center sm:text-left">
                    OR
                  </h5>
                  <button
                    type="button"
                    className="btn btn-blue"
                    onClick={this.handlePayButtonClick}
                  >
                    Pay Fee
                  </button>
                </div>
                {this.state.promoCodeError && (
                  <div className="mt-4">
                    <Alert
                      type="error"
                      onClose={this.handleClosePromoCodeError}
                      title="Invalid Promo Code"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderQuestionsStatus() {
    const {
      application: { questions_completed },
    } = this.state;

    if (questions_completed) {
      return (
        <Alert type="success" hideClose>
          <span className="text-sm">
            Thank you for answering the questions below.
          </span>
        </Alert>
      );
    }
    return (
      <Alert type="error" hideClose>
        <span className="text-sm">Please answer the questions below&nbsp;</span>
      </Alert>
    );
  }

  renderQuestions() {
    const { questions } = this.props;

    const isPeopleApplication = this.getType() === 'people';

    let entity;
    if (isPeopleApplication) {
      entity = 'profile';
    } else {
      entity = 'startup';
    }

    return (
      <div className="flex flex-wrap md:-mx-6">
        <div className="w-full md:w-1/3 md:px-6">
          <h3 className="mb-4 text-2xl">Questions</h3>
          <p className="text-base text-grey-dark">
            Here are some additional questions about your {entity}
          </p>
        </div>
        <div className="w-full md:w-2/3 md:px-6">
          <div className="p-6 bg-white rounded shadow">
            {this.renderQuestionsStatus()}
            {questions.length === 0 && (
              <p className="flex items-center mt-4">
                <span className="flex items-center justify-center w-8 h-8 mr-2 rounded-full bg-blue-lightest text-blue-darker">
                  <CheckIcon className="w-5 h-5" />
                </span>
                <span className="text-sm">
                  There are no questions for this round.
                </span>
              </p>
            )}

            {questions.length > 0 &&
              questions.map((question, i) => (
                <div key={question.id} className="mt-4 mb-6">
                  {this.getQuestionComponent(question, i)}
                </div>
              ))}
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }

  renderSaveButton() {
    let buttonContent;

    if (this.state.saving) {
      buttonContent = <LoadingCircle text="Saving..." />;
    } else {
      buttonContent = 'Save';
    }

    return (
      <button
        type="button"
        className="mr-4 btn btn-hollow-blue"
        disabled={this.state.saving}
        onClick={this.handleSave}
      >
        {buttonContent}
      </button>
    );
  }

  renderSubmitButton() {
    let buttonContent;
    const disabled = this.state.submitting || !this.canSubmit();

    if (this.state.submitting) {
      buttonContent = <LoadingCircle text="Submitting..." />;
    } else {
      buttonContent = 'Submit';
    }

    return (
      <button
        type="submit"
        className="btn btn-primary"
        disabled={disabled}
        onClick={this.handleSubmit}
      >
        {buttonContent}
      </button>
    );
  }

  render() {
    const {
      application: { submittable },
    } = this.state;

    return (
      <div>
        <div className="container mx-auto my-16">
          {this.renderStatusSection()}
          {this.renderPayments()}
          <hr />
          {this.renderProfile()}
          <hr />
          {this.renderQuestions()}
        </div>
        <div className="sticky bottom-0 left-0 bg-white">
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-end md:-mx-6">
              <div className="w-full md:px-6 md:w-2/3">
                <div className="flex flex-wrap items-center justify-end py-4 sm:space-x-4">
                  {this.renderErrors()}
                  {this.renderSaveButton()}
                  {submittable && this.renderSubmitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SubmittedStatus({ application, project, competitionType }) {
  const { submitted_at } = application;
  const isPeopleApplication = competitionType === 'people';
  const isStartupApplication = !isPeopleApplication;

  const formattedDate = moment(submitted_at).format('MM/DD/YYYY');
  let projectUrl;

  if (isStartupApplication) {
    projectUrl = getProjectUrl(project);
  }
  return (
    <div>
      <h4 className="mb-2 text-base text-black">
        Application Submitted&nbsp;
        <span className="text-sm">({formattedDate})</span>
      </h4>
      <p className="text-sm text-blue-darker">
        Congratulations on submitting your application!
      </p>
      {isPeopleApplication && (
        <div>
          <p className="text-sm text-blue-darker">
            You might want to check out the other applications we have open
            right now.
          </p>
          <div className="flex items-center my-4">
            <a
              href="/applications"
              className="mr-2 btn btn-primary"
              rel="noopener noreferrer"
              target="_blank"
            >
              Applications
            </a>
          </div>
        </div>
      )}
      {isStartupApplication && (
        <div>
          <p className="text-sm text-blue-darker">
            You might want to check out the other applications we have open
            right now, or check out the venture profile you just created!
          </p>
          <div className="flex items-center my-4">
            <a
              href="/applications"
              className="mr-2 btn btn-primary"
              rel="noopener noreferrer"
              target="_blank"
            >
              Applications
            </a>
            <a
              href={projectUrl}
              className="btn btn-hollow-blue"
              rel="noopener noreferrer"
              target="_blank"
            >
              Your Venture Profile
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

SubmittedStatus.propTypes = {
  application: PropTypes.shape({
    status: PropTypes.string.isRequired,
    submitted_at: PropTypes.string.isRequired,
  }).isRequired,
  project: PropTypes.shape({
    id: PropTypes.string,
  }),
  competitionType: PropTypes.string.isRequired,
};

function PassedStatus({ competition }) {
  const { name } = competition;

  const firstSentence = `${name} truly appreciates your application.`;

  return (
    <div className="text-sm text-blue-darker">
      <p className="mb-2 text-sm text-blue-darker">{firstSentence}</p>
      <p className="mb-2">
        This was a highly competitive process and unfortunately there were other
        startups that fit our application profile more accurately.
      </p>
      <p className="mb-2">
        Keep fighting, we look forward to your progress and seeing your success.
      </p>
    </div>
  );
}

PassedStatus.propTypes = {
  competition: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

function AwaitingReviewStatus() {
  return (
    <div>
      <h4 className="mb-2 text-base text-black">Pending Review</h4>
      <p className="text-sm text-blue-darker">
        The application judges are currently reviewing your application answers.
      </p>
    </div>
  );
}

function AnswersRequestedStatus({ application, children }) {
  const { current_round_completed } = application;

  if (current_round_completed) {
    return (
      <div>
        <h4 className="mb-2 text-base text-black">Answers Requested</h4>
        <p className="mb-2 text-sm text-blue-darker">
          Thank you for filling out the fields below
        </p>
        <p className="mb-4 text-sm text-blue-darker">
          Please submit your application so that the application judges can
          review your answers.
        </p>
        {children}
      </div>
    );
  }

  return (
    <div>
      <h4 className="mb-2 text-base text-black">Answers Requested</h4>
      <p className="mb-2 text-sm text-blue-darker">
        The application judges have requested additional information.
      </p>
      <p className="text-sm text-blue-darker">
        Please review the sections below.
      </p>
    </div>
  );
}

AnswersRequestedStatus.propTypes = {
  application: PropTypes.shape({
    current_round_completed: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.any.isRequired,
};

function StartedStatus() {
  return (
    <div>
      <h4 className="mb-2 text-base text-black">Application Started</h4>
      <p className="text-sm text-blue-darker">
        Please fill out the sections below and submit your application.
      </p>
    </div>
  );
}

CompetitionApplication.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    round: PropTypes.number.isRequired,
    current_round: PropTypes.shape({
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    }).isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        question_id: PropTypes.number.isRequired,
        data: PropTypes.any,
      }),
    ),
    profile_fields_completed: PropTypes.bool.isRequired,
    questions_completed: PropTypes.bool.isRequired,
    current_round_completed: PropTypes.bool.isRequired,
    submittable: PropTypes.bool.isRequired,
  }).isRequired,
  fee_required: PropTypes.bool.isRequired,
  fee_paid: PropTypes.bool.isRequired,
  fee_amount: PropTypes.number,
  competition: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    competition_type: PropTypes.string.isRequired,
  }).isRequired,
  directUploadPath: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question_type: PropTypes.string,
    }),
  ),
};

CompetitionApplication.defaultProps = {
  questions: [],
};
