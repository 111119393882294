const GRADE = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  F: 'F',
};

export const GRADES = [GRADE.A, GRADE.B, GRADE.C, GRADE.D, GRADE.F];

const A = 10;
const B = 7.5;
const C = 5;
const D = 2.5;
const F = 0;

const faceValueMap = {
  A,
  B,
  C,
  D,
  F,
};

export default faceValueMap;
