import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ComponentRoundCard from './CompetitionRoundCard';

export default class extends Component {
  static propTypes = {
    competitionRounds: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })),
    onEditRound: PropTypes.func.isRequired,
    onDeleteRound: PropTypes.func.isRequired,
    onShowJudgingSetup: PropTypes.func.isRequired,
  }

  static defaultProps = {
    competitionRounds: [],
  }

  renderEmptyList() {
    return (
      <div>
        <p>
          There are no competition rounds. Let&apos;s create one by clicking on the button below!
        </p>
      </div>
    );
  }

  renderCompetitionRounds() {
    const {
      competitionRounds,
    } = this.props;
    return (
      <div className="mb-6">
        {competitionRounds.map(competitionRound => (
          <ComponentRoundCard
            key={competitionRound.id}
            competitionRound={competitionRound}
            onDeleteRound={id => this.props.onDeleteRound(id)}
            onEditRound={id => this.props.onEditRound(id)}
            onShowJudgingSetup={id => this.props.onShowJudgingSetup(id)}
          />
        ))}
      </div>
    );
  }

  render() {
    const {
      competitionRounds,
    } = this.props;

    if (competitionRounds.length === 0) {
      return this.renderEmptyList();
    }
    return this.renderCompetitionRounds();
  }
}
