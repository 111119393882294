import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'applyButton',
  ];

  connect() {
    if (window.location.hash && window.location.hash.substr(1) === 'apply') {
      this.applyButtonTarget.click();
    }
  }
}
