import { humanize } from 'underscore.string';

export function mapRailsErrorsHash(errorsHash) {
  return Object.keys(errorsHash).reduce((mappedErrorsHash, errorKey) => {
    const fullMessages = errorsHash[errorKey].map(
      partialMessage => humanize(`${errorKey} ${partialMessage}`),
    );
    return {
      ...mappedErrorsHash,
      [errorKey]: fullMessages,
    };
  }, {});
}

export function errorsHashToArray(errorsHash) {
  return Object.keys(errorsHash).reduce((errorsArray, errorKey) => {
    const fullMessages = errorsHash[errorKey];
    return errorsArray.concat(...fullMessages);
  }, []);
}
