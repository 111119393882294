import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import QuestionCard from './QuestionCard';
import ProfileQuestionField from './ProfileQuestionField';
import CircleText from '../CircleText';
import { PROFIlE_TYPE } from './types';

const PROFILE_FIELDS = ['profile_field', 'profile_field_group'];

export default class CompetitionQuestionGroup extends Component {
  static propTypes = {
    questionGroup: PropTypes.shape({
      name: PropTypes.string.isRequired,
      round: PropTypes.number,
      activeTab: PropTypes.string,
      custom_questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          question_type: PropTypes.string,
          round: PropTypes.number,
        }),
      ),
      standard_questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          question_type: PropTypes.string,
          round: PropTypes.number,
        }),
      ),
    }).isRequired,
    onAddQuestion: PropTypes.func.isRequired,
    onEditQuestion: PropTypes.func.isRequired,
    onRemoveQuestion: PropTypes.func.isRequired,
  };

  getQuestionComponent = (question, i) => {
    if (PROFILE_FIELDS.includes(question.question_type)) {
      return (
        <ProfileQuestionField
          index={i}
          key={question.id}
          question={question}
          onToggleQuestion={this.props.onToggleQuestion}
        />
      );
    }
    return this.getApplicationQuestion(question, i);
  };

  getApplicationQuestion = (question, i) => (
    <QuestionCard
      key={question.id}
      index={i}
      question={question}
      onEdit={this.props.onEditQuestion}
      onRemove={this.props.onRemoveQuestion}
    />
  );

  handleAddQuestion = () =>
    this.props.onAddQuestion(this.props.questionGroup.round);

  renderEmptyQuestions() {
    return (
      <div className="p-4 bg-white rounded">
        <p>It looks like there are are no questions assigned to this round.</p>
        <button
          type="button"
          className="flex items-center justify-center btn btn-hollow-blue btn-circle"
          onClick={this.handleAddQuestion}
        >
          <PlusIcon className="grow-0 shrink-0 h-5 w-5 flex-no-grow flex-no-shrink" />
          <span>Question</span>
        </button>
      </div>
    );
  }

  renderQuestions() {
    const { questionGroup, activeTab } = this.props;

    const questions =
      activeTab === PROFIlE_TYPE
        ? questionGroup.profile_questions
        : questionGroup.custom_questions;

    if (questions.length === 0) {
      return this.renderEmptyQuestions();
    }

    return questions.map(this.getQuestionComponent);
  }

  render() {
    const {
      questionGroup: { name, round },
    } = this.props;

    return (
      <div className="mb-8 bg-white rounded shadow-lg">
        <header className="flex flex-wrap items-start justify-between p-4 bround-b bround-solid bround-grey-lightest">
          <div className="flex">
            <CircleText
              className={{
                'mr-4': true,
              }}
            >
              {round}
            </CircleText>
            <h4>{name}</h4>
          </div>
          <button
            type="button"
            className="grow-0 shrink-0 w-10 h-10 btn btn-circle btn-hollow-blue flex-no-shrink flex-no-grow"
            onClick={this.handleAddQuestion}
          >
            <PlusIcon className="w-5 h-5" />
          </button>
        </header>
        <Droppable droppableId={round.toString()}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              className={getDroppableClassName(snapshot)}
              ref={provided.innerRef}
            >
              {this.renderQuestions()}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

function getDroppableClassName(snapshot) {
  return classnames({
    'text-sm': true,
    'p-4': true,
    'bg-grey-lighter': !snapshot.isDraggingOver,
    'bg-blue-lightest': snapshot.isDraggingOver,
    border: true,
    'border-solid': true,
    'border-transparent': !snapshot.isDraggingOver,
    'border-blue-400': snapshot.isDraggingOver,
  });
}
