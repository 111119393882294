import { Controller } from '@hotwired/stimulus';
import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  static targets = [
    'card',
    'project',
    'actions',
    'errors',
  ]

  onClick(e) {
    const {
      currentTarget,
    } = e;

    const id = parseInt(currentTarget.getAttribute('data-id'), 10);
    [].forEach.call(this.cardTargets, (el) => {
      el.classList.remove('border-blue-400');
      el.classList.add('border-transparent');
    });

    currentTarget.classList.remove('border-transparent');
    currentTarget.classList.add('border-blue-400');

    this.projectTarget.value = id;

    this.actionsTarget.classList.add('flex');
    this.actionsTarget.classList.remove('hidden');
  }

  onApplicationCreated = () => {
    Snackbar.show({ text: 'Your application has been started' });
  }

  onError = (e) => {
    const [response, status, xhr] = e.detail;
    console.log('onError');

    this.errorsTarget.innerHTML = xhr.response;
    Snackbar.show({
      text: 'There was a problem starting your application',
      actionTextColor: ERROR_COLOR,
    });
  }
}
