import { Controller } from '@hotwired/stimulus';

function getSecondarySkills(parentSkills) {
  const url = '/skills/all_secondary_skills.json';
  return $.ajax(url, {
    method: 'GET',
    data: $.param({ skills: parentSkills }),
  });
}

export default class extends Controller {
  static targets = [
    'perks',
    'primarySkills',
    'primaryRecruitingContactsContainer',
    'secondaryRecruitingContactsContainer',
    'secondarySkills',
    'primaryEmail',
    'primaryPhone',
    'location',
  ];

  $primarySelect = false;

  $secondaryCheckboxes = false;

  connect() {
    this.$primarySelect = $(this.primaryRecruitingContactsContainerTarget).find(
      'select',
    );
    this.$secondaryCheckboxesContainer = $(
      this.secondaryRecruitingContactsContainerTarget,
    );
    this.$secondaryCheckboxes = this.$secondaryCheckboxesContainer.find(
      'input[type="checkbox"]',
    );

    this.$primarySelect.on('change', this.handlePrimaryChange);
    this.handlePrimaryChange();
    this.$secondarySkills = $(this.secondarySkillsTarget);
    this.$primarySkills = $(this.primarySkillsTarget);
    $.validator.setDefaults({ ignore: [] });

    const $perks = $(this.perksTarget);
    $perks.select2({
      width: '100%',
      placeholder: 'Select any perks this position might offer',
    });

    const values = $(this.secondarySkillsTarget).val();

    this.$primarySkills.select2({
      width: '100%',
      placeholder: 'Select any perks this position might offer',
    });

    this.$primarySkills
      .select2({
        width: '100%',
        placeholder: 'Select one or more primary skills',
      })
      .removeClass('hide');

    this.$primarySkills.on('select2:select', this.handlePrimarySkillChange);

    if (values || this.$primarySkills.val()) {
      this.setupSecondarySkillDropdown();
    }

    this.setupValidation();

    document.addEventListener('validate:form', this.triggerValidation);
  }

  disconnect() {
    if (this.validator) {
      this.validator.destroy();
      this.validator = null;
    }
    document.removeEventListener('validate:form', this.triggerValidation);
  }

  handlePrimaryChange = () => {
    const selectedID = this.$primarySelect.val();
    const selectedObject = this.$primarySelect.find(
      `option[value="${selectedID}"]`,
    );

    const selectedEmail = selectedObject.attr('data-email');
    const selectedPhone = selectedObject.attr('data-phone');

    if (selectedEmail) {
      this.primaryEmailTarget.value = selectedEmail;
    }

    if (selectedPhone) {
      this.primaryPhoneTarget.value = selectedPhone;
    }

    let visibleCount = 0;

    this.$secondaryCheckboxes.each((_, check) => {
      const $check = $(check);
      const $label = $(check)
        .parent()
        .find(
          `label[for="project_position_recruiting_contact_user_ids_${$check.val()}"]`,
        );

      const hide = $check.val() === selectedID;
      const display = hide ? 'none' : 'inline';

      $check.css('display', display);
      $label.css('display', display);

      if (!hide) {
        visibleCount += 1;
      }
    });

    const hideOtherSection = visibleCount === 0;
    const displayOtherSection = hideOtherSection ? 'none' : 'inline';
    this.$secondaryCheckboxesContainer.css('display', displayOtherSection);
  };

  handlePrimarySkillChange = (e) => {
    const selected = $(e.target).val();

    getSecondarySkills(selected).done((data) => {
      const fragment = document.createDocumentFragment();
      const skillsCount = (data || []).length;
      let i;
      let skill;
      let $option;

      const values = this.$secondarySkills.val();
      this.$secondarySkills.addClass('hide').empty();

      for (i = 0; i < skillsCount; i += 1) {
        skill = data[i];
        $option = $('<option />').val(skill.id).text(skill.name);
        fragment.appendChild($option[0]);
      }

      this.$secondarySkills[0].appendChild(fragment);
      this.$secondarySkills.val(values);
      if (!this.$secondarySkills.hasClass('hide')) {
        console.log('here');
        this.setupSecondarySkillDropdown();
      }
    });
  };

  setupSecondarySkillDropdown = () => {
    this.$secondarySkills
      .select2({
        width: '100%',
        placeholder: 'Select one or more secondary skills',
      })
      .removeClass('hide');
  };

  setupValidation() {
    this.validator = $(this.element)
      .validate({
        messages: {
          'project_position[title]': 'Please enter a job title',
          'project_position[description]': 'Please enter a job description',
          'project_position[location]': 'Please select a location',
          'project_position[skill_ids][]':
            'Please select the required skills for this position',
        },
        highlight: (element) => {
          $(element).closest('section').addClass('error-wrapper');
        },
        unhighlight: (element) => {
          $(element).closest('section').removeClass('error-wrapper');
        },
      });
  }

  triggerValidation() {
    console.log('trigger validation');
    $(this.element).validate();
  }
}
