/* global Stripe */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'tokenInput',
    'cardErrors',
    'serverErrors',
    'form',
    'submitButton',
    'formSubmitButton',
    'type',
    'brand',
    'country',
    'expMonth',
    'expYear',
    'last4',
  ];

  stripe = false;

  card = false;

  async connect() {
    this.loadStripe();
  }

  setupStripe = () => {
    this.stripe = Stripe(this.data.get('key'));
    const elements = this.stripe.elements();

    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    this.card = elements.create('card', { style });
    this.card.mount('#stripe-card-element');

    // Handle real-time validation errors from the card Element.
    this.card.addEventListener('change', (event) => {
      const displayError = this.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  handlePaymentError = (e) => {
    const [,,xhr] = e.detail;

    this.submitButtonTarget.disabled = false;
    const enableValue = this.submitButtonTarget.getAttribute('data-enable-with');
    this.submitButtonTarget.innerHTML = enableValue;
    if (xhr.response) {
      this.serverErrorsTarget.innerHTML = xhr.response;
    }
  }

  handleStripeToken = (paymentMethod) => {
    const {
      id,
      type,
      card: {
        brand,
        country,
        exp_month,
        exp_year,
        last4,
      },
    } = paymentMethod;
    this.tokenInputTarget.value = id;
    this.typeTarget.value = type;
    this.brandTarget.value = brand;
    this.countryTarget.value = country;
    this.expMonthTarget.value = exp_month;
    this.expYearTarget.value = exp_year;
    this.last4Target.value = last4;

    const event = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    this.formSubmitButtonTarget.dispatchEvent(event);
  }

  submitStripe() {
    this.submitButtonTarget.disabled = true;
    const disableValue = this.submitButtonTarget.getAttribute('data-disable-with');
    this.submitButtonTarget.setAttribute('data-enable-with', this.submitButtonTarget.innerHTML);
    this.submitButtonTarget.innerHTML = disableValue;

    this.stripe.createPaymentMethod({ type: 'card', card: this.card }).then((result) => {
      if (result.error) {
        // Inform the user if there was an error.
        const errorElement = this.cardErrorsTarget;
        errorElement.textContent = result.error.message;
        this.submitButtonTarget.disabled = false;
        const enableValue = this.submitButtonTarget.getAttribute('data-enable-with');
        this.submitButtonTarget.innerHTML = enableValue;
      } else {
        console.log('created payment method');
        console.log(result);
        // Send the token to your server.
        this.handleStripeToken(result.paymentMethod);
      }
    });
  }

  loadStripe() {
    const script = document.createElement('script');
    script.onload = this.setupStripe;
    script.src = 'https://js.stripe.com/v3/';

    document.head.appendChild(script);
  }
}
