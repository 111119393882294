import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import Alert from '../../Alert';

export default class CategoriesDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      categories: mapCategories(props),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      categories: mapCategories(nextProps),
    });
  }

  getCategories() {
    const data = this.props.competitionRound || {};
    const categories = data.categories || [];
    return categories;
  }

  handleCloseErrors = () => {
    this.setState((prevState) => ({
      errors: {},
      categories: prevState.categories.map((data) => {
        const category = {
          ...data,
          errors: {},
        };
        delete category.valid;

        return category;
      }),
    }));
  };

  handleAddCategory = () =>
    this.setState((prevState) => ({
      categories: [
        ...prevState.categories,
        {
          name: '',
          desription: '',
          weight: '',
          errors: {},
        },
      ],
    }));

  handleRemoveCategory = (i) => () =>
    this.setState((prevState) => ({
      categories: [
        ...prevState.categories.slice(0, i),
        ...prevState.categories.slice(i + 1),
      ],
    }));

  handleFieldChange = (i, field) => (e) => {
    const {
      target: { value },
    } = e;

    this.setState((prevState) => ({
      categories: [
        ...prevState.categories.slice(0, i),
        {
          ...prevState.categories[i],
          [field]: value,
        },
        ...prevState.categories.slice(i + 1),
      ],
    }));
  };

  handleSave = () => {
    // validate
    const { competitionRound } = this.props;

    const { categories } = this.state;
    const total = categories.reduce((sum, category) => {
      const weight = parseInt(category.weight || 0, 10);
      return sum + weight;
    }, 0);

    const errors = {};

    if (total !== 100) {
      errors.weight = 'The weights must add up to 100%';
    }

    const updatedCategories = categories.map((category) => {
      const categoryErrors = {};

      if (!category.name) {
        categoryErrors.name = 'The name is required';
      }

      if (!category.weight) {
        categoryErrors.weight = 'The weight is required';
      }

      const valid = Object.keys(categoryErrors).length === 0;

      return {
        ...category,
        errors: categoryErrors,
        valid,
      };
    });

    if (updatedCategories.find((c) => !c.valid)) {
      errors.missingFields =
        'Please make sure the cells highighted in red are set';
    }

    this.setState({
      errors,
      categories: updatedCategories,
    });

    if (Object.keys(errors).length === 0) {
      const setup = {
        categories: updatedCategories.map((cat, position) => {
          const { id, name, description, weight } = cat;

          return {
            id,
            name,
            description,
            weight,
            position,
          };
        }),
      };

      this.props.onSave(setup);
    }
  };

  renderErrors() {
    const { errors } = this.state;

    const errorKeys = Object.keys(errors);

    if (errorKeys.length === 0) {
      return null;
    }
    return (
      <div className="mb-6">
        <Alert
          title="Please fix the following issues"
          type="error"
          onClose={this.handleCloseErrors}
        >
          <ul className="p-0 ml-0 list-none">
            {errorKeys.map((key, i) => (
              <li key={i} className="">
                {errors[key]}
              </li>
            ))}
          </ul>
        </Alert>
      </div>
    );
  }

  render() {
    const { categories } = this.state;

    return (
      <div className="p-2 categories-wrapper md:p-6">
        <table className="mb-8 excel-grid table-layout">
          <thead>
            <tr className="font-light text-blue-dark">
              <th className="border-t border-l border-r bg-grey-lightest">
                Category Name
              </th>
              <th className="border-t border-l border-r bg-grey-lightest">
                Description
              </th>
              <th className="border-t border-l border-r bg-grey-lightest">
                <span className="mr-1">Weight</span>
                <span className="text-xs">(%)</span>
              </th>
              <th className="">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, i) => (
              <tr key={i}>
                <TextCell
                  value={category.name}
                  onChange={this.handleFieldChange(i, 'name')}
                  error={hasError(category.errors, 'name')}
                />
                <TextCell
                  value={category.description}
                  onChange={this.handleFieldChange(i, 'description')}
                  error={hasError(category.errors, 'description')}
                />
                <IntegerCell
                  value={category.weight}
                  onChange={this.handleFieldChange(i, 'weight')}
                  error={hasError(category.errors, 'weight')}
                />
                <td className="px-2 text-center border-grey">
                  <button
                    type="button"
                    className="align-middle"
                    title="Remove Category"
                    onClick={this.handleRemoveCategory(i)}
                  >
                    <XMarkIcon className="w-4 h-4 text-gray-400 hover:text-gray-700" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.renderErrors()}
        <div className="flex justify-between">
          <button
            type="button"
            onClick={this.handleAddCategory}
            title="Add Category"
            className="inline-flex items-center btn btn-hollow-blue"
          >
            <svg
              className="w-5 h-5 font-bold"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <div>Category</div>
          </button>
          <button
            type="submit"
            title="Save Categories"
            className="btn btn-primary"
            onClick={this.handleSave}
          >
            Save Categories
          </button>
        </div>
      </div>
    );
  }
}

const TextCell = ({ value, onChange, error }) => (
  <td className={getCellClassName(error)}>
    <input
      type="text"
      className="border-0 border-none"
      value={value}
      onChange={onChange}
    />
  </td>
);

TextCell.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

TextCell.defaultProps = {
  value: '',
  error: false,
};

const IntegerCell = ({ value, onChange, error, min }) => (
  <td className={getCellClassName(error)}>
    <input
      type="number"
      className="border-0 border-none"
      step="1"
      min={min}
      value={value}
      onChange={onChange}
      onKeyPress={onNumberCellKeyPress}
    />
  </td>
);

IntegerCell.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  min: PropTypes.number,
};

IntegerCell.defaultProps = {
  value: '',
  error: false,
  min: 0,
};

function onNumberCellKeyPress(e) {
  if (e.charCode < 48 || e.charCode > 57) {
    e.preventDefault();
  }
}

function getCellClassName(error = false) {
  return classnames({
    border: true,
    'border-solid': true,
    'border-grey': !error,
    'hover:border-grey-dark': !error,
    'border-red-400': error,
    'hover:border-red-darker': error,
  });
}

const { hasOwnProperty } = Object.prototype;

function hasError(errors, key) {
  if (!hasOwnProperty.call(errors, key)) {
    return false;
  }

  return true;
}

function mapCategories(props) {
  const {
    competitionRound: { setup },
  } = props;

  const categories = (setup || {}).categories || [
    {
      name: '',
      description: '',
      weight: '',
    },
  ];

  return categories.map((category) => ({
    ...category,
    errors: {},
  }));
}

CategoriesDataTable.propTypes = {
  competitionRound: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};
