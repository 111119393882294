import { Controller } from '@hotwired/stimulus';
import { humanize } from 'underscore.string';

const ENTITY_NAME = 'profile';

export default class extends Controller {
  static targets = [
    'errors',
  ];

  onSaveSuccess(e) {
    const [, , xhr] = e.detail;

    this.clearErrors();
    this.updateSection(xhr.response);
    this.closeModal();
    const section = humanize(this.data.get('key'));
    const text = `The ${section} has been updated.`;
    Snackbar.show({ text });
  }

  onDeleteSuccess(e) {
    const [, , xhr] = e.detail;

    this.clearErrors();
    this.updateSection(xhr.response);
    this.closeModal();
    const section = humanize(this.data.get('key'));
    const text = `The ${section} has been deleted.`;
    Snackbar.show({ text });
  }

  onSaveFail(event) {
    if (this.hasErrorsTarget) {
      const [, , xhr] = event.detail;
      this.errorsTarget.innerHTML = xhr.response;
    }
  }

  updateSection(html) {
    document.dispatchEvent(
      new CustomEvent(
        this.updateEventName,
        {
          detail: {
            html,
          },
        },
      ),
    );
  }

  closeModal() {
    document.dispatchEvent(
      new CustomEvent(
        'profile-form-modal-close',
        {
        },
      ),
    );
  }

  clearErrors() {
    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = '';
    }
  }

  get updateEventName() {
    return `${ENTITY_NAME}-${this.data.get('key')}:updated`;
  }
}
