import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'attachments',
    'file',
    'error',
    'uploadForm',
  ];

  onFileChange(e) {
    const { currentTarget } = e;
    if (currentTarget.files.length > 0) {
      console.log('about to submit form');
      this.submitForm();
    }
  }

  onUploadSuccess(e) {
    const [
      response, status, xhr,
    ] = e.detail;

    this.fileTarget.value = '';
    this.fileTarget.disabled = false;
    this.attachmentsTarget.innerHTML = xhr.response;
  }

  onUploadFail(e) {
    const [
      response, status, xhr,
    ] = e.detail;

    this.errorTarget.innerHTML = xhr.response;
  }

  onDeleteAttachmentSuccess(e) {
    this.errorTarget.innerHTML = '';
    const [
      response, status, xhr,
    ] = e.detail;

    this.attachmentsTarget.innerHTML = xhr.response;
  }

  onDeleteAttachmentFail(e) {
    const [
      response, status, xhr,
    ] = e.detail;

    this.errorTarget.innerHTML = xhr.response;
  }

  submitForm() {
    Rails.fire(this.uploadFormTarget, 'submit');
  }
}
