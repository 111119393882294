import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['rightIcon', 'downIcon', 'header', 'menu'];

  connect() {
    this.open = false;
  }

  onToggleMenu(e) {
    e.stopPropagation();
    this.menuTarget.classList.toggle('block');
    this.menuTarget.classList.toggle('hidden');
    this.open = !this.open;
    this.toggleCaretIcon();
  }

  toggleCaretIcon() {
    if (this.open) {
      this.downIconTarget.classList.remove('hidden');
      this.rightIconTarget.classList.add('hidden');
    } else {
      this.rightIconTarget.classList.remove('hidden');
      this.downIconTarget.classList.add('hidden');
    }
  }
}
