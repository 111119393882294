import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'template', 'links',
  ];

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields';
  }

  disconnect() {

  }

  onAddAssociation(e) {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.linksTarget.insertAdjacentHTML('beforebegin', content);
  }

  onRemoveAssociation(e) {
    e.preventDefault();
    const wrapper = e.target.closest(`.${this.wrapperClass}`);

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
    } else {
      wrapper.querySelector('input[name*="_destroy"]').value = 1;
      wrapper.style.display = 'none';
    }
  }
}
