import { Controller } from '@hotwired/stimulus';

import NotificationsHelper from '../utils/notifications_helper';

export default class NotificationsController extends Controller {
  static targets = ['list'];

  connect() {
    const helper = NotificationsHelper.shared();
    this.listenerID = helper.addListener(this.handleNotification);

    this.removeUnreadStyle()
  }

  removeUnreadStyle() {
    setTimeout(() => {
      $('.notifications__container.unread').removeClass('unread');
    }, 2500);
  }

  disconnect() {
    const helper = NotificationsHelper.shared();
    helper.removeListener(this.listenerID);
  }

  handleAddNotification = ({ notification, id }) => {
    const {
      listTarget,
      listTarget: { firstChild },
    } = this;

    if (listTarget.getAttribute('data-page') !== '1') {
      return;
    }

    const div = document.createElement('div');
    div.innerHTML = notification;

    listTarget.insertBefore(div.firstChild, firstChild);
    this.removeUnreadStyle();
  }

  handleNotification = ({ notification, id }) => {
    if (!id) {
      return;
    }

    const {
      listTarget,
    } = this;

    const target = $(listTarget)
      .children(`[notification-id='${id}']`);

    if (target.length === 0) {
      this.handleAddNotification({ notification });
      return;
    }

    target.replaceWith(notification);
  }
}
