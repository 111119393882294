import { Controller } from '@hotwired/stimulus';

const placesOptions = {
  types: ['(cities)'],
};

export default class extends Controller {
  static targets = [
    'errors',
    'locationTextField',
    'locationHiddenField',
  ];

  connect() {
    const options = placesOptions;
    const types = this.locationTextFieldTarget.getAttribute('data-types');

    if (types) {
      options.types = types.split(',');
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      this.locationTextFieldTarget,
      options,
    );
    google.maps.event.addListener(this.autocomplete, 'place_changed', this.onPlaceChanged);
    if (this.isFormElement()) {
      this.validator = $(this.element).validate();
    }
  }

  disconnect() {
    google.maps.event.clearInstanceListeners(this.autocomplete);
    this.autocomplete = null;
    if (this.isFormElement() && this.validator()) {
      this.validator.destroy();
    }
  }

  onKeydown = (e) => {
    if (
      e.metaKey ||
      e.altKey ||
      e.ctrlKey ||
      (e.key && e.key.startsWith('Arrow')) ||
      e.key === 'Shift'
    ) {
      return;
    }
    const autocompleteVisible = $('.pac-container:visible').length > 0;
    if (autocompleteVisible && e.keyCode === 13) {
      e.preventDefault();
    }
    if (e.keyCode !== 13) {
      this.locationHiddenFieldTarget.value = '';
      this.locationHiddenFieldTarget.dispatchEvent(new Event('change'));
    }
  };

  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    if (place && place.formatted_address) {
      this.locationHiddenFieldTarget.value = place.formatted_address;
      this.locationHiddenFieldTarget.dispatchEvent(new Event('change'));
    } else {
      this.locationTextFieldTarget.value = '';
      this.locationHiddenFieldTarget.value = '';
      this.locationHiddenFieldTarget.dispatchEvent(new Event('change'));
    }
    this.triggerValidation();
  };

  clearErrors() {
    this.errorsTarget.innerHTML = '';
  }

  isFormElement() {
    return this.element.tagName === 'FORM';
  }

  triggerValidation() {
    $(this.locationHiddenFieldTarget).valid();
  }
}
