import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class TextQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      helpText: PropTypes.string,
    }),
    answer: PropTypes.shape({
      data: PropTypes.string,
    }),
    onAnswerChange: PropTypes.func.isRequired,
  }

  handleChange = e => this.props.onAnswerChange(e.target.value);

  render() {
    const {
      index,
      question,
      validationStatus,
    } = this.props;

    const answer = this.props.answer || {
      data: '',
    };

    const finalClassName = {
      'form-group': true,
      'mb-4': true,
    };

    if (this.props.className) {
      finalClassName[this.props.className] = true;
    }

    if (validationStatus && !validationStatus.success) {
      finalClassName['error-wrapper'] = true;
    }

    const fieldName = `competition_application[answers_attributes][${index}][(field)]`;
    const hiddenField = fieldName.replace('(field)', 'question_id');
    const answerField = fieldName.replace('(field)', 'data');

    return (
      <div className={classnames(finalClassName)}>
        <label
          className="form-label mb-2"
          htmlFor={fieldName}
        >
          {question.title}
        </label>
        <input
          type="text"
          name={answerField}
          placeholder={question.help_text}
          onChange={this.handleChange}
          value={answer.data}
        />
        {validationStatus && (
          <p className="text-red-400 font-bold">{validationStatus.errorMessage}</p>
        )}
      </div>
    );
  }
}
