import { Controller } from '@hotwired/stimulus';
import accounting from 'accounting';

export default class extends Controller {
  static values = {
    applicationFeePercentage: Number,
    collectiveFeePercentage: Number,
  };

  static targets = ['applicationFee', 'sparkxyzFee', 'total'];

  connect() {
    const { value } = this.applicationFeeTarget;
    this.calculateFees(value);
  }

  calculateFees(value) {
    const fee = parseFloat(value);

    if (!Number.isNaN(fee)) {
      const customerPrice = (1 + this.applicationFeePercentageValue) * fee;
      const payout =
        customerPrice - customerPrice * this.collectiveFeePercentageValue;
      const collectiveFee = fee - payout;
      this.sparkxyzFeeTarget.innerHTML = accounting.formatMoney(collectiveFee);
      this.totalTarget.innerHTML = accounting.formatMoney(payout);
    } else {
      this.sparkxyzFeeTarget.innerHTML = '-';
      this.totalTarget.innerHTML = '-';
    }
  }

  onFeeChange(e) {
    const {
      target: { value },
    } = e;
    this.calculateFees(value);
  }
}
