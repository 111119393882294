import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

const ONE_MB_IN_BYTES = 1024 * 1024;
const MAX_FILE_SIZE = 20 * ONE_MB_IN_BYTES;

export default class extends Controller {
  static targets = [
    'file',
    'progressBar',
    'key',
    'previewWrapper',
    'previewImage',
  ];

  connect() {
    this.uploadReady = false;
    this.fetchUploaderUrl();
    this.xmlParser = new window.DOMParser();
  }

  disconnect() {
    this.xmlParser = null;
  }

  fetchUploaderUrl(callback) {
    axios.get(this.uploaderUrl)
      .then((res) => {
        this.mapFormData(res.data, callback);
      })
      .catch((error) => {
        this.showError('There seems to be an issue with image uploads; please reach out to support');
        console.error(error);
      });
  }

  mapFormData(data, callback) {
    const {
      directFogUrl,
    } = data;
    this.s3Endpoint = directFogUrl;

    const {
      key,
      acl,
      success_action_status,
      policy,
      algorithm,
      credential,
      date,
      signature,
    } = data;

    this.s3Endpoint = directFogUrl;
    this.formData = {
      key,
      acl,
      success_action_status,
      policy,
    };

    this.formData['x-amz-algorithm'] = algorithm;
    this.formData['x-amz-credential'] = credential;
    this.formData['x-amz-date'] = date;
    this.formData['x-amz-signature'] = signature;
    if (callback) {
      callback();
    }
  }

  setPreviewImage(location) {
    this.previewWrapperTarget.classList.remove('hidden');
    this.previewImageTarget.src = location;
  }

  onFileChange() {
    const {
      size,
    } = this.fileTarget.files[0];

    if (size > MAX_FILE_SIZE) {
      this.showError('The file is too large');
      this.resetFile();
    } else if (!this.formData) {
      this.fetchUploaderUrl(() => this.uploadFileToS3());
    } else {
      this.uploadFileToS3();
    }
  }

  showError(message) {
    Snackbar.show({ pos: 'bottom-right', actionTextColor: '#E3342F', text: message });
  }

  updateProgressBar(percentCompleted) {
    const width = `${percentCompleted}%`;
    this.progressBarTarget.style.width = width;
    this.progressBarTarget.innerHTML = width;
  }

  resetProgressBar() {
    this.updateProgressBar(0);
  }

  showProgressBar() {
    this.progressBarTarget.classList.remove('hidden');
  }

  hideProgressBar() {
    this.progressBarTarget.classList.add('hidden');
  }

  resetFile() {
    this.fileTarget.type = '';
    this.fileTarget.type = 'file';
  }

  uploadFileToS3() {
    const formData = new FormData();

    Object.keys(this.formData)
      .forEach(key => formData.append(key, this.formData[key]));

    formData.append('file', this.fileTarget.files[0]);


    this.showProgressBar();
    axios.post(
      this.s3Endpoint,
      formData,
      {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.updateProgressBar(percentCompleted);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'text',
      },
    ).then((res) => {
      const xmlDoc = this.xmlParser.parseFromString(res.data, 'text/xml');
      const location = decodeURIComponent(xmlDoc.querySelector('Location').childNodes[0].textContent);
      const key = decodeURIComponent(xmlDoc.querySelector('Key').textContent);
      this.keyTarget.value = key;
      this.hideProgressBar();
      this.resetFile();
      this.setPreviewImage(location);
    }, (err) => {
      const xmlDoc = this.xmlParser.parseFromString(err.response.data, 'text/xml');
      const message = decodeURIComponent(xmlDoc.querySelector('Message').textContent);

      this.resetFile();
      this.hideProgressBar();
      this.resetProgressBar();
      this.showError(message);
    });
  }

  get uploaderUrl() {
    return this.data.get('uploader-url');
  }
}
