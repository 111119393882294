import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class DropdownQuestion extends Component {
  handleChange = (e) => this.props.onAnswerChange(e.target.value);

  render() {
    const { index, question, validationStatus } = this.props;

    const answer = this.props.answer || {};
    const data = answer.data || '';

    const finalClassName = {
      'form-group': true,
      'mb-4': true,
    };

    if (validationStatus && !validationStatus.success) {
      finalClassName['error-wrapper'] = true;
    }

    const fieldName = `competition_application[answers_attributes][${index}][(field)]`;
    // const hiddenField = fieldName.replace('(field)', 'question_id');
    // const answerField = fieldName.replace('(field)', 'data');

    return (
      <div className={classnames(finalClassName)}>
        <label className="form-label mb-2" htmlFor={fieldName}>
          {question.title}
        </label>
        <select className="max-w-xs" onChange={this.handleChange} value={data}>
          <option value="">Please select an answer</option>
          {question.data.choices.map((choice, i) => (
            <option key={i} value={choice.name}>
              {choice.name}
            </option>
          ))}
        </select>
        {validationStatus && (
          <p className="text-red-400 font-bold">
            {validationStatus.errorMessage}
          </p>
        )}
      </div>
    );
  }
}

DropdownQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    data: PropTypes.shape({
      choices: PropTypes.array,
    }),
  }).isRequired,
  answer: PropTypes.shape({
    data: PropTypes.string,
  }),
  onAnswerChange: PropTypes.func.isRequired,
  validationStatus: PropTypes.object,
};

DropdownQuestion.defaultProps = {
  answer: {
    data: '',
  },
};
