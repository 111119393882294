import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  static targets = [
    'round',
    'selectAll',
  ];

  onRoundChecked = (e) => {
    const {
      target,
    } = e;

    const {
      checked,
      value,
    } = target;

    const round = parseInt(value, 10);
    let rounds;
    const currentState = this.getState();
    const {
      rounds: currentRounds,
    } = currentState;
    if (checked && !currentRounds.includes(round)) {
      rounds = [
        ...currentRounds,
        round,
      ].sort();
    } else if (!checked) {
      rounds = currentRounds.filter(r => r !== round).sort();
    }

    const payload = {
      competition_role: {
        rounds,
      },
    };

    this.save(payload);
  }

  onSelectAllToggled = (e) => {
    const {
      target: {
        value,
        checked,
      },
    } = e;

    [].forEach.call(this.roundTargets, (roundElement) => {
      /* eslint no-param-reassign: 0 */
      roundElement.checked = checked;
    });

    const rounds = checked ? value.split(',').map(r => parseInt(r, 10)).sort() : [];
    const payload = {
      competition_role: {
        rounds,
      },
    };
    this.save(payload);
  }

  save(payload) {
    axios.put(
      this.data.get('url'),
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
        },
      },
    )
      .then(this.onSaveSuccess)
      .catch(this.onSaveFail);
  }

  onSaveSuccess = (res) => {
    const {
      data: {
        competition_role: {
          round_assignments: {
            rounds,
          },
        },
      },
    } = res;

    if (rounds.length === this.selectAllTarget.value.split(',').length) {
      this.selectAllTarget.checked = true;
    } else {
      this.selectAllTarget.checked = false;
    }

    this.saveState({ rounds });
    Snackbar.show({ text: 'The judge assignment has been saved' });
  }

  onSaveFail = () => {
    Snackbar.show({
      text: 'There was a problem saving judging setup',
      actionTextColor: ERROR_COLOR,
    });
  }

  getState() {
    const currentState = JSON.parse(this.data.get('state'));
    if (!currentState.rounds) {
      return {
        rounds: [],
      };
    }
    return currentState;
  }

  saveState(data = { rounds: [] }) {
    this.data.set('state', JSON.stringify(data));
  }
}
