export const THUMBS_UP_DOWN = {
  value: 0,
  key: 'thumbs_up_down',
  label: 'Thumbs Up/Down',
};

export const CATEGORIES = {
  value: 1,
  key: 'categories',
  label: 'Categories',
};

export const NUMERIC_SCORING = {
  value: 2,
  key: 'numeric_scoring',
  label: '0-7 Scoring',
};

export const VOTING_CAP = {
  value: 3,
  key: 'voting_cap',
  label: 'Voting Cap',
};

export default [THUMBS_UP_DOWN, CATEGORIES, NUMERIC_SCORING, VOTING_CAP];
