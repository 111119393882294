import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { ERROR_COLOR } from '../constants/colors';

const dropzoneClasses = [
  'border',
  'border-dotted',
  'bg-blue-lighter',
  'border-blue-darker',
];

export default class extends Controller {
  static targets = ['form', 'errors', 'portfolioCompanies', 'category'];

  connect() {
    document.addEventListener('portfolio-companies:updated', this.onUpdated);
  }

  disconnect() {
    document.removeEventListener('portfolio-companies:updated', this.onUpdated);
  }

  onFormLoadSuccess = (e) => {
    const [response, status, xhr] = e.detail;

    document.dispatchEvent(
      new CustomEvent('portfolio-company-form-modal-update', {
        detail: xhr.response,
      }),
    );
  };

  onFormLoadFail = () => {
    Snackbar.show({
      text: 'There was a problem loading the portfolio company form',
      actionTextColor: ERROR_COLOR,
    });
  };

  onUpdated = (e) => {
    const [response, status, xhr] = e.detail;
    this.portfolioCompaniesTarget.innerHTML = xhr.response;
  };

  onResendInviteSuccess() {
    Snackbar.show({
      text: 'The invitation has been resent!',
    });
  }

  onResendInviteFail() {
    Snackbar.show({
      text: 'There was a problem resending the invitation',
      actionTextColor: ERROR_COLOR,
    });
  }

  onDragStart(e) {
    const { target } = e;

    const portfolioCompanyId = parseInt(target.getAttribute('data-id'), 10);
    e.dataTransfer.setData('portfolio-company-id', portfolioCompanyId);
  }

  onDragOver(e) {
    const { currentTarget } = e;
    cancelDefault(e);
    currentTarget.classList.add(...dropzoneClasses);
  }

  onDragEnter(e) {
    cancelDefault(e);
  }

  onDragLeave(e) {
    const { currentTarget } = e;
    currentTarget.classList.remove(...dropzoneClasses);
    e.preventDefault();
  }

  onDrop(e) {
    const { currentTarget } = e;
    const portfolioCompanyId = parseInt(
      e.dataTransfer.getData('portfolio-company-id'),
      10,
    );
    const targetCategory = currentTarget.getAttribute('data-id');

    Array.from(this.categoryTargets).forEach((category) => {
      category.classList.remove(...dropzoneClasses);
    });

    e.preventDefault();
    const portfolioCategoryId = parseInt(targetCategory, 10);
    this.assignCategory(portfolioCompanyId, portfolioCategoryId);
    Snackbar.show({
      text: 'The portfolio company has been assiged to this category',
    });
  }

  onCategoryDeleteSuccess(e) {
    const [response, status, xhr] = e.detail;
    const portfolioCompanyId = e.currentTarget.getAttribute(
      'data-portfolio-company-id',
    );
    const targetCard = this.portfolioCompaniesTarget.querySelector(
      `[data-id="${portfolioCompanyId}"`,
    );
    targetCard.outerHTML = xhr.response;
  }

  onCategoryDeleteFail(e) {
    Snackbar.show({
      text: 'There was a problem removing this category',
      actionTextColor: ERROR_COLOR,
    });
  }

  onDeleteSuccess(e) {
    this.onUpdated(e);
  }

  onDeleteFail(e) {
    Snackbar.show({
      text: 'There was a problem removing this portfolio company',
      actionTextColor: ERROR_COLOR,
    });
  }

  async assignCategory(portfolioCompanyId, portfolioCategoryId) {
    const url = `/portfolio-companies/${portfolioCompanyId}/categories`;
    try {
      const res = await axios.post(
        url,
        { portfolio_category_id: portfolioCategoryId },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
              .content,
          },
        },
      );
      const target = this.portfolioCompaniesTarget.querySelector(
        `[data-id="${portfolioCompanyId}"]`,
      );
      target.outerHTML = res.data;
    } catch (e) {
      Snackbar.show({
        text: 'There was a problem assigning the portfolio company to this category',
        actionTextColor: ERROR_COLOR,
      });
    }
  }
}

function cancelDefault(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}
