import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { humanize } from 'underscore.string';
import CompetitionRoundList from './competitions/rounds/CompetitionRoundList';
import CompetitionRoundForm from './competitions/rounds/CompetitionRoundForm';
import Modal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import { CATEGORIES } from '../constants/judging-types';

import CategoriesDataTable from './competitions/judging/CategoriesDataTable';

import { ERROR_COLOR } from '../constants/colors';

function getJudgingSetupTitle(competitionRound) {
  const { judging_type } = competitionRound;

  return 'Categories';
}

function getFormTitle(competitionRound) {
  if (!competitionRound) {
    return 'Add Round';
  }
  return 'Edit Round';
}

function mapCompetitionRounds(props) {
  return props.competitionRounds.map((competitionRound) => ({
    ...competitionRound,
  }));
}

function mapErrors(resErrors) {
  return Object.keys(resErrors).reduce((runningHash, key) => {
    const field = humanize(key);
    const errorList = resErrors[key];
    const mappedErrors = errorList.map((error) => `${field} ${error}`);
    return {
      ...runningHash,
      [key]: mappedErrors,
    };
  }, {});
}

function updateRounds(updatedRound, currentCompetitionRounds) {
  const roundIndex = currentCompetitionRounds.findIndex(
    (r) => r.id === updatedRound.id,
  );
  if (roundIndex === -1) {
    return null;
  }
  const targetRound = {
    ...currentCompetitionRounds[roundIndex],
    ...updatedRound,
  };
  const competitionRounds = [
    ...currentCompetitionRounds.slice(0, roundIndex),
    targetRound,
    ...currentCompetitionRounds.slice(roundIndex + 1),
  ];

  return {
    competitionRounds,
  };
}

export default class CompetitionRoundsSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showConfirmDelete: false,
      showSetupJudgingScreen: false,
      roundIdPendingDelete: undefined,
      selectedRound: undefined,
      competitionRounds: mapCompetitionRounds(props),
      errors: {},
    };
  }

  getSelectedRound() {
    const { competitionRounds, selectedRound } = this.state;
    if (!selectedRound) {
      return undefined;
    }

    return competitionRounds.find((r) => r.id === selectedRound);
  }

  getDeleteRoundTitle() {
    const { competitionRounds, showConfirmDelete, roundIdPendingDelete } =
      this.state;

    if (!showConfirmDelete) {
      return '';
    }
    const roundPendingDelete = competitionRounds.find(
      (r) => r.id === roundIdPendingDelete,
    );
    if (!roundIdPendingDelete) {
      return '';
    }
    return roundPendingDelete.name;
  }

  handleSaveJudgingSetup = (data) => {
    const { selectedRound } = this.state;

    const url = `${this.props.endpoint}/${selectedRound}/categories`;
    axios
      .put(
        url,
        JSON.stringify({
          round: {
            setup: data,
          },
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
              .content,
          },
        },
      )
      .then((res) => {
        Snackbar.show({ text: 'The competition judging setup has been saved' });
        this.handleHideJudgingSetup();
        this.setState((prevState) => {
          const { competitionRounds: currentCompetitionRounds } = prevState;

          return updateRounds(
            res.data.competition_round,
            currentCompetitionRounds,
          );
        });
      })
      .catch((err) => {
        const {
          response: {
            data: { errors },
          },
        } = err;
        if (errors) {
          this.setState({
            errors,
          });
          Snackbar.show({
            text: 'There was a problem saving judging setup',
            actionTextColor: ERROR_COLOR,
          });
        }
      });
  };

  handleCreateRound = () => {
    this.setState({
      showForm: true,
      selectedRound: undefined,
    });
  };

  handleEditRound = (selectedRound) =>
    this.setState({
      showForm: true,
      selectedRound,
    });

  handleDeleteRound = (id) => {
    this.setState({
      showConfirmDelete: true,
      roundIdPendingDelete: id,
    });
  };

  handleConfirmDelete = () => {
    const { roundIdPendingDelete } = this.state;

    if (!roundIdPendingDelete) {
      return;
    }
    const roundId = roundIdPendingDelete;
    this.setState({
      showConfirmDelete: false,
      roundIdPendingDelete: undefined,
    });

    this.deleteRound(roundId);
  };

  handleCancelDelete = () =>
    this.setState({
      showConfirmDelete: false,
      roundIdPendingDelete: undefined,
    });

  handleShowJudgingSetup = (selectedRound) =>
    this.setState({
      selectedRound,
      showSetupJudgingScreen: true,
    });

  handleHideJudgingSetup = () =>
    this.setState({
      selectedRound: null,
      showSetupJudgingScreen: false,
    });

  handleCloseModal = () =>
    this.setState({
      showForm: false,
      selectedRound: undefined,
    });

  handleSave = (round) => {
    this.setState(
      {
        errors: {},
      },
      () => {
        if (round.id) {
          this.updateRound(round);
        } else {
          this.createRound(round);
        }
      },
    );
  };

  deleteRound(id) {
    axios
      .delete(`${this.props.endpoint}/${id}.json`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
            .content,
        },
      })
      .then((res) => {
        this.setState({
          competitionRounds: res.data.competition_rounds,
        });
        Snackbar.show({ text: 'The competition round been has been deleted' });
      })
      .catch((err) => {
        const {
          response: {
            data: { errors: resError },
          },
        } = err;
        if (resError) {
          Snackbar.show({
            text: 'There was a problem saving the timeline',
            actionTextColor: ERROR_COLOR,
          });
        }
      });
  }

  createRound(data) {
    const { competition_id, created_at, updated_at, ...round } = data;

    axios
      .post(
        `${this.props.endpoint}.json`,
        JSON.stringify({
          round,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
              .content,
          },
        },
      )
      .then((res) => {
        Snackbar.show({ text: 'The competition round been has been created' });
        this.setState((prevState) => {
          const { competitionRounds: currentCompetitionRounds } = prevState;

          const competitionRounds = [
            ...currentCompetitionRounds,
            res.data.competition_round,
          ];
          return {
            showForm: false,
            selectedRound: undefined,
            competitionRounds,
            errors: {},
          };
        });
      })
      .catch((err) => {
        const {
          response: {
            data: { errors },
          },
        } = err;
        if (errors) {
          this.setState({
            errors: mapErrors(errors),
          });
          Snackbar.show({
            text: 'There was a problem saving the timeline',
            actionTextColor: ERROR_COLOR,
          });
        }
      });
  }

  updateRound(data) {
    const { competition_id, created_at, updated_at, order, ...round } = data;

    const endpoint = `${this.props.endpoint}/${round.id}.json`;

    axios
      .put(
        endpoint,
        JSON.stringify({
          round,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
              .content,
          },
        },
      )
      .then((res) => {
        Snackbar.show({ text: 'The competition round has been saved' });
        this.resetForm();
        this.setState((prevState) => {
          const { competitionRounds: currentCompetitionRounds } = prevState;

          return updateRounds(
            res.data.competition_round,
            currentCompetitionRounds,
          );
        });
      })
      .catch((err) => {
        const {
          response: {
            data: { errors },
          },
        } = err;
        if (errors) {
          this.setState({
            errors: mapErrors(errors),
          });
          Snackbar.show({
            text: 'There was a problem saving the timeline',
            actionTextColor: ERROR_COLOR,
          });
        }
      });
  }

  resetForm() {
    this.setState({
      showForm: false,
      selectedRound: undefined,
    });
  }

  renderSetupJudgingScreen() {
    const { selectedRound, showSetupJudgingScreen } = this.state;

    if (!selectedRound || !showSetupJudgingScreen) {
      return null;
    }
    const competitionRound = this.getSelectedRound();
    const { judging_type } = competitionRound;

    return (
      <Modal
        show={this.state.showSetupJudgingScreen}
        onClose={this.handleHideJudgingSetup}
        title={getJudgingSetupTitle(this.getSelectedRound())}
      >
        {judging_type === CATEGORIES.key && (
          <CategoriesDataTable
            competitionRound={competitionRound}
            onSave={this.handleSaveJudgingSetup}
          />
        )}
      </Modal>
    );
  }

  render() {
    return (
      <div className="rounds-wrapper">
        <h3 className="mb-6">Application Rounds</h3>
        <div className="mb-6">
          <CompetitionRoundList
            competitionRounds={this.state.competitionRounds}
            onEditRound={this.handleEditRound}
            onDeleteRound={this.handleDeleteRound}
            onShowJudgingSetup={this.handleShowJudgingSetup}
          />
          {this.state.showConfirmDelete && (
            <ConfirmationModal
              show={this.state.showConfirmDelete}
              onCancel={this.handleCancelDelete}
              onConfirm={this.handleConfirmDelete}
              confirmButtonText="Yes, Delete"
              cancelBubble="No"
            >
              <p>
                Are you sure you want to delete the&nbsp;
                <strong>{this.getDeleteRoundTitle()}</strong>
                &nbsp;round?
              </p>
            </ConfirmationModal>
          )}
          {this.state.showForm && (
            <Modal
              show={this.state.showForm}
              onClose={this.handleCloseModal}
              title={getFormTitle(this.state.selectedRound)}
            >
              <CompetitionRoundForm
                competitionRound={this.getSelectedRound()}
                serverErrors={this.state.errors}
                onCancel={this.handleCloseModal}
                onSave={this.handleSave}
              />
            </Modal>
          )}
          {this.renderSetupJudgingScreen()}
        </div>

        <div className="flex">
          <button
            type="button"
            className="btn btn-blue inline-block"
            onClick={this.handleCreateRound}
          >
            <div className="flex items-center space-x-1">
              <PlusIcon className="w-5 h-5" />
              <span>Round</span>
            </div>
          </button>
        </div>
      </div>
    );
  }
}

CompetitionRoundsSetup.propTypes = {
  endpoint: PropTypes.string.isRequired,
};
