import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'form',
    'profilePictureKey',
    'imageCropper',
    'profilePictureUrl',
    'imageData',
    'facebook',
    'linkedin',
    'twitter',
    'instagram',
    'file',
  ];

  connect() {
    this.xmlParser = new window.DOMParser();

    const imageCropperOptions = {
      allowDragNDrop: false,
      imageState: '',
      exportZoom: 2,
      quality: 0.95,
      smallImage: 'allow',
    };
    const profilePictureUrl = this.profilePictureUrlTarget.value;
    this.setupValidation();

    this.$imageCropper = $(this.imageCropperTarget);
    this.$imageCropper.cropit(imageCropperOptions);

    if (profilePictureUrl) {
      this.$imageCropper.cropit('imageSrc', profilePictureUrl);
    }

    this.modal.on('closed.zf.reveal', this.cleanup);
  }

  cleanup = () => {
    if (this.validator) {
      this.validator.destroy();
    }

    this.$imageCropper.cropit('destroy');
    this.$imageCropper = null;

    this.modal.off('closed.zf.reveal', this.cleanup);
  }

  disconnect() {
  }

  setupValidation() {
    this.validator = $(this.formTarget).validate({
      rules: {
        'user[first_name]': {
          required: true,
        },
        'user[last_name]': {
          required: true,
        },
      },
      highlight(element) {
        $(element).closest('div').addClass('error-wrapper');
      },
      unhighlight(element) {
        $(element).closest('div').removeClass('error-wrapper');
      },
    });
  }

  dataURItoBlob(dataURI) {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  async setImageData(target) {
    const imageData = this.$imageCropper.cropit('export');
    if (imageData) {
      const blob = this.dataURItoBlob(imageData);
      const form = $("form[data-id='profile_picture_upload_form']");
      const formData = new FormData(form[0]);

      formData.set('file', blob, 'photo.jpg');

      try {
        const key = await this.submitFormDataWithTarget(formData, form[0]);

        this.uploadedImage = true;
        this.profilePictureKeyTarget.value = key;
        Rails.fire(target, 'submit');
      } catch (error) {
        Snackbar.show({ actionTextColor: '#E3342F', text: error });
      }
    } else {
      this.uploadedImage = true;
      Rails.fire(target, 'submit');
    }
  }

  async submitFormDataWithTarget(formData, target, callback) {
    try {
      const res = await axios.post(target.action, formData, {
        onUploadProgress: (progressEvent) => {

        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'text',
      });

      const xmlDoc = this.xmlParser.parseFromString(res.data, 'text/xml');
      const key = decodeURIComponent(xmlDoc.querySelector('Key').textContent);
      return key;
    } catch (error) {
      const xmlDocE = this.xmlParser.parseFromString(error.response.data, 'text/xml');
      const formErr = decodeURIComponent(xmlDocE.querySelector('Message').textContent);
      throw formErr;
    }
  }

  onBeforeSubmit(e) {
    if (!this.uploadedImage) {
      e.preventDefault();
      this.setImageData(e.target);
    }
  }

  onAddImage = (e) => {
    e.preventDefault();
    this.fileTarget.click();
  }

  get modal() {
    return $(this.element.closest('.reveal'));
  }
}
