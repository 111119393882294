import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'errors',
  ]

  onInviteSuccess(e) {
    const {
      detail,
    } = e;

    document.dispatchEvent(
      new CustomEvent(
        'portfolio-companies:updated',
        {
          detail,
        },
      ),
    );

    Snackbar.show({
      text: 'The invitation has been sent!',
    });

    setTimeout(() => this.closeModal(), 250);
  }

  onInviteFail(e) {
    const [
      response,
      status,
      xhr,
    ] = e.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  onCancel(e) {
    e.preventDefault();
    this.closeModal();
  }

  closeModal() {
    document.dispatchEvent(
      new CustomEvent(
        'portfolio-company-form-cancel',
        {
          detail: {},
        },
      ),
    );
  }
}
