import $ from 'jquery';
import 'select2'; // globally assign select2 fn to $ element

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { url: String, dropdownParent: String };

  connect() {
    this.select2mount();
    document.addEventListener(
      'turbolinks:before-cache',
      () => {
        this.select2unmount();
      },
      { once: true },
    );
  }

  disconnect() {
    this.select2unmount();
  }

  select2mount() {
    const placeholder =
      $(this.element).attr('placeholder') ||
      'Please start typing to search for a user';
    const options = {
      minimumInputLength: 3,
      ajax: {
        url: this.urlValue,
        data: function(params) {
          return {
            search: params.term,
          };
        },
        dataType: 'json',
        processResults: (data) => ({
          results: (data || {}).items || [],
        }),
      },
      quietMillis: 400,
      placeholder,
      templateResult: formatProfileOption,
      width: '100%',
    };
    if (this.dropdownParentValue) {
      options.dropdownParent = $(this.dropdownParentValue);
    }

    $(this.element).select2(options);
  }

  select2unmount() {
    $(this.element).select2('destroy');
  }
}

function formatProfileOption(option) {
  return $(option.template);
}
