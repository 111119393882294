import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['acceptedButton', 'ignoreButton'];

  onAcceptInviteSuccess(e) {
    this.acceptButtonTarget.disabled = true;
    this.ignoreButtonTarget.classList.add('hidden');
    Snackbar.show({ text: 'The invitation has been accepted' });
  }

  onAcceptInviteFail() {
    Snackbar.show({
      text: 'There was a problem accepting the invitation',
      actionTextColor: ERROR_COLOR,
    });
  }

  onIgnoreInviteSuccess(e) {
    this.ignoreButtonTarget.disabled = true;
    this.acceptButtonTarget.classList.add('hidden');

    Snackbar.show({ text: 'The invitation has been ignored' });
  }

  onIgnoreInviteFail() {
    Snackbar.show({
      text: 'There was a problem ignoring the invitation',
      actionTextColor: ERROR_COLOR,
    });
  }
}
