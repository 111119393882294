import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'file',
  ];

  onClick = (e) => {
    e.preventDefault();
    this.fileTarget.click();
  };
}
