import React from 'react';
import PropTypes from 'prop-types';

const ToggleSwitch = ({
  checked,
  value,
  onChange,
}) => (
  <label
    className="toggle-switch align-middle flex-no-grow flex-no-shrink grow-0 shrink-0"
  >
    <input
      type="checkbox"
      onChange={e => onChange(value, e.target.checked)}
      value={value}
      checked={checked}
    />
    <span
      className="toggle-slider round"
    />
  </label>
);

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  checked: false,
  value: '',
};

export default ToggleSwitch;
