import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log('connected');
    const contactButton = $('#open-profile-contact');
    const form = $('#profile-contact-form');

    this.validator = form.validate();
    contactButton.on('click', this.onOpenContactForm);
    form.on('ajax:success', this.onSendSuccess);
  }

  disconnect() {
    console.log('disconnected');
    const contactButton = $('#open-profile-contact');
    contactButton.off('click', this.onOpenContactForm);

    const form = $('#profile-contact-form');
    form.off('ajax:success', this.onSendSuccess);

    if (this.validator) {
      this.validator.destroy();
    }
  }

  onOpenContactForm = (e) => {
    e.preventDefault();
    const contactButton = $('#open-profile-contact');
    const modal = $(`#${contactButton.data().revealId}`);
    modal.foundation('open');
  }

  onSendSuccess() {
    const contactButton = $('#open-profile-contact');
    const modal = $(`#${contactButton.data().revealId}`);
    Snackbar.show({ text: 'Your message has been sent!' });
    setTimeout(() => {
      $('#interest_message').val('');
      modal.foundation('close');
    }, 2000);
  }
}
