export const QuestionTypes = {
  PARAGRAPH: 'paragraph',
  TEXT_BOX: 'text_box',
  DROP_DOWN: 'drop_down',
  CHECK_BOX: 'check_box',
  FILE: 'file',
};

export const QuestionTypeMap = {
  [QuestionTypes.PARAGRAPH]: 0,
  [QuestionTypes.TEXT_BOX]: 1,
  [QuestionTypes.DROP_DOWN]: 2,
  [QuestionTypes.CHECK_BOX]: 3,
  [QuestionTypes.FILE]: 4,
};

export const QuestionTypeLabelMap = {
  [QuestionTypes.PARAGRAPH]: 'Paragraph',
  [QuestionTypes.TEXT_BOX]: 'Textbox',
  [QuestionTypes.DROP_DOWN]: 'Dropdown',
  [QuestionTypes.CHECK_BOX]: 'Checkbox',
  [QuestionTypes.FILE]: 'File',
};

export const QUESTION_TYPES_WITH_CHOICES = [
  QuestionTypes.DROP_DOWN,
  QuestionTypes.CHECK_BOX,
];

export const QuestionTypesList = [
  {
    label: QuestionTypeLabelMap[QuestionTypes.PARAGRAPH],
    value: QuestionTypes.PARAGRAPH,
  },
  {
    label: QuestionTypeLabelMap[QuestionTypes.TEXT_BOX],
    value: QuestionTypes.TEXT_BOX,
  },
  {
    label: QuestionTypeLabelMap[QuestionTypes.DROP_DOWN],
    value: QuestionTypes.DROP_DOWN,
  },
  {
    label: QuestionTypeLabelMap[QuestionTypes.CHECK_BOX],
    value: QuestionTypes.CHECK_BOX,
  },
  {
    label: QuestionTypeLabelMap[QuestionTypes.FILE],
    value: QuestionTypes.FILE,
  },
];
