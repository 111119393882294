import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'title', 'errors'];

  connect() {
    this.select2mount();
    document.addEventListener('turbolinks:before-cache', () => {
      this.select2unmount();
    }, { once: true });
  }

  disconnect() {
    this.select2unmount();
  }

  getErrorsTarget() {
    return $(this.errorsTarget);
  }

  onSaveFail(event) {
    const [data, status, xhr] = event.detail;
    this.getErrorsTarget().html(xhr.response);
  }

  onSaveSuccess(event) {
    this.clearForm();
  }

  onCancel() {
    this.clearForm();
  }

  clearForm() {
    this.titleTarget.value = '';
    $(this.selectTarget).val(null).trigger('change');
    this.clearErrors();
  }

  clearErrors() {
    this.getErrorsTarget().html('');
  }

  select2mount() {
    const placeholder = $(this.selectTarget).attr('placeholder');
    $(this.selectTarget).select2({
      minimumInputLength: 3,
      ajax: {
        url: '/profiles.json',
        dataType: 'json',
        data: function (params) {
          return { search: params.term };
        },
        processResults(data) {
          const results = (data.items || []).map((item) => {
            const firstName = (item.first_name || '').trim();
            const lastName = (item.last_name || '').trim();
            const fullName = `${firstName} ${lastName}`.trim();
            return {
              ...item,
              text: fullName,
            };
          });
          return { results };
        },
      },
      quietMillis: 400,
      debug: true,
      placeholder,
      templateResult: formatProfileOption,
    });
  }

  select2unmount() {
    $(this.selectTarget).select2('destroy');
  }
}

function formatProfileOption(option) {
  return $(option.template);
}
