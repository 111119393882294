import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import { MAX_FILE_SIZE } from '../constants/files';

export default class extends Controller {
  static targets = [
    'form',
    'postProcessForm',
    'file',
    'progressBar',
    'submitButton',
    'key',
  ];

  connect() {
    this.xmlParser = new window.DOMParser();
  }

  disconnect() {
    this.xmlParser = null;
  }

  onFileChange() {
    const { size } = this.fileTarget.files[0];

    if (size > MAX_FILE_SIZE) {
      this.showError('The file is too large');
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
      if (this.submitButtonTarget.getAttribute('auto')) {
        this.submitButtonTarget.click();
      }
    }
  }

  onAddClick() {
    this.fileTarget.click();
  }

  showError(message) {
    Snackbar.show({
      pos: 'bottom-right',
      actionTextColor: '#E3342F',
      text: message,
    });
  }

  updateProgressBar(percentCompleted) {
    const width = `${percentCompleted}%`;
    this.progressBarTarget.style.width = width;
    this.progressBarTarget.innerHTML = width;
  }

  resetProgressBar() {
    this.updateProgressBar(0);
  }

  showProgressBar() {
    this.progressBarTarget.classList.remove('hidden');
  }

  hideProgressBar() {
    this.progressBarTarget.classList.add('hidden');
  }

  resetFile() {
    this.fileTarget.type = '';
    this.fileTarget.type = 'file';
  }

  onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(this.formTarget);
    this.showProgressBar();
    axios
      .post(this.formTarget.action, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.updateProgressBar(percentCompleted);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'text',
      })
      .then((res) => {
        const xmlDoc = this.xmlParser.parseFromString(res.data, 'text/xml');
        const key = decodeURIComponent(xmlDoc.querySelector('Key').textContent);
        this.keyTarget.value = key;
        Rails.fire(this.postProcessFormTarget, 'submit');
        this.resetFile();
        this.hideProgressBar();
        this.resetProgressBar();
      })
      .catch((err) => {
        const xmlDoc = this.xmlParser.parseFromString(
          err.response.data,
          'text/xml',
        );
        const message = decodeURIComponent(
          xmlDoc.querySelector('Message').textContent,
        );
        this.resetFile();
        this.hideProgressBar();
        this.resetProgressBar();
        this.showError(message);
        this.submitButtonTarget.disabled = true;
      });
  }
}
