import { Controller } from '@hotwired/stimulus';

class SkillsForm {
  constructor() {
    this.$primarySkills = $('#onboarding-skills-primary');
    this.$secondarySkillsSection = $('#onboarding-skills-secondary-section');
    this.$secondarySkills = $('#onboarding-skills-secondary');
    this.$secondarySkillsHeader = $('#secondary-skills-header');
  }

  renderSecondaryCheckboxButton(parent, skill) {
    const { id, name } = skill;
    const elId = `profile_skills_secondary_${id}`;

    const checkboxButton = document.createElement('div');
    checkboxButton.classList.add('mx-4');
    checkboxButton.classList.add('checkbox-button');
    checkboxButton.setAttribute('data-parent', parent);

    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.value = id;
    checkbox.id = elId;
    checkbox.classList.add('checkbox-button__checkbox');
    checkbox.name = 'user[skill_ids][]';

    const label = document.createElement('label');
    label.classList.add('checkbox-button__option');
    label.setAttribute('for', elId);
    label.innerHTML = name;

    checkboxButton.appendChild(checkbox);
    checkboxButton.appendChild(label);

    return checkboxButton;
  }

  onPrimarySkillCheckboxChange = (e) => {
    const {
      target: {
        checked,
        value: id,
      },
    } = e;

    if (checked) {
      this.getSecondarySkills(id);
    } else {
      $(`.checkbox-button[data-parent="${id}"]`).remove();
      this.toggleSecondarySkillsSection();
    }
  }

  toggleSecondarySkillsSection() {
    if (this.$secondarySkills.children().length > 0) {
      this.$secondarySkillsSection.removeClass('hide');
    } else {
      this.$secondarySkillsSection.addClass('hide');
    }
  }

  renderSecondarySkills(primarySkillId, secondarySkills) {
    let i;
    const skillsCount = (secondarySkills || []).length;
    const $fragment = document.createDocumentFragment();

    let skill;
    for (i = 0; i < skillsCount; i += 1) {
      skill = secondarySkills[i];
      $fragment.appendChild(this.renderSecondaryCheckboxButton(primarySkillId, skill));
    }
    this.$secondarySkills[0].appendChild($fragment);
    this.toggleSecondarySkillsSection();
  }

  getSecondarySkills(primarySkillId) {
    $.ajax({
      url: '/skills/secondary_skills.json',
      data: {
        skill: primarySkillId,
      },
      cache: false,
    })
      .done((secondarySkills) => {
        this.$secondarySkillsHeader.removeClass('hide');
        this.renderSecondarySkills(primarySkillId, secondarySkills);
      })
      .fail((error) => {
        console.error(error);
      });
  }

  setupEvents() {
    this.$primarySkills.on('change', '.checkbox-button__checkbox', this.onPrimarySkillCheckboxChange);
  }

  teardown() {
    this.$primarySkills.off('change', '.checkbox-button__checkbox', this.onPrimarySkillCheckboxChange);
  }
}

export default class extends Controller {
  connect() {
    this.skillsForm = new SkillsForm();
    this.skillsForm.setupEvents();
  }
}
