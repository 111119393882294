import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default class QuestionChoiceField extends Component {
  static propTypes = {
    choice: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  }

  handleChange = e => this.props.onChange(e.target.value);

  handleRemove = () => this.props.onRemove();

  render() {
    const {
      choice: {
        name,
      },
    } = this.props;

    return (
      <li className="mb-4 border-grey-light border-solid form-group flex justify-between items-center">
        <input
          type="text"
          placeholder="Enter the name of the answer choice"
          value={name}
          className="m-0 mr-2"
          onChange={this.handleChange}
        />
        <button
          type="button"
          className="text-grey-light"
          onClick={this.handleRemove}
        >
          <XMarkIcon className="w-4 h-4 text-gray-400 hover:text-gray-700" />
        </button>
      </li>
    );
  }
}
