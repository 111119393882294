import { Controller } from '@hotwired/stimulus';
import { bool } from 'prop-types';

export default class extends Controller {
  static values = {
    id: String,
    url: String,
    loadOnMount: Boolean,
  };

  static targets = ['content'];

  connect() {
    if (this.loadOnMount) {
      this.load();
    }

    if (this.idValue) {
      document.addEventListener(this.eventName, this.load);
    }
  }

  disconnect() {
    if (this.idValue) {
      document.removeEventListener(this.eventName, this.load);
    }
  }

  get eventName() {
    return `${this.idValue}:reload`;
  }

  load = async () => {
    const res = await fetch(this.urlValue);
    const text = await res.text();

    const el = this.hasContentTarget ? this.contentTarget : this.element;
    el.innerHTML = text;
  };

  get loadOnMount() {
    if (typeof this.loadOnMountValue === 'undefined') {
      return true;
    }
    return this.loadOnMountValue;
  }
}
