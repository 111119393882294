import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class CheckboxQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      helpText: PropTypes.string,
      data: PropTypes.shape({
        choices: PropTypes.array,
      }),
    }).isRequired,
    answer: PropTypes.shape({
      data: PropTypes.string,
    }),
    onAnswerChange: PropTypes.func.isRequired,
    validationStatus: PropTypes.object,
  }

  static defaultProps = {
    answer: {
      data: '',
    },
  }

  getAnswerValues() {
    const answer = this.props.answer || {};
    const data = answer.data || JSON.stringify({ choices: [] });

    return JSON.parse(data).choices;
  }

  handleChange = (e) => {
    const currentValues = this.getAnswerValues();

    const {
      target: {
        value,
        checked,
      },
    } = e;

    let values;
    if (checked && !currentValues.includes(value)) {
      values = [
        ...currentValues,
        value,
      ];
    } else if (!checked) {
      values = currentValues.filter(v => v !== value);
    }

    this.props.onAnswerChange(JSON.stringify({ choices: values }));
  }

  render() {
    const {
      index,
      question,
      validationStatus,
    } = this.props;

    const finalClassName = {
      'form-group': true,
      'mb-4': true,
    };

    if (this.props.className) {
      finalClassName[this.props.className] = true;
    }

    if (validationStatus && !validationStatus.success) {
      finalClassName['error-wrapper'] = true;
    }

    const fieldName = `competition_application[answers_attributes][${index}][(field)]`;
    const hiddenField = fieldName.replace('(field)', 'question_id');
    const answerField = fieldName.replace('(field)', 'data');
    const values = this.getAnswerValues();

    return (
      <div className={classnames(finalClassName)}>
        <label
          className="mb-2 form-label"
          htmlFor={fieldName}
        >
          {question.title}
        </label>
        <div className="checkbox-wrapper">
          {question.data.choices.map((choice, i) => {
            const id = generateId(question, i);
            return (
              <div
                key={choice.name}
                className="flex items-center mb-2"
              >
                <input
                  type="checkbox"
                  className="mb-0"
                  id={id}
                  value={choice.name}
                  onChange={this.handleChange}
                  checked={values.includes(choice.name)}
                />
                <label
                  className="ml-2 text-sm"
                  htmlFor={id}
                >
                  {choice.name}
                </label>
              </div>
            );
          })}
        </div>
        {validationStatus && (
          <p className="font-bold text-red-400">{validationStatus.errorMessage}</p>
        )}
      </div>
    );
  }
}

function generateId(question, i) {
  return `question-${question.id}-choice-${i}`;
}
