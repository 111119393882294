import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'content',
  ];

  connect() {
    $(this.element).on('closed.zf.reveal', this.onClosed);
    if (!this.elementId) {
      throw new Error('The modal is missing an id');
    }
    document.addEventListener(`${this.elementId}-open`, this.onOpen);
    document.addEventListener(`${this.elementId}-close`, this.onClose);
    document.addEventListener(`${this.elementId}-update`, this.onUpdateContent);
    document.addEventListener(`${this.elementId}:updated`, this.onUpdateContent);
  }

  disconnect() {
    $(this.element).off('closed.zf.reveal', this.onClosed);
    document.removeEventListener(`${this.elementId}-open`, this.onOpen);
    document.removeEventListener(`${this.elementId}-close`, this.onClose);
    document.removeEventListener(`${this.elementId}-update`, this.onUpdateContent);
    document.removeEventListener(`${this.elementId}:updated`, this.onUpdateContent);
  }

  onUpdateContent = (event) => {
    if (event && event.detail) {
      this.contentTarget.innerHTML = event.detail;
      this.onOpen();
    }
  }

  onOpen = () => {
    $(this.element).foundation('open');
  }

  onClose = () => {
    $(this.element).foundation('close');
  }

  onClosed = () => {
    if (this.clearOnClose) {
      this.contentTarget.innerHTML = '';
    }
  }

  get clearOnClose() {
    const clearOnCloseValue = this.data.get('clear-on-close');
    if (typeof clearOnCloseValue !== 'undefined' || clearOnCloseValue !== null) {
      return clearOnCloseValue === 'true';
    }
    return true;
  }

  get elementId() {
    return this.element.id;
  }
}
