import { Controller } from '@hotwired/stimulus';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onSendSuccess = () => {
    Snackbar.show({ text: 'Your message has been sent!' });
    const $interestedModal = $('#interested-modal');
    setTimeout(() => {
      this.element.reset();
      $interestedModal.foundation('close');
    }, 0);
  }

  onSendFail() {
    Snackbar.show({ text: 'There was a problem sending your message', actionTextColor: ERROR_COLOR });
  }
}
