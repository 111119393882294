import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    id: String,
    replaceParent: Boolean,
  };

  static targets = ['content'];

  connect() {
    document.addEventListener(this.deleteEventName, this.onContentDeleted);
    document.addEventListener(this.eventName, this.onContentUpdated);
  }

  disconnect() {
    document.removeEventListener(this.deleteEventName, this.onContentDeleted);
    document.removeEventListener(this.eventName, this.onContentUpdated);
  }

  onContentDeleted = (e) => {
    e.stopPropagation();

    const { contentEl } = this;

    contentEl.remove();
  };

  onContentUpdated = (e) => {
    e.stopPropagation();
    const { detail } = e;

    const { contentEl } = this;

    if (this.replaceParent) {
      contentEl.parentElement.innerHTML = detail;
    } else {
      contentEl.innerHTML = detail;
    }
  };

  get contentEl() {
    if (this.hasContentTarget) {
      return this.contentTarget;
    }
    return this.element;
  }

  get replaceParent() {
    return this.replaceParentValue === true;
  }

  get deleteEventName() {
    return `${this.idValue}:deleted`;
  }

  get eventName() {
    return `${this.idValue}:updated`;
  }
}
