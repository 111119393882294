import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

const DEFAULT_SUCCESS_MESSAGE = 'The form submission succeeded!';
const DEFAULT_ERROR_MESSAGE = 'There was a problem submitting the form.';

export default class extends Controller {
  static values = {
    contentId: String,
    successMessage: String,
    errorMessage: String,
    showStatusMessage: Boolean,
    submitEvent: String,
  };

  static targets = ['errors'];

  connect() {
    if (this.submitEventValue) {
      document.addEventListener(this.submitEventValue, this.onSubmit);
    }
  }

  disconnect() {
    if (this.submitEventValue) {
      document.removeEventListener(this.submitEventValue, this.onSubmit);
    }
  }

  onSubmit = () => {
    // this.element.submit();
    Rails.fire(this.element, 'submit');
  };

  onSubmitSuccess = (e) => {
    const [, , xhr] = e.detail;

    if (this.showStatusMessage) {
      Snackbar.show({ text: this.successMessage });
    }

    if (!this.contentIdValue) {
      return;
    }
    document.dispatchEvent(
      new CustomEvent(`${this.contentIdValue}:updated`, {
        detail: xhr.response,
      }),
    );
  };

  onSubmitFail = (e) => {
    const [, , xhr] = e.detail;

    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = xhr.response;
    }
    if (this.showStatusMessage) {
      Snackbar.show({ text: this.errorMessage, actionTextColor: ERROR_COLOR });
    }
  };

  get successMessage() {
    return this.successMessageValue || DEFAULT_SUCCESS_MESSAGE;
  }

  get errorMessage() {
    return this.errorMessageValue || DEFAULT_ERROR_MESSAGE;
  }

  get showStatusMessage() {
    if (
      typeof this.showStatusMessageValue === 'undefined' ||
      this.showStatusMessageValue === null
    ) {
      return true;
    }
    return this.showStatusMessageValue;
  }
}
