import { Controller } from '@hotwired/stimulus';

const ITEMS_TO_SHOW = 10;
export default class extends Controller {
  static targets = ['loadMoreButton', 'showLessButton', 'item'];

  connect() {
    let $passionItem = $('.passion-item');
    $passionItem.slice(0, ITEMS_TO_SHOW).show();
  }

  onSubmit() {
    this.element.submit();
  }

  onLoadMore(e) {
    e.preventDefault();
    $('.passion-item:hidden').slice().slideDown();
    this.loadMoreButtonTarget.classList.add('hidden');
    this.showLessButtonTarget.classList.remove('hidden');
  }

  onShowLess(e) {
    e.preventDefault();
    const count = $('.passion-item').length;
    $('.passion-item').slice(ITEMS_TO_SHOW, count).slideUp();
    this.loadMoreButtonTarget.classList.remove('hidden');
    this.showLessButtonTarget.classList.add('hidden');
  }
}
