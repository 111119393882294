import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'totalPointsAssigned',
  ];

  connect() {
    document.addEventListener('voting-cap:update', this.onUpdate);
  }

  disconnect() {
    document.removeEventListener('voting-cap:update', this.onUpdate);
  }

  onUpdate = (e) => {
    const {
      detail: {
        totalPointsAssigned,
      },
    } = e;

    this.totalPointsAssignedTarget.textContent = totalPointsAssigned;
  }
}
