import { Controller } from '@hotwired/stimulus';
import axios from 'axios';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = [
    'position',
    'formWrapper',
  ];

  onPositionChange(e) {
    const {
      currentTarget: {
        value,
      },
    } = e;

    if (!value) {
      this.formWrapper.innerHTML = null;
      return;
    }
    const projectPositionId = parseInt(value, 10);
    this.loadPositionForm(projectPositionId);
  }

  async loadPositionForm(projectPositionId) {
    const projectId = parseInt(this.data.get('project-id'), 10);
    const url = `/projects/${projectId}/applications/${projectPositionId}`;
    try {
      const res = await axios.get(
        url,
        {
          _: new Date().getTime(),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
          },
        },
      );
      const {
        data,
      } = res;
      this.formWrapperTarget.innerHTML = data;
    } catch (e) {
      console.error(e);
      Snackbar.show({ text: 'There was a problem loading the form', actionTextColor: ERROR_COLOR });
    }
  }
}
