import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['connectButton'];

  onInviteSuccess() {
    this.connectButtonTarget.disabled = true;
    Snackbar.show({ text: 'The invitation has been sent' });
  }

  onInviteFail() {
    Snackbar.show({
      text: 'There was a problem sending the invitation',
      actionTextColor: ERROR_COLOR,
    });
  }
}
