/* global Snackbar */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'memberList',
  ];

  connect() {
  }

  onBeforeShowEditForm(e) {
    this.editId = $(e.target).closest('tr').data('id');
  }

  onUpdateForm(event) {
    const [data, status, xhr] = event.detail;
    this.membersModalController.updateContent(xhr.response);
    this.membersModalController.show();
  }

  onShowEditForm(e) {
    this.onUpdateForm(e);
  }

  onDeleteSuccess(e) {
    $(e.target).closest('tr').remove();
  }

  onCancelForm() {
    this.editId = null;
  }

  onCreateSuccess(html) {
    this.memberListTarget.insertAdjacentHTML('beforeend', html);
    this.flashMessage('We added the team member successfully.');
  }

  onUpdateSuccess(html) {
    if (this.editId) {
      const memberEl = document.querySelector(`tr[data-id="${this.editId}"]`);

      memberEl.style.display = 'none';
      memberEl.insertAdjacentHTML('beforeBegin', html);
      memberEl.remove();
      this.flashMessage('We updated the team member info successfully.');
      this.editId = null;
      this.membersModalController.hide();
    }
  }

  flashMessage(text) {
    Snackbar.show({ text });
  }

  get membersModalController() {
    const el = document.getElementById('members-modal-content');
    const controller = this.application.getControllerForElementAndIdentifier(el, 'members-modal');
    return controller;
  }
}
