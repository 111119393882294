import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'menu'];

  active = false;

  $button = null;

  $menu = null;

  connect() {
    this.$menu = $(this.menuTarget);
    this.$button = $(this.buttonTarget);

    this.$button.on('click', this.handleButtonClick);
  }

  disconnect() {
    this.$button.off('click', this.handleButtonClick);
  }

  handleButtonClick = () => {
    this.active = !this.active;
    this.refreshState();
  };

  refreshState() {
    if (this.active) {
      this.$menu.removeClass('invisible');
      return;
    }

    this.$menu.addClass('invisible');
  }
}
