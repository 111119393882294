import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'state',
    'round',
    'selectAll',
  ];

  connect() {
    const rounds = [];
    [].forEach.call(this.roundTargets, (roundElement) => {
      if (roundElement.checked) {
        const round = this.parseRoundValue(roundElement);
        rounds.push(round);
      }
    });
    this.saveState({
      rounds,
    });
  }

  parseRoundValue(roundElement) {
    const {
      value,
    } = roundElement;

    const round = parseInt(value, 10);
    return round;
  }

  onRoundChecked = (e) => {
    const {
      target,
    } = e;

    const {
      checked,
      value,
    } = target;

    const round = parseInt(value, 10);
    let rounds;
    const currentState = this.getState();
    const {
      rounds: currentRounds,
    } = currentState;
    if (checked && !currentRounds.includes(round)) {
      rounds = [
        ...currentRounds,
        round,
      ].sort();
    } else if (!checked) {
      rounds = currentRounds.filter((r) => r !== round).sort();
    }

    if (rounds.length === this.roundTargets.length) {
      this.selectAllTarget.checked = true;
    } else {
      this.selectAllTarget.checked = false;
    }

    const payload = {
      rounds,
    };

    this.saveState(payload);
  }

  onSelectAllToggled = (e) => {
    const {
      target: {
        value,
        checked,
      },
    } = e;

    [].forEach.call(this.roundTargets, (roundElement) => {
      /* eslint no-param-reassign: 0 */
      roundElement.checked = checked;
    });

    const rounds = checked ? value.split(',').map((r) => parseInt(r, 10)).sort() : [];
    const payload = {
      rounds,
    };
    this.saveState(payload);
  }

  getState() {
    const currentState = JSON.parse(this.stateTarget.value || { rounds: [] });
    if (!currentState.rounds) {
      return {
        rounds: [],
      };
    }
    return currentState;
  }

  saveState(data = { rounds: [] }) {
    this.stateTarget.value = JSON.stringify(data);
  }
}
