import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';
import QuestionForm from './questions/QuestionForm';
import { PROFIlE_TYPE, CUSTOM_TYPE } from './questions/types';

import { ERROR_COLOR } from '../constants/colors';

import ProTip from './ProTip';
import CompetitionQuestionGroup from './questions/CompetitionQuestionGroup';
import Tabs from './Tabs';

import { QuestionTypes } from '../constants/questions';

const DEFAULT_QUESTION_TYPE = QuestionTypes.PARAGRAPH;

const endpoints = {
  questions: {
    create: '/applications/:competition_id/questions.json',
    update: '/applications/:competition_id/questions/:id.json',
    delete: '/applications/:competition_id/questions/:id.json',
    move: '/applications/:competition_id/questions/move.json',
  },
};

const tabs = [
  {
    name: 'Standard Questions',
    type: PROFIlE_TYPE,
    current: true,
  },
  {
    name: 'Custom Questions',
    type: CUSTOM_TYPE,
    current: false,
  },
];

function mapQuestionGroup(group) {
  return {
    ...group,
    custom_questions: group.custom_questions.map((q, i) => ({
      ...q,
      position: i + 1,
    })),
    profile_questions: group.profile_questions.map((q, i) => ({
      ...q,
      position: i + 1,
    })),
  };
}

function mapMoveQuestionPayload(question) {
  const { id, round, question_type, position } = question;

  return {
    id,
    question_type,
    round,
    position,
  };
}

function setOrder(question, i) {
  return {
    ...question,
    position: i + 1,
  };
}
export default class CompetitionQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionGroups: props.question_groups.map(mapQuestionGroup),
      showProTip: true,
      showQuestionForm: false,
      selectedQuestion: undefined,
      tabs: [...tabs],
    };
  }

  handleSelectTab = (selectedTab) => {
    this.setState((prevState) => {
      return {
        tabs: prevState.tabs.map((tab) => ({
          ...tab,
          current: tab.name === selectedTab.name,
        })),
      };
    });
  };

  handleCancelQuestionForm = () => this.resetQuestionForm();

  handleCloseTip = () => this.setState({ showProTip: false });

  handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      this.move(source, destination);
    } else {
      this.reorder(source, destination);
    }
  };

  handleAddQuestion = (round) => {
    const selectedQuestion = {
      id: null,
      title: '',
      question_type: DEFAULT_QUESTION_TYPE,
      round,
      data: {},
    };

    this.setState((prevState) => {
      return {
        showQuestionForm: true,
        selectedQuestion,
        tabs: prevState.tabs.map((tab) => ({
          ...tab,
          current: tab.type === CUSTOM_TYPE,
        })),
      };
    });
  };

  handleToggleQuestion = (round) => (questionId, enabled) => {
    const { questionGroups: currentQuestionGroups } = this.state;

    const group = currentQuestionGroups.find((g) => g.round === round);
    const question = {
      ...group.profile_questions.find((q) => q.id === questionId),
      enabled,
    };
    this.updateQuestion(question);
  };

  handleEditQuestion = (round) => (questionId) => {
    const { questionGroups: currentQuestionGroups } = this.state;

    const group = currentQuestionGroups.find((g) => g.round === round);
    const field = this.getActiveQuestionsField();
    const selectedQuestion = {
      ...group[field].find((q) => q.id === questionId),
    };

    this.setState({
      showQuestionForm: true,
      selectedQuestion,
    });
  };

  handleRemoveQuestionSuccess = (round, questionId) => {
    this.setState((currentState) => {
      const { questionGroups: currentQuestionGroups } = currentState;

      const groupIndex = currentQuestionGroups.findIndex(
        (g) => g.round === round,
      );
      const targetGroup = cloneDeep(currentQuestionGroups[groupIndex]);

      const field = this.getActiveQuestionsField();
      const questionIndex = targetGroup[field].findIndex(
        (q) => q.id === questionId,
      );

      targetGroup[field] = [
        ...targetGroup[field].slice(0, questionIndex),
        ...targetGroup[field].slice(questionIndex + 1),
      ].map((q, i) => ({
        ...q,
        position: i,
      }));

      const questionGroups = [
        ...currentQuestionGroups.slice(0, groupIndex),
        targetGroup,
        ...currentQuestionGroups.slice(groupIndex + 1),
      ];

      return {
        questionGroups,
      };
    });
  };

  handleRemoveQuestion = (round) => (questionId) => {
    const url = endpoints.questions.delete
      .replace(':competition_id', this.props.competitionId)
      .replace(':id', questionId);

    axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
            .content,
        },
      })
      .then(() => {
        Snackbar.show({
          text: 'The application question was deleted successfully',
        });
        this.handleRemoveQuestionSuccess(round, questionId);
      })
      .catch((err) => {
        const {
          response: {
            data: { errors: resError },
          },
        } = err;
        if (resError) {
          Snackbar.show({
            text: 'There was a problem creating the question',
            actionTextColor: ERROR_COLOR,
          });
          console.log(resError);
        }
      });
  };

  handleSaveQuestion = (question) => {
    if (!question.id) {
      this.createQuestion(question);
    } else {
      this.updateQuestion(question);
    }
  };

  handleCreateQuestionSuccess(question) {
    this.setState((currentState) => {
      const { questionGroups: currentQuestionGroups } = currentState;

      const groupIndex = currentQuestionGroups.findIndex(
        (g) => g.round === question.round,
      );
      const targetGroup = cloneDeep(currentQuestionGroups[groupIndex]);
      const field = this.getActiveQuestionsField();

      targetGroup[field] = [...targetGroup[field], question];

      const questionGroups = [
        ...currentQuestionGroups.slice(0, groupIndex),
        targetGroup,
        ...currentQuestionGroups.slice(groupIndex + 1),
      ];

      return {
        questionGroups,
      };
    });
  }

  handleUpdateQuestionSuccess(question) {
    this.setState((currentState) => {
      const { questionGroups: currentQuestionGroups } = currentState;

      const groupIndex = currentQuestionGroups.findIndex(
        (g) => g.round === question.round,
      );
      const targetGroup = cloneDeep(currentQuestionGroups[groupIndex]);

      const field = this.getActiveQuestionsField();
      const questionIndex = targetGroup[field].findIndex(
        (q) => q.id === question.id,
      );
      targetGroup[field][questionIndex] = {
        ...question,
      };

      const questionGroups = [
        ...currentQuestionGroups.slice(0, groupIndex),
        targetGroup,
        ...currentQuestionGroups.slice(groupIndex + 1),
      ];

      return {
        questionGroups,
      };
    });
  }

  getActiveTab() {
    return this.state.tabs.find((tab) => tab.current).type;
  }

  getActiveQuestionsField() {
    return `${this.getActiveTab()}_questions`;
  }

  resetQuestionForm = () =>
    this.setState({
      showQuestionForm: false,
      selectedQuestion: undefined,
    });

  createQuestion = (question) => {
    const url = endpoints.questions.create.replace(
      ':competition_id',
      this.props.competitionId,
    );
    const { questionGroups: currentQuestionGroups } = this.state;

    const groupIndex = currentQuestionGroups.findIndex(
      (g) => g.round === question.round,
    );
    const targetGroup = cloneDeep(currentQuestionGroups[groupIndex]);
    const field = this.getActiveQuestionsField();

    const position = targetGroup[field].length + 1;
    axios
      .post(url, JSON.stringify({ ...question, position }), {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
            .content,
        },
      })
      .then((res) => {
        Snackbar.show({
          text: 'The application question was created successfully',
        });
        this.handleCreateQuestionSuccess(res.data.question);
        this.resetQuestionForm();
      })
      .catch((err) => {
        const {
          response: {
            data: { errors: resError },
          },
        } = err;
        if (resError) {
          Snackbar.show({
            text: 'There was a problem creating the question',
            actionTextColor: ERROR_COLOR,
          });
          console.log(resError);
        }
      });
  };

  updateQuestion = (data) => {
    const { created_at, updated_at, ...question } = data;

    if (!question.data) {
      delete question.data;
    }

    const url = endpoints.questions.update
      .replace(':competition_id', this.props.competitionId)
      .replace(':id', question.id);

    axios
      .patch(url, JSON.stringify(question), {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
            .content,
        },
      })
      .then((res) => {
        Snackbar.show({
          text: 'The application question was updated successfully',
        });
        this.resetQuestionForm();
        this.handleUpdateQuestionSuccess(res.data.question);
      })
      .catch((err) => {
        const {
          response: {
            data: { errors: resError },
          },
        } = err;
        if (resError) {
          Snackbar.show({
            text: 'There was a problem creating the question',
            actionTextColor: ERROR_COLOR,
          });
          console.log(resError);
        }
      });
  };

  move(source, destination) {
    if (!destination.droppableId) {
      return;
    }
    const sourceRound = parseInt(source.droppableId, 10);
    const destinationRound = parseInt(destination.droppableId, 10);

    const { questionGroups: currentQuestionGroups } = this.state;

    const sourceRoundIndex = currentQuestionGroups.findIndex(
      (g) => g.round === sourceRound,
    );
    const sourceQuestionGroup = cloneDeep(
      currentQuestionGroups[sourceRoundIndex],
    );

    const destinationRoundIndex = currentQuestionGroups.findIndex(
      (g) => g.round === destinationRound,
    );
    const destinationQuestionGroup = cloneDeep(
      currentQuestionGroups[destinationRoundIndex],
    );

    const field = this.getActiveQuestionsField();
    const [removed] = sourceQuestionGroup[field].splice(source.index, 1);

    removed.round = destinationQuestionGroup.round;

    sourceQuestionGroup[field] = sourceQuestionGroup[field].map(setOrder);

    destinationQuestionGroup[field].splice(destination.index, 0, removed);
    destinationQuestionGroup[field] =
      destinationQuestionGroup[field].map(setOrder);

    let firstIndex;
    let firstGroup;
    let secondIndex;
    let secondGroup;
    if (sourceRoundIndex < destinationRoundIndex) {
      firstIndex = sourceRoundIndex;
      firstGroup = sourceQuestionGroup;
      secondIndex = destinationRoundIndex;
      secondGroup = destinationQuestionGroup;
    } else {
      firstIndex = destinationRoundIndex;
      firstGroup = destinationQuestionGroup;
      secondIndex = sourceRoundIndex;
      secondGroup = sourceQuestionGroup;
    }

    const questionGroups = [
      ...currentQuestionGroups.slice(0, firstIndex),
      firstGroup,
      ...currentQuestionGroups.slice(firstIndex + 1, secondIndex),
      secondGroup,
      ...currentQuestionGroups.slice(secondIndex + 1),
    ];

    this.setState({
      questionGroups,
    });

    this.moveQuestions([
      ...firstGroup[field].map(mapMoveQuestionPayload),
      ...secondGroup[field].map(mapMoveQuestionPayload),
    ]);
  }

  moveQuestions(questions) {
    const payload = {
      question: {
        questions,
      },
    };

    const url = endpoints.questions.move.replace(
      ':competition_id',
      this.props.competitionId,
    );

    axios
      .post(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
            .content,
        },
      })
      .then(() => {
        Snackbar.show({ text: 'The application question has been moved' });
      })
      .catch((err) => {
        const {
          response: {
            data: { errors: resError },
          },
        } = err;
        if (resError) {
          Snackbar.show({
            text: 'There was a problem creating the question',
            actionTextColor: ERROR_COLOR,
          });
          console.log(resError);
        }
      });
  }

  reorder(source, destination) {
    if (!destination.droppableId) {
      return;
    }
    const round = parseInt(destination.droppableId, 10);
    const { questionGroups: currentQuestionGroups } = this.state;

    const roundIndex = currentQuestionGroups.findIndex(
      (r) => r.round === round,
    );
    if (roundIndex === -1) {
      return;
    }
    const targetQuestionGroup = cloneDeep(currentQuestionGroups[roundIndex]);

    const field = this.getActiveQuestionsField();
    const { [field]: currentQuestions } = targetQuestionGroup;

    targetQuestionGroup[field] = this.reorderQuestions(
      currentQuestions,
      source.index,
      destination.index,
    );

    const questionGroups = [
      ...currentQuestionGroups.slice(0, roundIndex),
      targetQuestionGroup,
      ...currentQuestionGroups.slice(roundIndex + 1),
    ];

    this.setState({
      questionGroups,
    });

    // call API to reorder questions for a group
    this.moveQuestions(targetQuestionGroup[field].map(mapMoveQuestionPayload));
  }

  reorderQuestions(list, startIndex, endIndex) {
    const questions = Array.from(list);
    const [removed] = questions.splice(startIndex, 1);
    questions.splice(endIndex, 0, removed);
    return questions.map(setOrder);
  }

  render() {
    const { questionGroups } = this.state;

    return (
      <div className="questions-wrapper">
        <h2 className="mb-4 text-2xl">Application Questions</h2>
        <Tabs
          tabs={this.state.tabs}
          onSelect={this.handleSelectTab}
          className="mb-4"
        />
        {this.state.showProTip && (
          <div className="p-4 mb-4 bg-white shadow-lg">
            <div className="mb-4">
              <ProTip onClose={this.handleCloseTip}>
                <ol>
                  <li>
                    You can drag and drop questions to move them between rounds.
                  </li>
                  <li>
                    Toggle between the Standard and Custom tabs to see your
                    questions in the different rounds. Each round is comprised
                    of the Standardized and Customized questions that appear in
                    each tab. They are separated for you (but not applicants) to
                    make the organization a bit easier!
                  </li>
                </ol>
              </ProTip>
            </div>
          </div>
        )}
        {this.state.showQuestionForm && (
          <QuestionForm
            question={this.state.selectedQuestion}
            onCancel={this.handleCancelQuestionForm}
            onSave={this.handleSaveQuestion}
          />
        )}
        <DragDropContext onDragEnd={this.handleDragEnd}>
          {questionGroups.map((questionGroup) => (
            <CompetitionQuestionGroup
              key={questionGroup.round}
              activeTab={this.getActiveTab()}
              questionGroup={questionGroup}
              onAddQuestion={this.handleAddQuestion}
              onEditQuestion={this.handleEditQuestion(questionGroup.round)}
              onRemoveQuestion={this.handleRemoveQuestion(questionGroup.round)}
              onToggleQuestion={this.handleToggleQuestion(questionGroup.round)}
            />
          ))}
        </DragDropContext>
      </div>
    );
  }
}

CompetitionQuestions.propTypes = {
  competitionId: PropTypes.number.isRequired,
  question_groups: PropTypes.array,
};

CompetitionQuestions.defaultProps = {};
