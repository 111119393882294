import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';

export default class CompetitionRoundType extends Component {
  handleClick = (e) => {
    e.preventDefault();
    const {
      competitionRoundType: {
        key,
      },
    } = this.props;
    this.props.onSelect(key);
  }

  render() {
    const {
      competitionRoundType: {
        label,
      },
      selected,
      className,
      helpTextComponent,
    } = this.props;

    const finalClassName = classnames({
      shadow: true,
      'bg-white': true,
      'p-6': true,
      ...className,
      'border-transparent': !selected,
      'border-blue-400': selected,
      'border-solid': true,
      flex: true,
      'justify-center': true,
      'items-center': true,
      'outline-none': true,
      'hover:border-blue-400': true,
      border: true,
      rounded: true,
      ...className,
      'mx-6': true,
      'h-full': true,
    });

    const textClassName = classnames({
      'text-grey': !selected,
      'text-grey-darker': selected,
      'm-0': true,
    });

    return (
      <div className="md:w-1/4 w-1/2 mb-4">
        <Tippy
          content={helpTextComponent}
        >
          <button
            className={finalClassName}
            type="button"
            onClick={this.handleClick}
          >
            <p className={textClassName}>
              {label}
            </p>
          </button>
        </Tippy>
      </div>
    );
  }
}

CompetitionRoundType.propTypes = {
  competitionRoundType: PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  helpTextComponent: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  className: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

CompetitionRoundType.defaultProps = {
  selected: false,
  className: {},
};
