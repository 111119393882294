import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ percent }) => {
  const style = { width: `${percent}%` };
  return (
    <div className="w-full shadow bg-grey-300">
      <div className="py-2 text-xs leading-none text-center text-white bg-blue-500" style={style}>
        <span>{percent}</span>
        <span>%</span>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
};

export default ProgressBar;
