import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'editor',
    'hiddenTextField',
  ];

  validator = null;

  connect() {
    if (this.hiddenTextFieldTarget.required) {
      this.validator = $(this.element).validate({
        submitHandler: (form) => {
          const editorDocument = this.editor.getDocument();
          const editorText = (editorDocument.toString() || '').trim();
          const isEmpty = editorText.trim().length === 0;

          if (isEmpty) {
            this.hiddenTextFieldTarget.value = '';
            this.hiddenTextFieldTarget.dispatchEvent(new Event('change'));
            $(this.element).valid();
            return false;
          }
          return true;
        },
      });
    }
  }

  disconnect() {
    if (this.validator) {
      this.validator.destroy();
      this.validator = null;
    }
  }

  get editor() {
    return this.editorTarget.editor;
  }
}
