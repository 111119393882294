import { Controller } from '@hotwired/stimulus';
import ConfirmModalEvents from '../constants/events/confirm-modal';

export default class extends Controller {
  connect() {
    document.addEventListener(ConfirmModalEvents.OPEN, this.onOpen);
    document.addEventListener(ConfirmModalEvents.CLOSE, this.onClose);
  }

  disconnect() {
    document.removeEventListener(ConfirmModalEvents.OPEN, this.onOpen);
    document.removeEventListener(ConfirmModalEvents.CLOSE, this.onClose);
  }

  onOpen = () => {
    console.log('opening confirm');
    this.open();
  }

  onClose = () => this.close();

  onConfirm() {
    const confirmedEvent = new CustomEvent(ConfirmModalEvents.CONFIRMED, {
      bubbles: true,
    });
    document.dispatchEvent(confirmedEvent);
    this.close();
  }

  close() {
    $(this.element).foundation('close');
  }

  open() {
    $(this.element).foundation('open');
  }
}
