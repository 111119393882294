import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

const DEFAULT_SUCCESS_MESSAGE = 'The form submission succeeded!';
const DEFAULT_ERROR_MESSAGE = 'There was a problem submitting the form.';

export default class extends Controller {
  static values = {
    modalId: String,
    contentId: String,
    successMessage: String,
    errorMessage: String,
  };

  static targets = ['errors'];

  onSubmitSuccess = (e) => {
    e.stopPropagation();
    const [, , xhr] = e.detail;

    document.dispatchEvent(
      new CustomEvent(`${this.modalIdValue}-close`, {
        detail: {},
      }),
    );
    Snackbar.show({ text: this.successMessage });
    if (!this.contentIdValue) {
      return;
    }
    document.dispatchEvent(
      new CustomEvent(`${this.contentIdValue}:updated`, {
        detail: xhr.response,
      }),
    );
  };

  onSubmitFail = (e) => {
    e.stopPropagation();
    const [, , xhr] = e.detail;

    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = xhr.response;
    }
    Snackbar.show({ text: this.errorMessage, actionTextColor: ERROR_COLOR });
  };

  onCancel = (e) => {
    e.preventDefault();
    if (!this.modalIdValue) {
      return;
    }
    document.dispatchEvent(new CustomEvent(`${this.modalIdValue}-close`));
  };

  get successMessage() {
    return this.successMessageValue || DEFAULT_SUCCESS_MESSAGE;
  }

  get errorMessage() {
    return this.errorMessageValue || DEFAULT_ERROR_MESSAGE;
  }
}
