import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';

export default class extends Controller {
  static values = {
    message: String,
  };

  static targets = ['link'];

  copy() {
    this.linkTarget.focus();
    this.linkTarget.select();
    document.execCommand('copy');
    if (this.messageValue) {
      Snackbar.show({ text: this.messageValue });
    }
  }
}
