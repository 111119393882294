import { Controller } from '@hotwired/stimulus';
import { ERROR_COLOR } from '../constants/colors';

import { MAX_FILE_SIZE } from '../constants/files';

export default class extends Controller {
  static values = {
    contentId: String,
    successMessage: String,
    errorMessage: String,
    showStatusMessage: Boolean,
    submitEvent: String,
  };

  static targets = ['errors', 'file', 'progressBar'];

  onFileChange = () => {
    const filesTooLarge = Array.from(this.fileTarget.files).some(
      (f) => f.size > MAX_FILE_SIZE,
    );

    if (filesTooLarge) {
      this.showError('The files are too large');
      this.resetFile();
    } else {
      Rails.fire(this.element, 'submit');
    }
  };

  showError(message) {
    Snackbar.show({
      pos: 'bottom-right',
      actionTextColor: ERROR_COLOR,
      text: message,
    });
  }

  onInitialize = (event) => {
    const { target, detail } = event;
    const { id, file } = detail;
    console.log(target);
    target.insertAdjacentHTML('beforebegin', getProgressBar(id));
    target.previousElementSibling.querySelector(
      '.direct-upload__filename',
    ).textContent = file.name;
  };

  onStart = (event) => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove('direct-upload--pending');
  };

  onProgress = (event) => {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(
      `direct-upload-progress-${id}`,
    );
    progressElement.style.width = `${progress}%`;
  };

  onEnd = (event) => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--complete');
  };

  onError = (event) => {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add('direct-upload--error');
    element.setAttribute('title', error);
  };

  onUploadsEnd = (event) => {
    this.element.submit();
  };

  onSubmitSuccess = (e) => {
    const [, , xhr] = e.detail;

    if (this.showStatusMessage) {
      Snackbar.show({ text: this.successMessage });
    }

    if (!this.contentIdValue) {
      return;
    }
    document.dispatchEvent(
      new CustomEvent(`${this.contentIdValue}:updated`, {
        detail: xhr.response,
      }),
    );
  };

  onSubmitFail = (e) => {
    const [, , xhr] = e.detail;

    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = xhr.response;
    }
    if (this.showStatusMessage) {
      Snackbar.show({ text: this.errorMessage, actionTextColor: ERROR_COLOR });
    }
  };

  get successMessage() {
    return this.successMessageValue || DEFAULT_SUCCESS_MESSAGE;
  }

  get errorMessage() {
    return this.errorMessageValue || DEFAULT_ERROR_MESSAGE;
  }

  get showStatusMessage() {
    if (
      typeof this.showStatusMessageValue === 'undefined' ||
      this.showStatusMessageValue === null
    ) {
      return true;
    }
    return this.showStatusMessageValue;
  }
}

function getProgressBar(id) {
  return `
  <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
    <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
    <span class="direct-upload__filename"></span>
  </div>
  `;
}
