import { Controller } from '@hotwired/stimulus';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['score'];

  onChange = (e) => {
    const {
      target: { value },
    } = e;

    Rails.fire(this.element, 'submit');
  };

  onSaveSuccess = (e) => {
    const [res, status, xhr] = e.detail;
    const {
      judge_score: { score, total },
    } = res;
    this.scoreTarget.value = score;
    this.data.set('score', score);
    Snackbar.show({ text: 'The score has been updated!' });

    this.element.dispatchEvent(
      new CustomEvent('voting-cap:update', {
        bubbles: true,
        detail: {
          totalPointsAssigned: total,
        },
      }),
    );
  };

  onSaveFail = (e) => {
    const [res, status, xhr] = e.detail;

    this.scoreTarget.value = this.data.get('score');
    const { errors } = res;

    errors.forEach((text) => {
      Snackbar.show({
        text,
        actionTextColor: ERROR_COLOR,
        duration: 5000,
      });
    });
  };
}
