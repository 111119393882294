import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';

export default class extends Controller {
  static targets = ['form', 'createShareLink', 'errors'];

  onFormLoaded = (event) => {
    const [, , xhr] = event.detail;

    this.formTarget.innerHTML = xhr.response;
    this.createShareLinkTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');
  };

  onCancel = (event) => {
    event.preventDefault();

    this.createShareLinkTarget.classList.remove('hidden');
    this.formTarget.classList.add('hidden');
  };

  onSaveSuccess = (event) => {
    event.preventDefault();
    const [, , xhr] = event.detail;
    this.element.innerHTML = xhr.response;
    Snackbar.show({ text: 'We updated your share settings!' });
  };

  onSaveFail = (event) => {
    event.preventDefault();
    const [, , xhr] = event.detail;
    console.log('save fail');
    this.errorsTarget.innerHTML = xhr.response;
  };
}
