import Dropdown from '@stimulus-components/dropdown';
import { computePosition } from '@floating-ui/dom';

export default class extends Dropdown {
  static values = {
    placement: String,
  };

  static targets = ['trigger'];

  connect() {
    super.connect();
  }

  get placement() {
    return this.placementValue || 'bottom-end';
  }

  toggle(event) {
    computePosition(this.triggerTarget || this.element, this.menuTarget, {
      placement: this.placement,
    }).then(({ x, y }) => {
      Object.assign(this.menuTarget.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
    super.toggle(event);
  }

  hide(event) {
    super.hide(event);
  }
}
