import { Controller } from '@hotwired/stimulus';

const checkedClass = ['z-10', 'border-blue-200', 'bg-blue-50'];
const uncheckedClass = ['border-gray-200'];

export default class extends Controller {
  static targets = ['input'];

  connect() {
    document.addEventListener('radio:change', this.onRadioChange);
  }

  disconnect() {
    document.removeEventListener('radio:change', this.onRadioChange);
  }

  onRadioChange = (e) => {
    const {
      detail: { value },
    } = e;

    if (this.inputTarget.value === value) {
      this.element.classList.add(...checkedClass);
      this.element.classList.remove(...uncheckedClass);
    } else {
      this.element.classList.remove(...checkedClass);
      this.element.classList.add(...uncheckedClass);
    }
  };

  onChange(e) {
    const {
      target: { value, checked },
    } = e;

    document.dispatchEvent(
      new CustomEvent('radio:change', { detail: { value, checked } }),
    );
  }
}
