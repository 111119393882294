/* eslint no-param-reassign: 0 */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    id: String,
  };

  static targets = ['checkboxAll', 'checkbox'];

  connect() {
    this.refresh();
  }

  onToggle(e) {
    const {
      target: { checked },
    } = e;

    let values;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });

    if (checked) {
      values = this.checkedValues;
      console.log(values);
    } else {
      values = [];
    }
    document.dispatchEvent(
      new CustomEvent(this.eventName, {
        detail: {
          enabled: checked,
          values,
        },
      }),
    );
  }

  onChange = () => {
    this.refresh();
  };

  refresh() {
    const checkboxCount = this.checkboxTargets.length;
    const checkedCount = this.checked.length;

    const hasCheckedItems = checkedCount > 0;
    this.checkboxAllTarget.checked = hasCheckedItems;
    this.checkboxAllTarget.indeterminate =
      hasCheckedItems && checkedCount < checkboxCount;

    document.dispatchEvent(
      new CustomEvent(this.eventName, {
        detail: {
          enabled: hasCheckedItems,
          values: this.checkedValues,
        },
      }),
    );
  }

  get checkedValues() {
    return this.checked.map((checkbox) => checkbox.value);
  }

  get eventName() {
    if (this.idValue) {
      return `${this.idValue}:toggle`;
    }
    return 'toggle';
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked() {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
