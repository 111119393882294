import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  $textInput = null;

  $checkInput = null;

  $textInputValue = false;

  connect() {
    this.$textInput = $(this.element).find("input[type='text']");
    this.$checkInput = $(this.element).find("input[type='checkbox']");

    this.updateInput();

    this.$checkInput.on('change', this.handleCheckInputChange);
  }

  disconnect() {
    this.$checkInput.off('change', this.handleCheckInputChange);
  }

  handleCheckInputChange = () => {
    this.updateInput();
  }

  updateInput() {
    const val = this.$checkInput.is(':checked');
    this.$textInput.attr('disabled', val);

    if (val) {
      this.$textInputValue = this.$textInput.val();
      this.$textInput.val('');
      return;
    }

    if (this.$textInputValue !== false) {
      this.$textInput.val(this.$textInputValue);
      this.$textInputValue = false;
    }
  }
}
