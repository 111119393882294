import React, { Component } from 'react';
import classnames from 'classnames';
import { InformationCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import Alert from './Alert';
import Spinner from './Spinner';
import { mapRailsErrorsHash, errorsHashToArray } from '../utils/errors';

export default class CreateVentureForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      errors: {},
      saveInProgress: false,
      ventureLink: null,
    };
  }

  getValidationClass(field) {
    const defaultClassName = {
      'form-group': true,
      'mb-4': true,
    };

    const { errors } = this.state;

    if (errors[field]) {
      return {
        ...defaultClassName,
        'error-wrapper': true,
      };
    }
    return {
      ...defaultClassName,
    };
  }

  handleAlertClose = () => this.setState({ errors: {} });

  handleSave = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const { title } = prevState;
      const errors = this.validate(prevState);
      let saveInProgress = false;
      if (Object.keys(errors).length === 0) {
        saveInProgress = true;
        createVenture({
          title,
        })
          .then((res) => {
            const {
              data: { url: ventureLink },
            } = res;

            this.setState({
              ventureLink,
            });
            this.hideLoadingIndicator();
            setTimeout(() => {
              window.location.href = ventureLink;
            }, 5000);
          })
          .catch((err) => {
            const {
              response: {
                data: { errors: errorsHash },
              },
            } = err;
            this.hideLoadingIndicator();
            this.setState({
              errors: mapRailsErrorsHash(errorsHash),
            });
          });
      }
      console.log(errors);

      return {
        errors,
        saveInProgress,
      };
    });
  };

  handleFieldChange = (field) => (e) =>
    this.setState({ [field]: e.target.value });

  hideLoadingIndicator() {
    this.setState({
      saveInProgress: false,
    });
  }

  validate(currentState) {
    const { title } = currentState;
    const errors = {};
    if (!title || title.length < 3) {
      errors.title = ['The venture name must be at least 3 characters long'];
    }

    return errors;
  }

  renderVenturePrompt() {
    const { ventureLink } = this.state;

    return (
      <div className="bg-grey-lightest mb-4 p-4 border-t-4 border-brand-blue border-solid flex mb-4">
        <InformationCircleIcon className="w-5 h-5 mr-2 mt-1" />
        <div>
          <p className="text-grey-darkest mb-0">
            Now go fill out your venture details&nbsp;
            <a href={ventureLink}>here</a>
            &nbsp;in order to complete your application.
          </p>
        </div>
      </div>
    );
  }

  renderErrors() {
    const { errors: currentErrors } = this.state;

    const errorValues = Object.values(currentErrors);
    if (errorValues.length === 0) {
      return null;
    }

    const errors = errorsHashToArray(currentErrors);

    return (
      <div className="mb-4">
        <Alert
          type="error"
          title="There were problems creating your venture"
          onClose={this.handleAlertClose}
        >
          <ul>
            {errors.map((msg) => (
              <li key={msg}>{msg}</li>
            ))}
          </ul>
        </Alert>
      </div>
    );
  }

  renderVentureForm() {
    const { saveInProgress, title } = this.state;

    return (
      <div className="bg-grey-lightest mb-4 p-4 border-t-4 border-brand-blue border-solid">
        <div className="flex mb-4">
          <InformationCircleIcon className="w-5 h-5 mr-2 mt-1" />
          <div>
            <p className="text-grey-darkest mb-0">
              In order to apply to this competition, you will need to first
              create a venture
            </p>
            <p className="text-grey-darkest mb-0">Let&apos;s get started now</p>
          </div>
        </div>
        <div className={classnames(this.getValidationClass('title'))}>
          <label htmlFor="project_title" className="form-label mb-2">
            What is the name of your venture?
          </label>
          <input
            type="text"
            id="project_title"
            className="form-field"
            name="project[title]"
            placeholder="Enter the name of your venture"
            value={title}
            onChange={this.handleFieldChange('title')}
          />
        </div>
        {this.renderErrors()}

        <div className="form-actions flex justify-end">
          <button
            type="button"
            className="btn btn-primary flex items-center"
            disabled={saveInProgress}
            onClick={this.handleSave}
          >
            {saveInProgress && (
              <div className="flex items-center">
                <Spinner className="h-5 w-5 mr-2" />
                <span>Creating Venture...</span>
              </div>
            )}
            {!saveInProgress && <span>Create Venture</span>}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { ventureLink } = this.state;

    if (ventureLink) {
      return this.renderVenturePrompt();
    }
    return this.renderVentureForm();
  }
}

function createVenture(data) {
  const url = '/projects.json';
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
  });
}
