import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'field',
    'submit',
  ];

  connect() {
    $(this.fieldTarget).on('change', () => {
      $(this.submitTarget).click();
    });
  }

}