/* global ActionCable */
export default class NotificationsCable {
  static sharedInstance = null;

  static shared() {
    if (!this.sharedInstance) {
      this.sharedInstance = new NotificationsCable();
    }

    return this.sharedInstance;
  }

  constructor() {
    this.connect();
  }

  cable = false;

  connect() {
    this.cable = ActionCable.createConsumer();
  }
}
