import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = [
    'currentlyAttendCheckbox',
    'endYearField',
    'endMonthField',
  ]

  connect() {
    $(this.currentlyAttendCheckboxTarget).on('change', this.handleCurrentCheckChange);
    this.handleCurrentCheckChange();
  }

  disconnect() {

  }

  handleCurrentCheckChange = () => {
    const { checked } = this.currentlyAttendCheckboxTarget;

    this.endYearFieldTarget.disabled = checked;
    this.endMonthFieldTarget.disabled = checked;

    if (checked) {
      this.endYearFieldTarget.value = '';
      this.endMonthFieldTarget.value = '';
    }
  }
}
