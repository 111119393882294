import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DirectUpload } from '@rails/activestorage';
import { DocumentIcon } from '@heroicons/react/20/solid';
import { ERROR_COLOR } from '../../../constants/colors';

import ProgressBar from '../../ProgressBar';

const imageExtensions = ['jpg', 'png', 'bmp', 'jpeg'];

export default class FileUploadQuestion extends Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.blobRef = React.createRef();
    const state = {
      uploadingPending: false,
      percentComplete: 0,
      blob: {
        signed_id: null,
        filename: null,
      },
      signedUrl: null,
    };

    const {
      answer: { attachment_url, attachment_blob },
    } = props;
    if (attachment_url) {
      state.signedUrl = attachment_url;
      state.blob = { filename: attachment_blob.filename };
    }
    this.state = state;
  }

  handleToggleNotApplicable = (e) => {
    const {
      target: { checked },
    } = e;
    this.props.onToggleNotApplicable(checked);
  };

  handleChange = (e) => {
    Array.from(e.target.files).forEach((file) => {
      this.uploadFile(file);
    });
  };

  handleDirectUploadDidProgress = (e) => {
    const { lengthComputable } = e;
    if (lengthComputable) {
      const { loaded, total } = e;
      const percentComplete = Math.round((loaded / total) * 100 * 100) / 100;
      console.log('loaded: ', loaded);
      console.log('total: ', total);
      console.log('percent complete: ', percentComplete);
      this.setState({
        percentComplete,
      });
    }
  };

  removeProgressListener() {
    if (this.request) {
      this.request.upload.removeEventListener(
        'progress',
        this.handleDirectUploadDidProgress,
      );
      this.request = null;
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    this.removeProgressListener();
    this.request = request;
    this.request.upload.addEventListener(
      'progress',
      this.handleDirectUploadDidProgress,
    );
  }

  uploadFile(file) {
    const url = this.props.directUploadUrl;
    const upload = new DirectUpload(file, url, this);

    this.setState(
      {
        uploadingPending: true,
        percentComplete: 0,
      },
      () => {
        upload.create((error, blob) => {
          this.removeProgressListener();
          if (error) {
            Snackbar.show({
              text: 'There was a problem uploading the file',
              actionTextColor: ERROR_COLOR,
            });
          } else {
            const { signed_id, filename } = blob;
            this.fileRef.current.value = '';
            this.blobRef.current.value = signed_id;
            const signedUrl = getBlobServiceUrl({ signed_id, filename });
            this.setState({
              uploadingPending: false,
              blob,
              signedUrl,
            });

            this.props.onAnswerChange(blob);
          }
        });
      },
    );
  }

  renderProgressBar() {
    const { uploadingPending, percentComplete } = this.state;

    if (!uploadingPending) {
      return null;
    }

    return (
      <div className="mt-4">
        <ProgressBar percent={percentComplete} />
      </div>
    );
  }

  renderPreview() {
    const {
      blob: { filename },
      signedUrl,
    } = this.state;

    if (!filename || !signedUrl) {
      return null;
    }

    const extension = filename.split('.').pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
      return (
        <img className="sm:w-64 w-full h-auto" src={signedUrl} alt={filename} />
      );
    }

    return (
      <div className="mt-4">
        <a href={signedUrl} target="_blank" rel="noopener noreferrer">
          <div className="flex items-center space-x-2">
            <span className="p-2 text-blue-700 rounded bg-blue-50">
              <DocumentIcon className="w-5 h-5" />
            </span>
            <span>{filename}</span>
          </div>
        </a>
      </div>
    );
  }

  render() {
    const { index, validationStatus } = this.props;

    const finalClassName = {
      'form-group': true,
      'mb-4': true,
    };

    if (this.props.className) {
      finalClassName[this.props.className] = true;
    }

    if (validationStatus && !validationStatus.success) {
      finalClassName['error-wrapper'] = true;
    }

    const {
      question: { title },
      answer: { not_applicable },
    } = this.props;

    const fieldName = `competition_application[answers_attributes][${index}][attachment]`;
    const naFieldName = `competition_application[answers_attributes][${index}][not_applicable]`;
    const {
      blob: { signed_id },
    } = this.state;

    return (
      <div className="mb-4">
        <label className="mb-2 form-label" htmlFor={fieldName}>
          {title}
        </label>
        <div className="flex items-center space-x-3">
          <div>
            <input
              type="file"
              multiple={false}
              onChange={this.handleChange}
              ref={this.fileRef}
              disabled={not_applicable}
            />

            <input
              type="hidden"
              name={fieldName}
              ref={this.blobRef}
              value={signed_id || ''}
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              className="mb-0"
              id={naFieldName}
              value="not_applicable"
              onChange={this.handleToggleNotApplicable}
              checked={not_applicable}
            />
            <label className="ml-2 text-sm" htmlFor={naFieldName}>
              N/A
            </label>
          </div>
        </div>

        {this.renderProgressBar()}
        {this.renderPreview()}
      </div>
    );
  }
}

FileUploadQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  validationStatus: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
  }),
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  answer: PropTypes.shape({
    data: PropTypes.string,
    attachment: PropTypes.string,
    not_applicable: PropTypes.bool,
  }),
  className: PropTypes.string,
  onAnswerChange: PropTypes.func.isRequired,
  onToggleNotApplicable: PropTypes.func.isRequired,
};

function getBlobServiceUrl(blob) {
  const { filename, signed_id } = blob;
  return '/rails/active_storage/blobs/:signed_id/:filename'
    .replace(':signed_id', signed_id)
    .replace(':filename', filename);
}
