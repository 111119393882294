import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  onSubmitSuccess() {
    const error_response = $('.error-message-feedback__response');
    const error_intro = $('.error-message-feedback__intro');

    if (error_response.hasClass('hide')) {
      error_response.removeClass('hide');
    }

    if (!error_intro.hasClass('hide')) {
      error_intro.addClass('hide');
    }
  }

  onSubmitFail() {

  }
}
