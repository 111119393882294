import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { modal: String };

  launch(e) {
    e.preventDefault();
    const dialog = document.getElementById(this.modalValue);

    if (dialog) {
      const dialogController =
        this.application.getControllerForElementAndIdentifier(dialog, 'dialog');
      dialogController.open();
    }
  }
}
