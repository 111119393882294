import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.select2mount();
    document.addEventListener('turbolinks:before-cache', () => {
      this.select2unmount();
    }, { once: true });
  }

  disconnect() {
    this.select2unmount();
  }

  select2mount() {
    const url = this.data.get('url');
    const dropdownParent = this.data.get('parent');
    if (!url) {
      throw new Error('The url field is missing');
    }

    const placeholder = $(this.element).attr('placeholder') || 'Please start typing to search for a venture';
    const options = {
      minimumInputLength: 3,
      ajax: {
        url,
        dataType: 'json',
        processResults(data) {
          const results = (data || []).map(item => ({
            ...item,
            text: item.title,
          }));
          return { results };
        },
      },
      quietMillis: 400,
      placeholder,
      templateResult: formatOption,
      width: '100%',
    };

    if (dropdownParent) {
      const filteredId = dropdownParent.startsWith('#') ? dropdownParent.slice(1) : dropdownParent;
      options.dropdownParent = $(`#${filteredId}`);
    }

    $(this.element).select2(options);
  }

  select2unmount() {
    $(this.element).select2('destroy');
  }
}


function formatOption(option) {
  return $(option.template);
}
