import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'promoteButton',
    'passButton',
  ];

  onPromoteSuccess() {
    this.promoteButtonTarget.classList.remove('text-gray-300', 'border', 'border-gray-200');
    this.promoteButtonTarget.classList.add('text-gray-900', 'border-2', 'border-gray-500');
    this.passButtonTarget.classList.add('text-gray-300', 'border', 'border-gray-200');
    this.passButtonTarget.classList.remove('text-gray-900', 'border-2', 'border-gray-500');
    Snackbar.show({ text: 'You gave the application a thumbs-up!' });
  }

  onPassSuccess() {
    this.promoteButtonTarget.classList.add('text-gray-300', 'border', 'border-gray-200');
    this.promoteButtonTarget.classList.remove('text-gray-900', 'border-2', 'border-gray-500');
    this.passButtonTarget.classList.add('text-gray-900', 'border-2', 'border-gray-500');
    this.passButtonTarget.classList.remove('text-gray-300', 'border', 'border-gray-200');
    Snackbar.show({ text: 'You gave the application a thumbs-down!' });
  }
}
