import { Controller } from '@hotwired/stimulus';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onLoadSuccess(e) {
    const [_response, _status, xhr] = e.detail;

    document.dispatchEvent(
      new CustomEvent('due-diligence-modal-update', {
        detail: xhr.response,
      }),
    );
  }

  onLoadFail() {
    Snackbar.show({
      text: 'There was a problem loading the due diligence',
      actionTextColor: ERROR_COLOR,
    });
  }
}
