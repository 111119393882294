import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class extends Component {
  static propTypes = {
    voting_cap: {
      total_points_per_judge: PropTypes.number,
      application_max_points_per_judge: PropTypes.number,
    },
    errors: PropTypes.shape({
      total_points_per_judge: PropTypes.string,
      application_max_points_per_judge: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    voting_cap: {
      total_points_per_judge: null,
      application_max_points_per_judge: null,
    },
    errors: {},
  }

  handleFieldChange = field => (e) => {
    const {
      target: {
        value,
      },
    } = e;
    this.props.onChange(field, value);
  }

  renderTotalPoints() {
    const {
      voting_cap: {
        total_points_per_judge,
      },
      errors,
    } = this.props;

    const className = classnames({
      'md:w-1/2': true,
      'w-1/2': true,
      'error-wrapper': Object.keys(errors).includes('total_points_per_judge'),
    });

    return (
      <div className={className}>
        <div className="mx-6">
          <label
            className="mb-2"
            htmlFor=""
          >
            Total number of point assigned to a judge:
          </label>
          <input
            type="number"
            min="1"
            step="1"
            value={total_points_per_judge}
            onChange={this.handleFieldChange('total_points_per_judge')}
          />
        </div>
      </div>
    );
  }

  renderApplicationMaxPoints() {
    const {
      voting_cap: {
        application_max_points_per_judge,
      },
      errors,
    } = this.props;

    const className = classnames({
      'md:w-1/2': true,
      'w-1/2': true,
      'error-wrapper': Object.keys(errors).includes('application_max_points_per_judge'),
    });

    return (
      <div className={className}>
        <div className="form-group mb-4 mx-6">
          <label
            className="mb-2"
            htmlFor=""
          >
            Total number of points a judge can assign to a startup:
          </label>
          <input
            type="number"
            min="1"
            step="1"
            value={application_max_points_per_judge}
            onChange={this.handleFieldChange('application_max_points_per_judge')}
          />
        </div>
      </div>

    );
  }

  render() {
    const {
      voting_cap: {
        total_points_per_judge,
        application_max_points_per_judge,
      },
    } = this.props;
    return (
      <div className="">
        <h4 className="text-xl mb-4 mx-6">Voting Cap Settings</h4>
        <section className="flex flex-wrap">
          {this.renderTotalPoints()}
          {this.renderApplicationMaxPoints()}
        </section>
      </div>
    );
  }
}
