import { Controller } from '@hotwired/stimulus';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['form', 'error', 'comments', 'commentField'];

  onBefore(e) {
    const comment = this.commentFieldTarget.value.trim();

    if (!comment) {
      e.preventDefault();
    }
  }

  onCreateSuccess(e) {
    const [response, status, xhr] = e.detail;

    this.commentFieldTarget.value = '';
    this.commentsTarget.innerHTML = xhr.response;
  }

  onCreateFail(e) {
    const [response, status, xhr] = e.detail;

    this.errorTarget.innerHTML = xhr.response;
  }

  onDeleteSuccess(e) {
    const [response, status, xhr] = e.detail;

    this.commentsTarget.innerHTML = xhr.response;
  }

  onDeleteFail() {
    Snackbar.show({
      text: 'There was a problem deleting the comment',
      actionTextColor: ERROR_COLOR,
    });
  }
}
