import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['thanks', 'form', 'message'];

  connect() {
    document.addEventListener('feedback-modal:closed', this.onClosed);
  }

  disconnect() {
    document.removeEventListener('feedback-modal:closed', this.onClosed);
  }

  onClosed = () => {
    this.messageTarget.value = '';
    this.formTarget.classList.remove('hidden');
    this.thanksTarget.classList.add('hidden');
  };

  close = () => {
    document.dispatchEvent(
      new CustomEvent('feedback-modal:close', {
        detail: {},
      }),
    );
  };

  onSubmit = () => {
    this.messageTarget.value = '';
    this.formTarget.classList.add('hidden');
    this.thanksTarget.classList.remove('hidden');
  };
}
