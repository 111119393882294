import { Controller } from '@hotwired/stimulus';
import ConfirmModalEvents from '../constants/events/confirm-modal';

const CONFIRM_ACTIONS = {
  promote: 'promote-selected-confirmed',
  pass: 'pass-selected-confirmed',
  archive: 'archive-selected-confirmed',
};

export default class extends Controller {
  static targets = [
    'exportSelectedButton',
    'bulkApproveButton',
    'bulkPassButton',
    'bulkArchiveButton',
    'bulkEmailLink',
    'competitionApplications',
    'competitionApplicationsForExport',
    'competitionApplicationsForPass',
    'competitionApplicationsForArchive',
    'checkbox',
    'form',
    'formForPass',
    'formForArchive',
    'formForExport',
    'selectAll',
    'applications',
    'sortForm',
  ];

  connect() {
    document.addEventListener(
      CONFIRM_ACTIONS.promote,
      this.onBulkApproveConfirmed,
    );
    document.addEventListener(CONFIRM_ACTIONS.pass, this.onBulkPassConfirmed);
    document.addEventListener(
      CONFIRM_ACTIONS.archive,
      this.onBulkArchiveConfirmed,
    );
  }

  disconnect() {
    document.removeEventListener(
      CONFIRM_ACTIONS.promote,
      this.onBulkApproveConfirmed,
    );
    document.removeEventListener(
      CONFIRM_ACTIONS.pass,
      this.onBulkPassConfirmed,
    );
  }

  onSort = () => {
    this.sortFormTarget.submit();
  };

  onBulkActionSuccess = (html) => {
    this.applicationsTarget.innerHTML = html;
    this.selectedApplications = [];
    this.toggleButtons(false);
    this.updateBulkEmailLinkParams([]);
  };

  onBulkPassConfirmed = () => {
    const selected = this.selectedApplications;
    this.competitionApplicationsForPassTarget.value = selected;
    Rails.fire(this.formForPassTarget, 'submit');
  };

  onBulkApproveConfirmed = () => {
    const selected = this.selectedApplications;
    this.competitionApplicationsTarget.value = selected;
    Rails.fire(this.formTarget, 'submit');
  };

  onBulkArchiveConfirmed = () => {
    const selected = this.selectedApplications;
    this.competitionApplicationsForArchiveTarget.value = selected;
    Rails.fire(this.formForArchiveTarget, 'submit');
  };

  onBulkPassSuccess = (e) => {
    const [, , xhr] = e.detail;
    this.onBulkActionSuccess(xhr.response);
    Snackbar.show({ text: 'The selected applications have been passed.' });
  };

  onBulkApproveSuccess = (e) => {
    const [, , xhr] = e.detail;
    this.onBulkActionSuccess(xhr.response);
    Snackbar.show({ text: 'The selected applications have been promoted.' });
  };

  onBulkArchiveSuccess = (e) => {
    const { params } = e;

    const [, , xhr] = e.detail;
    const archiveType = params.archiveType || 'archive';
    this.onBulkActionSuccess(xhr.response);
    if (archiveType === 'archive') {
      Snackbar.show({ text: 'The selected applications have been archived.' });
    } else {
      Snackbar.show({
        text: 'The selected applications have been unarchived.',
      });
    }
  };

  onSelectAllToggled = (e) => {
    const {
      target: { checked },
    } = e;

    const updatedSelection = [];
    [].forEach.call(this.checkboxTargets, (el, i) => {
      /* eslint no-param-reassign: 0 */
      el.checked = checked;
      const applicationId = parseInt(el.value, 10);

      if (checked) {
        updatedSelection.push(applicationId);
      }
    });
    this.selectedApplications = updatedSelection;

    const selectedCount = updatedSelection.length;
    const bulkActionsEnabled = selectedCount > 0;

    this.toggleButtons(bulkActionsEnabled);
    this.updateBulkEmailLinkParams(updatedSelection);
  };

  onToggleSelection = (e) => {
    const { target } = e;

    const { checked } = target;

    const applicationId = parseInt(target.value, 10);

    const currentSelection = this.selectedApplications;
    let updatedSelection;
    if (checked) {
      updatedSelection = [...currentSelection, applicationId];
    } else {
      const index = currentSelection.findIndex((s) => s === applicationId);
      updatedSelection = [
        ...currentSelection.slice(0, index),
        ...currentSelection.slice(index + 1),
      ];
    }

    this.selectedApplications = updatedSelection;
    const selectedCount = updatedSelection.length;
    const checkboxCount = this.checkboxTargets.length;
    this.selectAllTarget.checked = checkboxCount === selectedCount;

    const bulkActionsEnabled = selectedCount > 0;

    this.toggleButtons(bulkActionsEnabled);
    this.updateBulkEmailLinkParams(updatedSelection);
  };

  onExportSelected() {
    console.log('export selected');
    const selected = this.selectedApplications;
    this.competitionApplicationsForExportTarget.value = selected;
    this.formForExportTarget.submit();
  }

  onShowBulkApproveModal() {
    document.dispatchEvent(
      new CustomEvent('confirm-promote-modal-open', {
        bubbles: true,
      }),
    );
  }

  onShowBulkPassModal() {
    document.dispatchEvent(
      new CustomEvent('confirm-pass-modal-open', {
        bubbles: true,
      }),
    );
  }

  onShowBulkArchiveModal() {
    document.dispatchEvent(
      new CustomEvent('confirm-archive-modal-open', {
        bubbles: true,
      }),
    );
  }

  onBulkEmailFormLoaded(e) {
    const [data, status, xhr] = e.detail;
    // set the modal content
    document.dispatchEvent(
      new CustomEvent('bulk-email-modal-update', {
        bubbles: true,
        detail: xhr.response,
      }),
    );
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent('bulk-email-modal-open', {
          bubbles: true,
        }),
      );
    }, 0);
  }

  updateButtonStates() {
    const bulkActionsEnabled = this.selectedApplications.length > 0;
    this.toggleButtons(bulkActionsEnabled);
    this.updateBulkEmailLinkParams(this.selectedApplications);
  }

  toggleButtons(toggle) {
    if (toggle) {
      if (this.hasExportSelectedButtonTarget) {
        this.exportSelectedButtonTarget.disabled = false;
      }
      if (this.hasBulkApproveButtonTarget) {
        this.bulkApproveButtonTarget.disabled = false;
      }

      if (this.hasBulkPassButtonTarget) {
        this.bulkPassButtonTarget.disabled = false;
      }

      if (this.hasBulkEmailLinkTarget) {
        this.bulkEmailLinkTarget.classList.remove('disabled');
      }

      if (this.hasBulkArchiveButtonTarget) {
        this.bulkArchiveButtonTarget.disabled = false;
      }
    } else {
      if (this.hasBulkApproveButtonTarget) {
        this.bulkApproveButtonTarget.disabled = true;
      }

      if (this.hasBulkPassButtonTarget) {
        this.bulkPassButtonTarget.disabled = true;
      }

      if (this.hasBulkArchiveButtonTarget) {
        this.bulkArchiveButtonTarget.disabled = true;
      }
      if (this.hasBulkEmailLinkTarget) {
        this.bulkEmailLinkTarget.classList.add('disabled');
      }
    }
  }

  updateBulkEmailLinkParams(selection = []) {
    if (this.hasBulkEmailLinkTarget) {
      const fullLink = this.bulkEmailLinkTarget.href;
      const index = fullLink.indexOf('?');
      let baseUrl;
      if (index !== -1) {
        baseUrl = this.bulkEmailLinkTarget.href.substr(0, index);
      } else {
        baseUrl = fullLink;
      }

      this.bulkEmailLinkTarget.href = `${baseUrl}?competition_application_ids=${selection.join(
        ',',
      )}`;
    }
  }

  set selectedApplications(selected = []) {
    this.data.set('selected', JSON.stringify({ selected }));
  }

  get selectedApplications() {
    const data = JSON.parse(this.data.get('selected')) || {};
    const selected = data.selected || [];
    return selected.map((s) => parseInt(s, 10));
  }
}
