import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [
    'street',
    'city',
    'streetNumber',
    'route',
    'postalCode',
    'country',
    'county',
    'state',
    'longitude',
    'latitude',
    'addressId',
  ];

  static values = {
    current_address: Object,
    country: Array,
  };

  initialize() {
    this.placeChanged = this.placeChanged.bind(this);
  }

  connect() {
    if (typeof google !== 'undefined') {
      this.initAutocomplete();
    }
  }

  disconnect() {
    if (typeof google !== 'undefined') {
      google.map.event.clearListeners(this.streetTarget, 'place_changed');
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.streetTarget,
      this.autocompleteOptions,
    );

    this.autocomplete.addListener('place_changed', this.placeChanged);
  }

  placeChanged() {
    this.place = this.autocomplete.getPlace();
    const addressComponents = this.place.address_components;

    if (addressComponents !== undefined) {
      const formattedAddress = this.formatAddressComponents(addressComponents);

      this.setAddressComponents(formattedAddress);
    }

    if (this.place.geometry !== undefined) {
      this.setGeometry(this.place.geometry);
    }
  }

  async checkAddress(address) {
    const url = `/addresses?${new URLSearchParams(address).toString()}`;
    try {
      const { data: existingAddress } = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
            .content,
        },
      });

      if (this.hasAddressIdTarget) {
        this.addressIdTarget.value = existingAddress.id;
      }
    } catch (e) {
      if (this.hasAddressIdTarget) {
        this.addressIdTarget.value = '';
      }
    }
  }

  setAddressComponents(address) {
    const streetNumber = address.street_number || '';
    const route = address.route || '';
    const city = address.locality || '';
    const state = address.administrative_area_level_1 || '';
    const county = address.administrative_area_level_2 || '';
    const postalCode = address.postal_code || '';
    const country = address.country || '';

    this.checkAddress({
      street_number: streetNumber,
      route,
      city,
      state,
      postal_code: postalCode,
      country,
    });

    if (this.hasStreetTarget) {
      this.streetTarget.value = [streetNumber, route].join(' ');
    }
    if (this.hasStreetNumberTarget)
      this.streetNumberTarget.value = streetNumber;
    if (this.hasRouteTarget) this.routeTarget.value = route;
    if (this.hasCityTarget) this.cityTarget.value = city;
    if (this.hasCountyTarget) this.countyTarget.value = county;
    if (this.hasStateTarget) this.stateTarget.value = state;
    if (this.hasCountryTarget) this.countryTarget.value = country;
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = postalCode;
  }

  setGeometry(geometry) {
    if (this.hasLongitudeTarget)
      this.longitudeTarget.value = geometry.location.lng().toString();
    if (this.hasLatitudeTarget)
      this.latitudeTarget.value = geometry.location.lat().toString();
  }

  get autocompleteOptions() {
    return {
      fields: ['address_components', 'geometry'],
      componentRestrictions: {
        country: this.countryValue,
      },
    };
  }

  preventSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {};

    addressComponents.forEach((component) => {
      const type = component.types[0];

      data[type] = component.long_name;
    });

    return data;
  }
}
