import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onToggleFavoriteSuccess(e) {
    const [response, status, xhr] = e.detail;
    this.element.parentNode.innerHTML = xhr.response;
  }

  onToggleFavoriteFail() {
    Snackbar.show({
      text: 'There was a problem toggling the favorite',
      actionTextColor: ERROR_COLOR,
    });
  }
}
