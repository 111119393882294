import { Controller } from '@hotwired/stimulus';
import {
  dasherize,
} from 'underscore.string';

export default class extends Controller {
  connect() {
    document.addEventListener('project:updated', this.onSectionUpdated);
  }

  disconnect() {
    document.removeEventListener('project:updated', this.onSectionUpdated);
  }

  onSectionLoadSuccess(e) {
    const [, , xhr] = e.detail;

    document.dispatchEvent(new CustomEvent(
      'edit-venture-modal-update',
      {
        detail: xhr.response,
      },
    ));
  }

  onSectionLoadFail() {
    Snackbar.show({ text: 'There was a problem loading the project section' });
  }

  onSectionUpdated(e) {
    const {
      key,
      html,
    } = e.detail;

    if (key) {
      const id = `#${dasherize(key)}-section`;
      const element = document.querySelector(id);
      if (element) {
        element.innerHTML = html;
      }
      Snackbar.show({ text: 'The project has been updated' });
    }

    document.dispatchEvent(
      new CustomEvent(
        'edit-venture-modal-close',
        {},
      ),
    );

    const refreshRequirementsEvent = new CustomEvent('refresh:requirements');
    document.dispatchEvent(refreshRequirementsEvent);
  }
}
