import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  onFormLoadSuccess(e) {
    const [
      response,
      status,
      xhr,
    ] = e.detail;

    document.dispatchEvent(
      new CustomEvent(
        'portfolio-job-modal-update',
        {
          detail: xhr.response,
        },
      ),
    );
  }

  onFormLoadFail(e) {
    Snackbar.show({ text: 'There was a problem loading the form' });
  }
}
