import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


function getClassName(className = {}) {
  return classnames({
    'w-12': true,
    'h-12': true,
    'rounded-full': true,
    'font-bold': true,
    'bg-blue-lighter': true,
    'text-white': true,
    flex: true,
    'justify-center': true,
    'items-center': true,
    ...className,
  });
}

const CircleText = ({
  className,
  children,
}) => (
  <span className={getClassName(className)}>
    {children}
  </span>
);

CircleText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.object,
};

CircleText.defaultProps = {
  className: {},
};

export default CircleText;
