import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  static values = {
    enableTime: Boolean,
    minDate: String,
  };

  static targets = ['input'];

  connect() {
    document.addEventListener('turbolinks:before-cache', this.unmount);
    this.mount();
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.unmount);
    this.unmount();
  }

  mount = () => {
    const enableTime = this.enableTimeValue;
    const minDate = this.minDateValue;

    const options = { enableTime };
    if (minDate) {
      options.minDate = minDate;
    }

    if (!this.datepicker) {
      this.datepicker = flatpickr(this.inputTarget, options);
    }
  };

  unmount = () => {
    if (this.datepicker) {
      this.datepicker.destroy();
      this.datepicker = null;
    }
  };

  onClear = (e) => {
    e.preventDefault();
    this.datepicker.clear();
  };
}
