import { Controller } from '@hotwired/stimulus';
import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  static targets = [
    'loading',
    'tabs',
    'tab',
    'tabContent',
  ];

  connect() {
    document.addEventListener('portfolio-company-form-cancel', this.onCancelForm);
  }

  disconnect() {
    document.removeEventListener('portfolio-company-form-cancel', this.onCancelForm);
  }

  onCancelForm = (e) => {
    e.preventDefault();
    this.resetTabs();

    document.dispatchEvent(new CustomEvent(
      'portfolio-company-form-modal-close',
      {
        detail: {},
      },
    ));
  }

  onBeforeTabContentLoad(e) {
    const {
      currentTarget,
    } = e;

    if (Array.from(currentTarget.classList).includes('active')) {
      e.preventDefault();
      return;
    }
    this.tabTargets.forEach((tabTarget) => {
      tabTarget.classList.remove(
        'active',
        'border-brand-blue',
        'text-brand-blue',
      );
      tabTarget.classList.add(
        'border-transparent',
        'text-blue-dark',
      );
    });

    currentTarget.classList.add(
      'active',
      'border-brand-blue',
      'text-brand-blue',
    );

    currentTarget.classList.remove(
      'border-transparent',
      'text-blue-dark',
    );

    this.showLoadingIndicator();
  }

  onTabContentLoadSuccess(e) {
    const [
      response,
      status,
      xhr,
    ] = e.detail;

    this.tabContentTarget.innerHTML = xhr.response;
    this.loadingTarget.classList.remove('hidden');
  }

  onTabContentLoadFail() {
    Snackbar.show({
      text: 'There was a problem loading the form',
      actionTextColor: ERROR_COLOR,
    });
  }

  onTabContentLoadComplete() {
    this.hideLoadingIndicator();
  }

  resetTabs() {
    this.tabTargets.forEach((tabTarget, index) => {
      tabTarget.classList.remove(
        'active',
        'border-brand-blue',
        'text-brand-blue',
      );
      tabTarget.classList.add(
        'border-transparent',
        'text-blue-dark',
      );

      if (index === 0) {
        tabTarget.classList.add(
          'active',
          'border-brand-blue',
          'text-brand-blue',
        );
      }
    });
  }

  showLoadingIndicator() {
    this.loadingTarget.classList.remove('hidden');
  }

  hideLoadingIndicator() {
    this.loadingTarget.classList.add('hidden');
  }
}
