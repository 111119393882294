import { Controller } from '@hotwired/stimulus';
import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  onSubmitSuccess(e) {
    Snackbar.show({ text: 'Thank you for applying to this position' });
  }

  onSubmitFail(e) {
    const [
      response,
      status,
      xhr,
    ] = e.detail;

    Snackbar.show({ text: 'There was a problem submitting your job application', actionTextColor: ERROR_COLOR });
  }
}
