import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    let mask;

    switch (this.data.get('type')) {
      case 'currency':
        mask = {
          alias: 'currency',
          rightAlign: false,
          unmaskAsNumber: true,
          autoUnmask: true,
          removeMaskOnSubmit: true,
          min: 0,
          clearMaskOnLostFocus: true,
        };
        break;
      case 'percent':
        mask = {
          alias: 'percentage',
          rightAlign: false,
          unmaskAsNumber: true,
          autoUnmask: true,
          removeMaskOnSubmit: true,
          placeholder: undefined,
        };
        break;
      case 'integer':
        mask = {
          alias: 'integer',
          groupSeparator: ',',
          autoGroup: true,
          rightAlign: false,
          unmaskAsNumber: true,
          autoUnmask: true,
          removeMaskOnSubmit: true,
          placeholder: undefined,
          max: 2147483647,
        };
        break;
      case 'bigint':
        mask = {
          alias: 'integer',
          groupSeparator: ',',
          autoGroup: true,
          rightAlign: false,
          unmaskAsNumber: true,
          autoUnmask: true,
          removeMaskOnSubmit: true,
          placeholder: undefined,
        };
        break;
      case 'email':
        mask = {
          alias: 'email',
        };
        break;
      case 'phone':
        mask = {
          mask: '+1 (999) 999-9999[ ext 9[9][9][9][9][9][9][9]]',
        };
        break;
      case 'number':
      default:
        mask = {
          alias: 'decimal',
          rightAlign: false,
          unmaskAsNumber: true,
          autoUnmask: true,
          removeMaskOnSubmit: true,
          placeholder: undefined,
          max: 2147483647,
          min: 0,
        };
    }

    if (this.element.getAttribute('max')) {
      mask.max = this.element.getAttribute('max');
    }

    if (this.element.getAttribute('min')) {
      mask.max = this.element.getAttribute('min');
    }
  }
}
