import { Controller } from '@hotwired/stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = [
    'date',
  ];

  connect() {
    const unixTimestamp = parseInt(this.data.get('date'), 10);

    const commentDate = new Date(unixTimestamp * 1000);
    const year = commentDate.getFullYear();

    let format;
    if (year === new Date().getFullYear()) {
      format = 'MMM DD [at] hh:mm A';
    } else {
      format = 'MMM DD, YYYY [at] hh:mm A';
    }
    this.dateTarget.textContent = moment(commentDate).format(format);
  }
}
