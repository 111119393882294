import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'submitButton',
  ]

  connect() {
    this.validator = $(this.element).validate({
      rules: {
        resume: {
          required: true,
          accept: 'application/pdf, application/octet-stream, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
      },
      highlight: (element) => {
        $(element).closest('section').addClass('error-wrapper');
      },
      unhighlight: (element) => {
        $(element).closest('section').removeClass('error-wrapper');
      },
    });
    this.modal.on('closed.zf.reveal', this.cleanup);
  }

  disconnect() {

  }

  cleanup = () => {
    if (this.validator) {
      this.validator.destroy();
    }

    this.modal.off('closed.zf.reveal', this.cleanup);
  }

  onFileChange() {
    this.submitButtonTarget.disabled = false;
  }

  get modal() {
    return $(this.element.closest('.reveal'));
  }
}
