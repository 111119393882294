import { Controller } from '@hotwired/stimulus';

const placesOptions = {
  types: ['(cities)'],
  // componentRestrictions: { country: 'US' },
};

export default class extends Controller {
  static targets = ['locationTextField', 'locationHiddenField'];

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.locationTextFieldTarget,
      placesOptions,
    );
    this.placeChangedHandler = google.maps.event.addListener(
      this.autocomplete,
      'place_changed',
      this.onPlaceChanged,
    );
    $.validator.setDefaults({ ignore: [] });
    this.validator = $(this.element).validate({
      highlight(element) {
        $(element).closest('div').addClass('error-wrapper');
      },
      unhighlight(element) {
        $(element).closest('div').removeClass('error-wrapper');
      },
    });

    this.changeListener = google.maps.event.addDomListener(
      this.locationTextFieldTarget,
      'keydown',
      this.onChange,
    );
  }

  disconnect() {
    if (this.autocomplete) {
      google.maps.event.removeListener(this.placeChangedHandler);
    }
    if (this.changeListener) {
      console.log('removing change listener');
      google.maps.event.removeListener(this.changeListener);
      this.changeListener = null;
    }
    this.autocomplete = null;
    if (this.validator) {
      this.validator.destroy();
      this.validator = null;
    }
  }

  onKeydown = (e) => {
    if (
      e.metaKey ||
      e.altKey ||
      e.ctrlKey ||
      (e.key && e.key.startsWith('Arrow')) ||
      e.key === 'Shift'
    ) {
      return;
    }
    const autocompleteVisible = $('.pac-container:visible').length > 0;
    if (e.keyCode === 13 && autocompleteVisible) {
      e.preventDefault();
    } else if (e.keyCode !== 13) {
      this.locationHiddenFieldTarget.value = '';
      this.locationHiddenFieldTarget.dispatchEvent(new Event('change'));
    }
  };

  onPlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    if (place && place.formatted_address) {
      this.locationHiddenFieldTarget.value = place.formatted_address;
      this.locationHiddenFieldTarget.dispatchEvent(new Event('change'));
    } else {
      this.locationTextFieldTarget.value = '';
      this.locationHiddenFieldTarget.value = '';
      this.locationHiddenFieldTarget.dispatchEvent(new Event('change'));
    }
  };
}
