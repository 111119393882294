import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'email',
    'title',
    'errors',
    'form',
  ];

  clearForm() {
    this.emailTarget.value = '';
    this.titleTarget.value = '';
    this.clearErrors();
  }

  clearErrors() {
    $(this.errorsTarget).html('');
  }

  onSaveFail(event) {
    const [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  onSaveSuccess(event) {
    this.clearForm();
  }
}
