import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['videoType', 'videoLink'];

  onChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value === 'youtube') {
      this.videoLinkTarget.placeholder =
        'http://www.youtube.com/watch?v=<VIDEO_ID>';
    } else if (value === 'vimeo') {
      this.videoLinkTarget.placeholder = 'https://vimeo.com/<VIDEO_ID>';
    } else {
      this.videoLinkTarget.placeholder = 'Document URL';
    }
  };
}
