import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['status', 'downloadLink', 'loading'];

  static values = {
    projectId: String,
  };

  connect() {
    this.timeoutId = null;
    this.poll();
  }

  disconnect() {
    this.stop();
  }

  checkDownload = async () => {
    const {
      data: { download_link, one_pager_box_html },
    } = await axios.get(`/projects/${this.projectId}/download`, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      },
    });
    if (download_link?.url) {
      this.stop();
      this.onDownloadReady(download_link.url, one_pager_box_html);
    } else {
      this.continuePolling();
    }
  };

  showDownloadSection(downloadLink) {
    this.hideLoadingSection();
    this.statusTarget.classList.remove('hidden');
    this.downloadLinkTarget.href = downloadLink;
  }

  hideLoadingSection() {
    this.loadingTarget.classList.add('hidden');
  }

  showLoadingSection() {
    this.loadTarget.classList.remove('hidden');
  }

  onDownloadReady(link, html) {
    document.getElementById('one-pager-section').innerHTML = html;
    this.showDownloadSection(link);
  }

  continuePolling() {
    this.timeoutId = setTimeout(this.checkDownload, 1000);
  }

  poll() {
    this.timeoutId = setTimeout(this.checkDownload, 1000);
  }

  stop() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  get projectId() {
    return this.projectIdValue;
  }
}
