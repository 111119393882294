/* eslint no-param-reassign: 0 */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];

  static values = {
    id: String,
  };

  connect() {
    document.addEventListener(this.eventName, this.onToggle);
  }

  disconnect() {
    document.removeEventListener(this.eventName, this.onToggle);
  }

  onToggle = (e) => {
    e.stopPropagation();
    const {
      detail: { values },
    } = e;

    this.fieldTarget.value = values;
  };

  get eventName() {
    return `${this.idValue}:toggle`;
  }
}
