import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import cloneDeep from 'lodash.clonedeep';

import Modal from '../Modal';

import QuestionChoiceField from './QuestionChoiceField';
import Alert from '../Alert';

import {
  QuestionTypesList,
  QuestionTypes,
  QUESTION_TYPES_WITH_CHOICES,
} from '../../constants/questions';

const DEFAULT_QUESTION_TYPE = QuestionTypes.PARAGRAPH;

function getTitle(question) {
  if (question.id) {
    return 'Edit Question';
  }
  return 'Add Question';
}

export default class QuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      question: props.question,
    };
  }

  getValidationClass(field) {
    const { errors } = this.state;

    return classnames({
      'form-group': true,
      'mb-6': true,
      'error-wrapper': errors[field] && errors[field].length > 0,
    });
  }

  handleChange = (field) => (e) => {
    const {
      target: { value },
    } = e;

    this.setState((currentState) => {
      const { question: currentQuestion } = currentState;

      return {
        question: {
          ...cloneDeep(currentQuestion),
          [field]: value,
        },
      };
    });
  };

  handleTypeChange = (e) => {
    const {
      target: { value: question_type },
    } = e;

    this.setState((currentState) => {
      const { question: currentQuestion } = currentState;

      return {
        question: {
          ...currentQuestion,
          question_type,
        },
      };
    });
  };

  handleSave = () => {
    const errors = this.validate();
    if (Object.values(errors).length === 0) {
      this.props.onSave(this.transform());
    }
    this.setState({
      errors,
    });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleAddChoice = () => {
    this.setState((currentState) => {
      const question = cloneDeep(currentState.question);
      const currentData = question.data || {};
      const currentChoices = currentData.choices || [];

      const choices = [
        ...currentChoices,
        {
          name: '',
        },
      ];

      const data = {
        ...currentData,
        choices,
      };

      question.data = data;

      return {
        question,
      };
    });
  };

  handleRemoveChoice = (index) => () => {
    this.setState((currentState) => {
      const question = cloneDeep(currentState.question);
      const currentData = question.data || {};
      const currentChoices = currentData.choices || [];

      const choices = [
        ...currentChoices.slice(0, index),
        ...currentChoices.slice(index + 1),
      ];

      const data = {
        choices,
      };

      question.data = data;

      return {
        question,
      };
    });
  };

  handleChoiceFieldChange = (index) => (value) => {
    this.setState((currentState) => {
      const question = cloneDeep(currentState.question);
      const currentData = question.data || {};
      const currentChoices = currentData.choices || [];

      const choices = [
        ...currentChoices.slice(0, index),
        {
          name: value,
        },
        ...currentChoices.slice(index + 1),
      ];

      question.data = {
        choices,
      };

      return {
        question,
      };
    });
  };

  transform() {
    const { question: currentQuestion } = this.state;

    const question = cloneDeep(currentQuestion);

    if (question.updated_at) {
      delete question.updated_at;
    }

    if (question.created_at) {
      delete question.created_at;
    }

    if (!QUESTION_TYPES_WITH_CHOICES.includes(question.question_type)) {
      question.data = {};
      question.data.choices = [];
    }

    if (!question.data || !question.data.choices) {
      question.data = {};
      question.data.choices = [];
    }

    return question;
  }

  validate() {
    const errors = {};
    const {
      question: { title, question_type, data },
    } = this.state;

    if (!title) {
      errors.title = ['The question cannot be blank'];
    }

    if (question_type === '') {
      errors.question_type = ['The question type must be sent'];
    } else if (QUESTION_TYPES_WITH_CHOICES.includes(question_type)) {
      const choices = (data || {}).choices || [];
      const choicesFilled = choices.every((s) => s.name.length > 0);
      if (choices.length === 0 || !choicesFilled) {
        errors.choices = ['Please provide at least one answer choice'];
      }
    }

    return errors;
  }

  renderChoices() {
    const {
      question: { data, question_type },
    } = this.state;

    if (!QUESTION_TYPES_WITH_CHOICES.includes(question_type)) {
      return null;
    }

    const choices = (data || {}).choices || [];

    return (
      <div className="mb-4 choices-wrapper form-group">
        <header className="flex items-center mb-2">
          <label className="mr-4">Answer Choices</label>
          {choices.length > 0 && (
            <button
              type="button"
              className="grow-0 shrink-0 w-10 h-10 btn btn-hollow-blue btn-circle"
              onClick={this.handleAddChoice}
            >
              <PlusIcon className="w-5 h-5" />
            </button>
          )}
        </header>
        {choices.length > 0 && (
          <ul className="w-full p-0 mx-0 list-none md:w-1/2">
            {choices.map((choice, i) => (
              <QuestionChoiceField
                key={i}
                choice={choice}
                onChange={this.handleChoiceFieldChange(i)}
                onRemove={this.handleRemoveChoice(i)}
              />
            ))}
          </ul>
        )}
        {choices.length === 0 && (
          <div className="flex flex-col flex-wrap items-start justifiy-center">
            <small className="mb-2 text-sm">
              It looks like there are no choices assigned to this question
            </small>
            <button
              type="button"
              className="flex items-center btn btn-hollow-blue"
              onClick={this.handleAddChoice}
            >
              <PlusIcon className="w-5 h-5 mr-2" />
              <span>Create a Choice</span>
            </button>
          </div>
        )}
      </div>
    );
  }

  renderTitleField() {
    const {
      question: { title },
    } = this.state;

    return (
      <div className={this.getValidationClass('title')}>
        <label htmlFor="question_title" className="mb-2">
          Enter the Question Text
        </label>
        <textarea
          id="question_title"
          type="text"
          placeholder="Enter the question"
          onChange={this.handleChange('title')}
          value={title}
        />
      </div>
    );
  }

  renderTypeField() {
    const {
      question: { question_type: currentQuestionType },
    } = this.state;

    const selectedQuestionType = currentQuestionType || DEFAULT_QUESTION_TYPE;
    return (
      <div className={this.getValidationClass('question_type')}>
        <label htmlFor="question_title" className="mb-2">
          Select the Question Type
        </label>
        <select value={selectedQuestionType} onChange={this.handleTypeChange}>
          {QuestionTypesList.map((questionType) => (
            <option key={questionType.value} value={questionType.value}>
              {questionType.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderErrors() {
    const errorValues = Object.values(this.state.errors);

    if (errorValues.length === 0) {
      return null;
    }

    const flattenedErrors = errorValues.reduce(
      (flattenedList, error) => flattenedList.concat(error),
      [],
    );

    return (
      <div className="mb-4">
        <Alert
          type="error"
          title="There were some problems with saving the question:"
        >
          <ul>
            {flattenedErrors.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </Alert>
      </div>
    );
  }

  render() {
    return (
      <Modal
        title={getTitle(this.props.question)}
        onClose={this.handleCancel}
        className={{
          'md:w-1/2': true,
        }}
      >
        <div className="p-4 mb-8 bg-white shadow form-wrapper">
          {this.renderTitleField()}
          {this.renderTypeField()}
          {this.renderChoices()}
          {this.renderErrors()}
          <div className="flex justify-end">
            <button
              type="submit"
              onClick={this.handleSave}
              className="mr-2 btn btn-primary"
            >
              <span>Save</span>
            </button>
            <button
              type="button"
              onClick={this.handleCancel}
              className="btn btn-hollow-blue"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

QuestionForm.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    question_type: PropTypes.string.isRequired,
    round: PropTypes.number.isRequired,
    data: PropTypes.object,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
