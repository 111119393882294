import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Modal extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOverlayClick, false);
    document.addEventListener('keyup', this.handleKeyPress, false);
    this.modal.focus();
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress, false);
    document.removeEventListener('mousedown', this.handleOverlayClick, false);
  }

  handleOverlayClick = (e) => {
    if (!this.modal.contains(e.target)) {
      this.props.onClose();
    }
  };

  handleKeyPress = (e) => {
    const { closeOnEscapeKey } = this.props;

    if (e.keyCode === 27 && closeOnEscapeKey) {
      this.props.onClose();
    }
  };

  handleClose = () => this.props.onClose();

  render() {
    const { title, className } = this.props;

    const modalClassName = classnames({
      'bg-white': true,
      'shadow-lg': true,
      relative: true,
      'z-50': true,
      rounded: true,
      'overflow-auto': true,
      ...className,
    });

    return ReactDOM.createPortal(
      <div className="bg-smoke-lighter fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center h-screen z-50">
        <div
          className={modalClassName}
          role="dialog"
          style={{
            maxWidth: '600px',
            maxHeight: '90vh',
          }}
          ref={(node) => {
            this.modal = node;
          }}
        >
          <div className="p-4 border-b border-grey-light border-solid flex justify-between">
            <h3 className="text-2xl">{title}</h3>
            <button
              type="button"
              className="no-outline"
              onClick={this.handleClose}
            >
              <XMarkIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
            </button>
          </div>
          {this.props.children}
        </div>
      </div>,
      document.getElementById('modal-root'),
    );
  }
}

Modal.propTypes = {
  className: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClose: PropTypes.func,
  closeOnEscapeKey: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  className: {},
  onClose: () => {},
  closeOnEscapeKey: true,
};
