import { Controller } from '@hotwired/stimulus';

const ENTITY_NAME = 'profile';

export default class extends Controller {
  connect() {
    document.addEventListener(this.updateEventName, this.onSectionUpdated);
  }

  disconnect() {
    document.removeEventListener(this.updateEventName, this.onSectionUpdated);
  }

  onSectionUpdated = (e) => {
    const {
      html,
    } = e.detail;

    this.element.innerHTML = html;
    const refreshRequirementsEvent = new CustomEvent('refresh:requirements');
    document.dispatchEvent(refreshRequirementsEvent);
  }

  get updateEventName() {
    return `${ENTITY_NAME}-${this.data.get('key')}:updated`;
  }
}
