import { Controller } from '@hotwired/stimulus';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onCancel() {
    this.closeModal();
  }

  onSendSuccess() {
    Snackbar.show({
      text: 'The message has been sent to the selected applicants',
    });
    this.closeModal();
  }

  onSendFail() {
    Snackbar.show({
      text: 'There was a problem sending your message',
      actionTextColor: ERROR_COLOR,
    });
    this.closeModal();
  }

  closeModal() {
    document.dispatchEvent(new CustomEvent('bulk-email-modal-close'));
  }
}
