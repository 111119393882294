import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import isEqual from 'lodash.isequal';
import { convertISODateToMoment } from '../../../utils/date';
import Alert from '../../Alert';
import ProTip from '../../ProTip';

import CompetitionRoundType from './CompetitionRoundType';
import VotingCapFields from './VotingCapFields';

import JudgingTypes, {
  THUMBS_UP_DOWN,
  CATEGORIES,
  NUMERIC_SCORING,
  VOTING_CAP,
} from '../../../constants/judging-types';

const VOTING_CAP_FIELDS = [
  'total_points_per_judge',
  'application_max_points_per_judge',
];

const ThumbsUpDownHelpText = (
  <p>
    This judging type is primarily used for the first round of your judging. It
    helps consolidate and quickly run through a large number of applications so
    that you can start doing deeper dives into the remaining startups.
  </p>
);

const CategoriesHelpText = (
  <div>
    <p>
      Judging by categories relies on your customization. Do you have specific
      categories that you use, such as Traction, Team, Likeability, or Product?
      You have the option to add the different types here and then rate them on
      a graded scale of A, B, C, D, F
    </p>
    <p>
      Additionally, you can add specific weights to those categories if you
      choose to do so.
    </p>
  </div>
);

const NumberScoreHelpText = (
  <div>
    <p>Rate the startups based on a scale of 1-7 (7 as the best company).</p>

    <p>
      The reason behind 1-7 and not 1-10 is that you inherit upward grading bias
      if you do 1-10.
    </p>
  </div>
);

const VotingCapHelpText = (
  <div>
    <p>
      Use this judging if only a certain number of startups can advance to the
      next round.
    </p>
    <p>
      You have the option to limit the total number of points a judge receives
      as well as the total number of points that a judge can assign to a single
      startup. That judge then can add an incremental or a full point to the
      applicants they like.
    </p>
    <p>
      Example: We, as a collective user, only want ten startups to advance to
      the next round. We will limit the number of points a judge gets to ten.
      That way when they go through the forty applications, they will have to be
      extremely selective in who they are giving points to.
    </p>
  </div>
);

const helpTextComponent = {
  [VOTING_CAP.key]: VotingCapHelpText,
  [CATEGORIES.key]: CategoriesHelpText,
  [NUMERIC_SCORING.key]: NumberScoreHelpText,
  [THUMBS_UP_DOWN.key]: ThumbsUpDownHelpText,
};

function validateVotingCap(competitionRound) {
  const {
    judging_type,
    total_points_per_judge,
    application_max_points_per_judge,
  } = competitionRound;

  if (judging_type !== VOTING_CAP.key) {
    return {};
  }

  const errors = {};

  if (!total_points_per_judge) {
    errors.total_points_per_judge = [
      'Total points assigned to a judge is required',
    ];
  }

  if (!application_max_points_per_judge) {
    errors.application_max_points_per_judge = [
      'Total number of points a judge can assign to a startup is required',
    ];
  }

  return errors;
}

function validate(competitionRound) {
  let errors = {};

  if (!competitionRound.name) {
    errors.name = ['The round name is required'];
  }

  const votingCapErrors = validateVotingCap(competitionRound);

  errors = {
    ...errors,
    ...votingCapErrors,
  };

  return {
    errors,
    votingCapErrors,
  };
}

function getValidationClass(field, errors = {}, className = {}) {
  const errorWrapper = errors[field] && errors[field].length;
  return classnames({
    'form-group': true,
    'error-wrapper': errorWrapper,
    ...className,
  });
}

function transformRoundBeforeSave(competitionRound) {
  const { judging_type } = competitionRound;

  if (judging_type !== VOTING_CAP.key) {
    return {
      ...competitionRound,
      total_points_per_judge: null,
      application_max_points_per_judge: null,
    };
  }
  return competitionRound;
}
export default class CompetitionRoundForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.competitionRound.id !== prevState.competitionRound.id) {
      return {
        competitionRound: nextProps.competitionRound,
        errors: {},
      };
    }

    if (!isEqual(nextProps.serverErrors, prevState.serverErrors)) {
      return {
        serverErrors: nextProps.serverErrors,
        errors: nextProps.serverErrors,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      competitionRound: props.competitionRound,
      serverErrors: props.serverErrors,
      errors: props.serverErrors,
      votingCapErrors: {},
      showProTip: true,
    };
    this.tooltipRef = React.createRef();
  }

  componentDidMount() { }

  handleCloseProTip = () => this.setState({ showProTip: false });

  handleCloseAlert = () =>
    this.setState({
      errors: {},
      votingCapErrors: {},
    });

  handleDateFieldChange = (field) => (momentDate) => {
    let value = null;
    if (momentDate) {
      value = momentDate.toISOString();
    }

    this.setState((prevState) => {
      const competitionRound = {
        ...prevState.competitionRound,
        [field]: value,
      };

      return {
        competitionRound,
      };
    });
  };

  handleJudgingTypeSelect = (judging_type) => {
    this.setState((prevState) => {
      const competitionRound = {
        ...prevState.competitionRound,
        judging_type,
      };
      return {
        competitionRound,
      };
    });
  };

  handleVotingCapFieldChange = (field, value) => {
    this.setState((prevState) => {
      if (!VOTING_CAP_FIELDS.includes(field)) {
        return null;
      }
      return {
        competitionRound: {
          ...prevState.competitionRound,
          [field]: value,
        },
      };
    });
  };

  handleFieldChange = (field) => (e) => {
    const {
      target: { value },
    } = e;
    this.setState((prevState) => {
      const competitionRound = {
        ...prevState.competitionRound,
        [field]: value,
      };
      return {
        competitionRound,
      };
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    let competitionRound;
    this.setState(
      (prevState) => {
        competitionRound = transformRoundBeforeSave(prevState.competitionRound);
        const validationResult = validate(competitionRound);

        return validationResult;
      },
      () => {
        if (Object.keys(this.state.errors).length === 0) {
          this.props.onSave(competitionRound);
        }
      },
    );
  };

  handleCancel = () => this.props.onCancel();

  renderErrors() {
    const { errors } = this.state;

    const errorMessages = Object.values(errors).reduce(
      (errorList, errorMessage) => errorList.concat(errorMessage),
      [],
    );

    if (errorMessages.length === 0) {
      return null;
    }
    return (
      <div className="mx-6 mb-4">
        <Alert
          type="error"
          title="Please correct the following errors:"
          onClose={this.handleCloseAlert}
        >
          <ul>
            {errorMessages.map((errorMessage, i) => (
              <li key={i}>{errorMessage}</li>
            ))}
          </ul>
        </Alert>
      </div>
    );
  }

  renderJudgingSetup() {
    const {
      competitionRound: { judging_type },
    } = this.state;

    switch (judging_type) {
      case VOTING_CAP.key:
        return this.renderVotingCapSetup();
      default:
        return null;
    }
  }

  renderVotingCapSetup() {
    const {
      competitionRound: {
        total_points_per_judge,
        application_max_points_per_judge,
      },
    } = this.state;

    const votingCap = {
      total_points_per_judge,
      application_max_points_per_judge,
    };

    return (
      <VotingCapFields
        voting_cap={votingCap}
        onChange={this.handleVotingCapFieldChange}
        errors={this.state.votingCapErrors}
      />
    );
  }

  render() {
    const {
      competitionRound: {
        name,
        answering_end_date,
        judging_end_date,
        judging_type,
      },
      errors,
    } = this.state;

    return (
      <div className="form-wrapper">
        {this.state.showProTip && (
          <div className="px-4">
            <ProTip className="my-4" onClose={this.handleCloseProTip}>
              <p>
                The Application Deadline & Judging End Date are informational
                and do not affect whether the application closes or stays open.{' '}
              </p>

              <p>
                Add in the deadline dates to give applicants an understanding of
                the process. The dates help reduce the number of questions you
                will receive. For those without an application deadline, please
                leave it blank.
              </p>
              <p>
                There are four different types of judging to choose from. If you
                hover over each one, they will tell you what they do. If you
                select categories, you must go back to the original screen and
                click the gear icon to set your custom categories.
              </p>
            </ProTip>
            <hr className="" />
          </div>
        )}
        {this.renderErrors()}
        <div className="flex flex-wrap mx-6 mt-4">
          <div className="w-full">
            <label className="mb-2 form-label">Round Name</label>
            <div
              className={getValidationClass('name', errors, {
                'mb-6': true,
              })}
            >
              <input
                type="text"
                placeholder="Round Name"
                className="w-full form-field"
                value={name}
                onChange={this.handleFieldChange('name')}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <div className="mx-6 form-group">
              <label className="mb-2 form-label">Application Deadline</label>
              <div className="w-full mb-6">
                <DatePicker
                  className="block w-full mb-0 form-field"
                  placeholderText="Application Deadline"
                  selected={convertISODateToMoment(answering_end_date)}
                  onChange={this.handleDateFieldChange('answering_end_date')}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="mx-6 form-group">
              <label className="mb-2 form-label">Judging End Date</label>
              <div className="w-full mb-6">
                <DatePicker
                  className="block w-full mb-0 form-field"
                  placeholderText="Judging End Date"
                  selected={convertISODateToMoment(judging_end_date)}
                  onChange={this.handleDateFieldChange('judging_end_date')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4 form-group">
          <label className="mx-6 mb-2 form-label">
            <span className="mr-2">Select the judging type</span>
            <span ref={this.tooltipRef} />
          </label>
          <div className="flex flex-wrap types-wrapper">
            {JudgingTypes.map((type) => (
              <CompetitionRoundType
                key={type.value}
                className={{
                  'mb-6': true,
                }}
                helpTextComponent={helpTextComponent[type.key]}
                selected={type.key === judging_type}
                competitionRoundType={type}
                onSelect={this.handleJudgingTypeSelect}
              />
            ))}
          </div>
        </div>
        {this.renderJudgingSetup()}
        <div className="flex justify-end mx-6 mt-8 form-actions">
          <button
            type="submit"
            onClick={this.handleSave}
            className="mr-4 btn btn-primary"
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-hollow-blue"
            onClick={this.handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

CompetitionRoundForm.propTypes = {
  competitionRound: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    answering_end_date: PropTypes.string,
    judging_end_date: PropTypes.string,
    judging_type: PropTypes.string,
    total_points_per_judge: PropTypes.number,
    application_max_points_per_judge: PropTypes.number,
  }),
  serverErrors: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

CompetitionRoundForm.defaultProps = {
  competitionRound: {
    id: null,
    name: '',
    answering_end_date: null,
    judging_end_date: null,
    judging_type: null,
    total_points_per_judge: null,
    application_max_points_per_judge: null,
  },
  serverErrors: {},
};
