import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'rightIcon', 'downIcon'];

  connect() {}

  disconnect() {}

  onToggle() {
    const current = this.data.get('expanded') === 'true';
    const expanded = !current;
    this.data.set('expanded', expanded);

    this.toggleHeaderIcon(expanded);
    this.toggleContent(expanded);
  }

  toggleContent(expanded) {
    if (expanded) {
      this.contentTarget.classList.remove('hidden');
      setTimeout(() => {
        $(this.contentTarget).slideDown();
      }, 0);
    } else {
      $(this.contentTarget).slideUp();
      setTimeout(() => {
        this.contentTarget.classList.add('hidden');
      }, 300);
    }
  }

  toggleHeaderIcon(expanded) {
    if (expanded) {
      this.downIconTarget.classList.remove('hidden');
      this.rightIconTarget.classList.add('hidden');
    } else {
      this.rightIconTarget.classList.remove('hidden');
      this.downIconTarget.classList.add('hidden');
    }
  }
}
