import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'content',
    'textarea',
  ];

  connect() {

  }

  disconnect() {

  }

  onClick() {
    this.hideContent();
    this.showTextArea();
  }

  onChange(e) {
    const {
      currentTarget: {
        value,
      },
    } = e;
    this.hideTextArea();
    this.updateContent(value);
    this.showContent();
  }

  onBlur() {
    this.hideTextArea();
    this.showContent();
  }

  showTextArea() {
    this.textareaTarget.classList.remove('hidden');
  }

  hideTextArea() {
    this.textareaTarget.classList.add('hidden');
  }

  updateContent(text) {
    this.contentTarget.innerHTML = text;
  }

  showContent() {
    this.contentTarget.classList.remove('hidden');
  }

  hideContent() {
    this.contentTarget.classList.add('hidden');
  }
}
