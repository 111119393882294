import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.mount();

    document.addEventListener(
      'turbolinks:before-cache',
      () => {
        this.unmount();
      },
      { once: true },
    );
  }

  saveState() {
    const $el = $(this.element);
    const values = $(this.element).val();
    values.forEach((val) => {
      $el.find(`options[value="${val}"]`).attr('selected', 'selected');
    });
  }

  mount() {
    const $el = $(this.element);
    const placeholder = $el.data('placeholder');

    const options = {};

    if (placeholder) {
      options.placeholder = placeholder;
    }
    $el.select2(options);
  }

  unmount() {
    $(this.element).select2('destroy');
  }
}
