import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CogIcon, CalendarIcon, PencilIcon, XMarkIcon } from '@heroicons/react/20/solid';
import JudgingTypes, { CATEGORIES } from '../../../constants/judging-types';

const judgingTypeKeys = JudgingTypes.map(j => j.key);

function formatDate(date) {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format('MM/DD/YYYY');
}

const CalendarField = ({ dateStr }) => (
  <div className="mb-4 flex items-center space-x-2">
    <CalendarIcon className="text-grey w-5 h-5" aria-hidden={true} />
    <span className="text-grey-dark">
      {dateStr}
    </span>
  </div>
);

CalendarField.propTypes = {
  dateStr: PropTypes.string.isRequired,
};

function getJudgingLabel(key) {
  const match = JudgingTypes.find(j => j.key === key);
  if (match) {
    return match.label;
  }
  return '';
}

export default class CompetitionRoundCard extends Component {
  static propTypes = {
    competitionRound: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      answering_end_date: PropTypes.string,
      judging_end_date: PropTypes.string,
      judging_type: PropTypes.oneOf(judgingTypeKeys),
    }).isRequired,
    onEditRound: PropTypes.func.isRequired,
    onDeleteRound: PropTypes.func.isRequired,
    onShowJudgingSetup: PropTypes.func.isRequired,
  }

  handleEditRound = () => this.props.onEditRound(
    this.props.competitionRound.id,
  );

  handleDeleteRound = () => this.props.onDeleteRound(
    this.props.competitionRound.id,
  );

  handleShowJudgingSetup = () => this.props.onShowJudgingSetup(this.props.competitionRound.id);

  renderJudgingSetup() {
    const {
      competitionRound: {
        judging_type,
      },
    } = this.props;

    if (judging_type !== CATEGORIES.key) {
      return null;
    }

    const setupJudgingLabel = `Setup ${CATEGORIES.label}`;
    return (
      <button
        type="button"
        className="text-sm"
        onClick={this.handleShowJudgingSetup}
      >
        <div className="flex items-center">
          <CogIcon className="mr-2 w-4 h-4 text-gray-400 hover:text-gray-700" />
          <span className="text-gray-500">
            {setupJudgingLabel}
          </span>
        </div>
      </button>
    );
  }

  render() {
    const {
      competitionRound: {
        name,
        order,
        answering_end_date,
        judging_end_date,
        judging_type,
      },
    } = this.props;
    return (
      <div
        className="flex-auto p-6 mb-6 bg-white rounded shadow-lg round-card relative"
      >
        <div className="flex flex-wrap justify-between">
          <div className="">
            <div className="flex flex-wrap items-center mb-4">
              <span className="flex items-center justify-center shrink-0 w-10 h-10 mr-4 font-bold text-white rounded-full bg-blue-lighter">
                {order}
              </span>
              <h3 className="flex flex-wrap items-center mb-2 text-2xl">
                <span className="block mb-2 truncate md:mb-0 w-80 text-ellipsis" title={name}>{name}</span>
                {judging_type && (
                  <span className="block px-2 py-1 ml-0 text-xs font-bold text-white bg-blue-400 rounded-full md:inline md:ml-2">
                    {getJudgingLabel(judging_type)}
                  </span>
                )}
              </h3>
            </div>

            <div className="ml-0 md:ml-16">
              <div className="flex flex-wrap mb-6">
                <div className="mr-8">
                  <h4 className="mb-2 text-base text-grey-dark">
                    Answering
                  </h4>
                  <CalendarField
                    dateStr={formatDate(answering_end_date)}
                  />
                </div>
                <div className="">
                  <h4 className="mb-2 text-base text-grey-dark">
                    Judging&nbsp;
                  </h4>
                  <CalendarField
                    dateStr={formatDate(judging_end_date)}
                  />
                </div>
              </div>
              {this.renderJudgingSetup()}
            </div>
          </div>

          <div className="absolute right-0 mr-4 text-grey-dark md:relative md:mr-0">
            <button
              type="button"
              onClick={this.handleEditRound}
              className="mr-2"
              title="Edit Round"
            >
              <PencilIcon className="text-sm text-grey hover:text-grey-dark w-4 h-4" />
            </button>
            <button
              type="button"
              onClick={this.handleDeleteRound}
              title="Delete Round"
            >
              <XMarkIcon className="text-sm text-grey hover:text-grey-dark w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
