import { Controller } from '@hotwired/stimulus';
import ConfirmModalEvents from '../constants/events/confirm-modal';

export default class extends Controller {
  connect() {
    if (!this.elementId) {
      throw new Error(
        'An element ID must be set for the confirm-action controller',
      );
    }
  }

  onConfirm() {
    console.log('confirmed');

    const confirmedEvent = new CustomEvent(`${this.elementId}-confirmed`, {
      bubbles: true,
    });
    document.dispatchEvent(confirmedEvent);
  }

  get elementId() {
    return this.element.id;
  }
}
