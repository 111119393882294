import Snackbar from 'node-snackbar';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'container',
    'memberList',
  ];

  onInviteSent(event) {
    const [data, status, xhr] = event.detail;
    const response = JSON.parse(xhr.response);
    Snackbar.show({ text: response.message });
  }

  onDeleteSuccess(e) {
    $(e.target).closest('tr').remove();
    if ($(this.memberListTarget.memberListTarget).find('tr').length === 0) {
      this.containerTarget.classList.add('hidden');
    }
    Snackbar.show({ text: 'We removed the selected user.' });
  }

  onCreateSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.containerTarget.classList.remove('hidden');
    this.memberListTarget.insertAdjacentHTML('beforeend', xhr.response);
    Snackbar.show({ text: 'We invited the user to your team.' });
  }
}
