import ApplicationCable from './application_cable';

export default class NotificationsHelper {
  static sharedInstance = false;

  static shared() {
    if (this.sharedInstance === false) {
      this.sharedInstance = new NotificationsHelper();
    }

    return this.sharedInstance;
  }

  handleUpdateNotificationListeners = [];

  handleUpdateNotification = (notification) => {
    const listeners = this.handleUpdateNotificationListeners;

    Object.values(listeners).filter((l) => l).forEach((listener) => {
      listener(notification);
    });
  }

  constructor() {
    window.sparkxyz = window.sparkxyz || {};
    window.sparkxyz.notifications = window.sparkxyz.notifications || {
      onUpdateNotification: this.handleUpdateNotification,
    };

    this.connect();
  }

  connect() {
    const $currentUserMetaTag = document.querySelector('meta[name="current-user"]');
    if (!$currentUserMetaTag) {
      return;
    }
    const { cable } = ApplicationCable.shared();

    const index = this.handleUpdateNotificationListeners.push(this.handleNotification);

    this.handleUpdateNotificationListenerIndex = index;

    cable.subscriptions.create('NotificationsChannel', {
      received: this.handleUpdateNotification,
    });
  }

  addListener(listener) {
    this.handleUpdateNotificationListeners.push(listener);
    return this.handleUpdateNotificationListeners.length - 1;
  }

  removeListener(index) {
    delete this.handleUpdateNotificationListeners[index];
  }

  clearNotificationsCount() {
    this.handleUpdateNotification({ unread_count: 0 });
  }
}
