import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['form', 'errors', 'items'];

  onDeleteSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.itemsTarget.innerHTML = xhr.response;
    Snackbar.show({ text: 'The selected item was successfully removed' });
  }

  onDeleteFail(event) {
    const [data, status, xhr] = event.detail;
    const {
      xhr: { response },
    } = event;

    Snackbar.show({
      text: 'There was a problem completing your request',
      actionTextColor: ERROR_COLOR,
    });
  }

  onSaveSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.itemsTarget.innerHTML = xhr.response;
    this.hideForm();
    Snackbar.show({
      text: 'The item was successfully created',
    });
  }

  onSaveFail(event) {
    const [data, status, xhr] = event.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  onCancelSave() {
    this.hideForm();
  }

  onShowForm(event) {
    const [data, status, xhr] = event.detail;
    this.formTarget.innerHTML = xhr.response;
    this.showForm();
  }

  onShowFormFail() {
    Snackbar.show({
      text: 'There was a problem displaying the form',
      actionTextColor: ERROR_COLOR,
    });
  }

  showForm() {
    this.formTarget.classList.remove('hidden');
  }

  hideForm() {
    this.formTarget.classList.add('hidden');
  }
}
