import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static values = {
    text: String,
    type: String,
    position: String,
  };

  connect() {
    const options = {
      text: this.textValue,
      pos: this.positionValue || 'bottom-right',
    };

    if (['alert', 'error'].includes(this.typeValue)) {
      options.actionTextColor = ERROR_COLOR;
    }

    if (this.textValue) {
      Snackbar.show(options);
    }
  }
}
