import { Controller } from '@hotwired/stimulus';
import FaceValues, { GRADES } from '../constants/judging/face-values';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static targets = ['category', 'totalScore', 'scores'];

  onFaceValueChange = (e) => {
    const { target } = e;

    const { value } = target;

    const categoryId = target.getAttribute('data-id');

    const scoresJSON = this.scoresTarget.value;
    const scores = scoresJSON ? JSON.parse(scoresJSON) : {};
    const currentCategories = scores.categories || [];

    let categories;
    if (!value || !GRADES.includes(value)) {
      categories = currentCategories.filter((c) => c.id !== categoryId);
    } else {
      const matchIndex = currentCategories.findIndex(
        (c) => c.id === categoryId,
      );
      const index = matchIndex !== -1 ? matchIndex : currentCategories.length;

      categories = [
        ...currentCategories.slice(0, index),
        {
          id: categoryId,
          value,
        },
        ...currentCategories.slice(index + 1),
      ];
    }
    const payload = categories.filter((c) => c.value !== '-' && c.value !== '');

    this.scoresTarget.value = JSON.stringify({ categories: payload });

    Rails.fire(this.element, 'submit');
  };

  onSaveSuccess = (e) => {
    const [res, status, xhr] = e.detail;
    const {
      judge_score: { score },
    } = res;
    this.totalScoreTarget.textContent = score || '-';
    Snackbar.show({ text: 'The score has been updated!' });
  };

  onSaveFail = (e) => {
    Snackbar.show({
      text: 'There was a problem saving judging setup',
      actionTextColor: ERROR_COLOR,
    });
  };
}
