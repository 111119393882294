import { Controller } from '@hotwired/stimulus';
import accounting from 'accounting';

export default class extends Controller {
  static targets = ['amount', 'fee', 'total'];

  static values = {
    payoutPercent: Number,
    customerMarkupPercent: Number,
  };

  clearFeeSummary() {
    this.feeTarget.innerHTML = '-';
    this.totalTarget.innerHTML = '-';
  }

  onChange = (e) => {
    const {
      target: { value },
    } = e;

    if (!value) {
      this.clearFeeSummary();
      return;
    }

    const amount = parseFloat(value);

    if (Number.isNaN(amount)) {
      this.clearFeeSummary();
      return;
    }

    const total = amount + parseFloat(this.customerMarkupValue || 0) / 100;
    const payoutTotal = total * (parseFloat(this.payoutPercentValue) / 100);
    const sparkFee = (100 - parseFloat(this.payoutPercentValue)) * total;
    this.feeTarget.innerHTML = accounting.formatMoney(sparkFee / 100);
    this.totalTarget.innerHTML = accounting.formatMoney(payoutTotal);
  };
}
