import { Controller } from '@hotwired/stimulus';

import axios from 'axios';
import NotificationsHelper from '../utils/notifications_helper';

export default class NotificationsController extends Controller {
  static targets = ['count', 'countContainer', 'notificationsContainer'];

  connect() {
    const helper = NotificationsHelper.shared();
    this.listenerID = helper.addListener(this.handleNotification);
  }

  disconnect() {
    const helper = NotificationsHelper.shared();
    helper.removeListener(this.listenerID);
  }

  handleNotification = ({ unread_count: unreadCount }) => {    
    if (unreadCount === undefined) {
      return;
    }

    if (unreadCount === 0) {
      this.countTarget.innerHTML = '';
      const classArr = this.countContainerTarget.getAttribute('class').split(' ');
      classArr.push('hidden');
      this.countContainerTarget.setAttribute('class', classArr.join(' '));
    } else {
      this.countTarget.innerHTML = `${unreadCount}`;
      const classAttr = this.countContainerTarget.getAttribute('class').split(' ').filter(a => a !== 'hidden').join(' ');
      this.countContainerTarget.setAttribute('class', classAttr);
    }
  }

  async showNotifications() {
    const target = this.notificationsContainerTarget;
    target.innerHTML = '<p class="notifications__control-message">Loading</p>';

    const helper = NotificationsHelper.shared();
    helper.clearNotificationsCount();

    try {
      const { data } = await axios.get('/notifications/widget');
      target.innerHTML = data;
    } catch (e) {
      target.innerHTML = '<p class="notifications__control-message">Error. Please try again.</p>';
    }
  }
}
