import React, { Component } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import {
  Draggable,
} from 'react-beautiful-dnd';

import {
  QuestionTypes,
  QuestionTypeLabelMap,
  QUESTION_TYPES_WITH_CHOICES,
} from '../../constants/questions';

export default class QuestionCard extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      question_type: PropTypes.string,
      round: PropTypes.number,
      position: PropTypes.number,
    }).isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  handleEdit = () => this.props.onEdit(this.props.question.id);

  handleRemove = () => this.props.onRemove(this.props.question.id);

  renderAnswerChoices() {
    const {
      question,
    } = this.props;

    if (!QUESTION_TYPES_WITH_CHOICES.includes(question.question_type)) {
      return null;
    }

    const choices = (question.data || {}).choices || [];


    if (!choices.length) {
      return null;
    }

    return (
      <div className="mb-4">
        <label className="form-label mb-2">
          Question Choice(s)
        </label>
        <ul className="">
          {choices.map((choice, i) => (
            <li key={i}>{choice.name}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderQuestionTitle() {
    const {
      question: {
        question_type,
        title,
      },
    } = this.props;

    const label = QuestionTypeLabelMap[question_type];
    return (
      <p>
        {title}
        <span className="ml-1 text-sm">
        (
          {label}
        )
        </span>
      </p>
    );
  }

  render() {
    const {
      question,
      index,
    } = this.props;

    const draggableId = `question-${question.id}`;
    return (
      <Draggable
        draggableId={draggableId}
        index={index}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="shadow bg-white mb-4 p-4 border-solid border-grey-lightest rounded cursor-pointer"
          >
            <div className="flex justify-between items-center">
              <div>
                {this.renderQuestionTitle()}
                {this.renderAnswerChoices()}
              </div>
              <div className="actions">
                <button
                  type="button"
                  className="text-grey-darker mr-2"
                  title="Edit Question"
                  onClick={this.handleEdit}
                >
                  <PencilIcon className="w-4 h-4" />
                </button>
                <button
                  type="button"
                  className="text-grey-darker mr-2"
                  title="Delete Question"
                  onClick={this.handleRemove}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
