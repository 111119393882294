import { Controller } from '@hotwired/stimulus';

export default class ReadMore extends Controller {
  static targets = ['content', 'fullContent'];

  static values = {
    moreText: String,
    lessText: String,
  };

  connect() {
    this.open = false;
  }

  toggle(event) {
    if (this.open === false) {
      this.show(event);
    } else {
      this.hide(event);
    }
  }

  show(event) {
    this.open = true;

    const { target } = event;
    target.innerHTML = this.lessTextValue;
    this.contentTarget.classList.add('hide');
    this.fullContentTarget.classList.remove('hide');
  }

  hide(event) {
    this.open = false;

    const { target } = event;
    target.innerHTML = this.moreTextValue;
    this.contentTarget.classList.remove('hide');
    this.fullContentTarget.classList.add('hide');
  }
}
