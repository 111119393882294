import { Controller } from '@hotwired/stimulus';
import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  static targets = [
    'checkbox',
  ];

  onChange = (e) => {
    const {
      target: {
        checked,
      },
    } = e;
    this.previousValue = !checked;
    this.checked = checked;
    Rails.fire(this.element, 'submit');
  }

  onToggleSuccess = () => {
    const { checked } = this.checkboxTarget;
    if (checked && this.data.get('on-success-message')) {
      Snackbar.show({ text: this.data.get('on-success-message') });
    } else if (!checked && this.data.get('off-success-message')) {
      Snackbar.show({ text: this.data.get('off-success-message') });
    } else {
      Snackbar.show({ text: 'The changes have been saved' });
    }
  }

  onToggleFail = () => {
    if (this.data.get('error-message')) {
      Snackbar.show({ text: this.data.get('error-message'), actionTextColor: ERROR_COLOR });
    } else {
      Snackbar.show({ text: 'There was a problem toggling the switch', actionTextColor: ERROR_COLOR });
    }
  }
}
