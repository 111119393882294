import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['errors', 'select'];

  onSaveSuccess(e) {
    const [response, status, xhr] = e.detail;

    const { detail } = e;

    document.dispatchEvent(
      new CustomEvent('portfolio-companies:updated', {
        detail,
      }),
    );

    this.closeForm();
  }

  onSaveFail(e) {
    const [response, status, xhr] = e.detail;
    this.errorsTarget.innerHTML = xhr.response;
  }

  onCancel(e) {
    e.preventDefault();
    this.closeForm();
  }

  closeForm() {
    document.dispatchEvent(
      new CustomEvent('portfolio-company-form-modal:close', {
        detail: {},
      }),
    );
  }
}
