import { Controller } from '@hotwired/stimulus';
import {
  ERROR_COLOR,
} from '../constants/colors';

export default class extends Controller {
  onLoadSuccess(e) {
    const [, , xhr] = e.detail;

    const event = `${this.modalId}-update`;

    document.dispatchEvent(new CustomEvent(event, {
      detail: xhr.response,
    }));
  }

  onLoadFail() {
    Snackbar.show({
      text: 'There was a problem loading the content',
      actionTextColor: ERROR_COLOR,
    });
  }

  get modalId() {
    return this.data.get('modal-id');
  }
}
