import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['type', 'venue', 'online'];

  connect() {
    const checked = this.typeTargets.find((c) => c.checked);

    if (checked) {
      this.toggleSection(checked.value);
    }
  }

  onSelect = (e) => {
    const {
      target: { value },
    } = e;
    this.toggleSection(value);
  };

  toggleSection(value) {
    console.log(value);
    if (['venue', 'both'].includes(value)) {
      this.venueTarget.classList.remove('hidden');
    } else {
      this.venueTarget.classList.add('hidden');
    }

    if (['online', 'both'].includes(value)) {
      this.onlineTarget.classList.remove('hidden');
    } else {
      this.onlineTarget.classList.add('hidden');
    }
  }
}
