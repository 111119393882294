import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onSendSuccess() {
    Snackbar.show({ text: 'The invite has been sent!' });
    this.element.reset();
  }

  onSendFail(e) {
    const [response, status, xhr] = e.detail;
    Snackbar.show({
      text: response.message || 'There was a problem sending the invite!',
      actionTextColor: ERROR_COLOR,
    });
  }
}
