import React from 'react';
import PropTypes from 'prop-types';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';

const BASE_CLASSES =
  'flex items-start justify-between w-full p-3 bg-white border-solid border-t-4 border-blue-400';
function ProTip({ title, text, className, children, canClose, onClose }) {
  return (
    <div
      className={classnames(BASE_CLASSES, className)}
      data-controller="protip"
    >
      <div className="flex">
        <LightBulbIcon className="w-16 h-16 text-blue-500 mt-1 mr-3" />
        <div className="flex flex-col mb-0">
          <strong className="mt-1 mb-2 mr-2 text-blue-400">{title}</strong>
          <div className="mb-0 text-sm">{text || children}</div>
        </div>
      </div>
      {canClose && (
        <button
          type="button"
          className="text-lg text-blue-400"
          onClick={onClose}
        >
          <svg
            className="w-6 h-6 fill-current"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </button>
      )}
    </div>
  );
}

ProTip.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  canClose: PropTypes.bool,
  onClose: PropTypes.func,
};

ProTip.defaultProps = {
  title: 'Pro Tip!',
  canClose: true,
  onClose: () => {},
};

export default ProTip;
