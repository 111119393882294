import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

const BUTTON_CLASS =
  'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2';

function getColorClasses(type) {
  switch (type) {
    case 'success':
      return {
        titleText: ['text-green-800'],
        bodyText: 'text-green-700',
        wrapper: {
          'bg-green-50': true,
        },
        iconText: {
          'text-green-400': true,
        },
        closeButton: classnames(
          BUTTON_CLASS,
          'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
        ),
      };
    case 'info':
      return {
        titleText: ['text-blue-800'],
        bodyText: 'text-blue-700',
        wrapper: {
          'bg-blue-50': true,
        },
        iconText: {
          'text-blue-400': true,
        },
        closeButton: classnames(
          BUTTON_CLASS,
          'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50',
        ),
      };
    case 'warning':
      return {
        titleText: ['text-yellow-800'],
        bodyText: 'text-yellow-700',
        wrapper: {
          'bg-yellow-50': true,
        },
        iconText: {
          'text-yellow-400': true,
        },
        closeButton: classnames(
          BUTTON_CLASS,
          'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50',
        ),
      };
    default:
      return {
        titleText: ['text-red-800'],
        bodyText: 'text-red-700',
        wrapper: {
          'bg-red-50': true,
          'border-red-400': true,
          'text-red-700': true,
        },
        iconText: {
          'text-red-400': true,
        },
        closeButton: classnames(
          BUTTON_CLASS,
          'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
        ),
      };
  }
}

function AlertIcon({ type }) {
  switch (type) {
    case 'success':
      return (
        <CheckCircleIcon
          className="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
      );
    case 'info':
      return (
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      );
    case 'warning':
      return (
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      );
    default:
      return (
        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
      );
  }
}

AlertIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default function Alert({ type, title, children, hideClose, onClose }) {
  const colorClasses = getColorClasses(type);
  const wrapperClassName = classnames({
    relative: true,
    'p-4': true,
    'rounded-md': true,
    ...colorClasses.wrapper,
  });

  return (
    <div className={wrapperClassName}>
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertIcon type={type} />
        </div>
        <div className="ml-3">
          {title && (
            <h3
              className={classnames(
                'text-sm font-medium',
                colorClasses.titleText,
              )}
            >
              {title}
            </h3>
          )}
          <div
            className={classnames('text-sm', colorClasses.bodyText, {
              'mt-2': title && children,
            })}
          >
            {children}
          </div>
        </div>
        {!hideClose && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={colorClasses.closeButton}
                onClick={onClose}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  onClose: PropTypes.func,
  hideClose: PropTypes.bool,
};

Alert.defaultProps = {
  type: 'error',
  hideClose: false,
  children: null,
  onClose: () => {},
};
