import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    id: String,
  };

  static targets = ['button'];

  connect() {
    document.addEventListener(this.eventName, this.onToggle);
  }

  disconnect() {
    document.removeEventListener(this.eventName, this.onToggle);
  }

  onToggle = (e) => {
    this.buttonTarget.disabled = !e.detail.enabled;
  };

  get eventName() {
    if (this.idValue) {
      return `${this.idValue}:toggle`;
    }
    return 'toggle';
  }
}
