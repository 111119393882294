import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Draggable,
} from 'react-beautiful-dnd';

import ToggleSwitch from '../ToggleSwitch';

export default class ProfileQuestionField extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    question: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
    }).isRequired,
    onToggleQuestion: PropTypes.func.isRequired,
  }

  handleSwitchChange = (id, checked) => this.props.onToggleQuestion(id, checked);

  render() {
    const {
      index,
      question: {
        id,
        enabled,
        title,
      },
    } = this.props;

    const draggableId = `venture-question-${id}`;

    return (
      <Draggable
        draggableId={draggableId}
        index={index}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="p-4 mb-6 bg-white border-solid rounded border-grey-lightest"
          >
            <div className="flex justify-between">
              <p>{title}</p>
              <ToggleSwitch
                checked={enabled}
                value={id}
                onChange={this.handleSwitchChange}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}
