import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['field'];

  onToggle = (e) => {
    const {
      target: { checked },
    } = e;
    if (checked) {
      this.fieldTarget.classList.remove('hidden');
    } else {
      this.fieldTarget.classList.add('hidden');
    }
    this.fieldTarget.disabled = !checked;
  };
}
