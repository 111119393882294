import { marked } from 'marked';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'attachments',
    'description',
    'descriptionDisplay',
    'descriptionFieldContainer',
    'editButton',
    'emptyDescriptionButton',
    'error',
    'file',
  ];

  connect() {
    this.renderDescription();
  }

  renderDescription() {
    const currentDescription = this.data.get('description');
    if (!currentDescription) {
      this.emptyDescriptionButtonTarget.classList.remove('hidden');
      this.descriptionDisplayTarget.classList.add('hidden');
    } else {
      this.emptyDescriptionButtonTarget.classList.add('hidden');
      this.descriptionDisplayTarget.innerHTML = marked(currentDescription);
      this.descriptionDisplayTarget.classList.remove('hidden');
    }
  }

  hideDescriptionTextArea() {
    this.restoreDescriptionValue();
    this.renderDescription();
    this.descriptionFieldContainerTarget.classList.add('hidden');
    this.editButtonTarget.classList.remove('hidden');
  }

  showDescriptionTextArea() {
    this.restoreDescriptionValue();
    this.descriptionFieldContainerTarget.classList.remove('hidden');

    this.descriptionDisplayTarget.classList.add('hidden');
    this.emptyDescriptionButtonTarget.classList.add('hidden');

    this.editButtonTarget.classList.add('hidden');
    this.descriptionTarget.focus();
  }

  restoreDescriptionValue() {
    this.descriptionTarget.value = this.data.get('description');
  }

  onEscapeKeyDown(e) {
    e.stopPropagation();
    if (isEscapeKey(e)) {
      this.hideDescriptionTextArea();
    }
  }

  onBlur(e) {
    if (e.relatedTarget && e.relatedTarget.nodeName === 'BUTTON') {
      return;
    }
    if (this.data.get('description').trim() !== this.descriptionTarget.value.trim()) {
      this.saveChanges();
    } else {
      this.hideDescriptionTextArea();
    }
  }

  onEdit() {
    this.showDescriptionTextArea();
  }

  onSaveSuccess() {
    this.data.set('description', this.descriptionTarget.value);
    this.hideDescriptionTextArea();
    this.fileTarget.value = '';
    this.errorTarget.innerHTML = '';
  }

  onSaveFail(e) {
    const [
      response, status, xhr,
    ] = e.detail;

    this.errorTarget.innerHTML = xhr.reponse;
  }

  saveChanges() {
    if (this.data.get('description').trim() !== this.descriptionTarget.value.trim()) {
      this.submitForm();
    }
  }

  submitForm() {
    Rails.fire(this.element, 'submit');
  }

  onCancel(e) {
    e.stopPropagation();
    this.hideDescriptionTextArea();
  }
}

function isEscapeKey(evt) {
  if ('key' in evt) {
    return evt.key === 'Escape' || evt.key === 'Esc';
  }
  return evt.keyCode === 27;
}
